import { Api } from "../api";

class CustomerApi extends Api {
  getCustomerForChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/customer/get-for-choose",
      data
    );
  }

  getCustomerWidthBalance(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/customer/get-for-transaction",
      data
    );
  }
  getCustomerPaging(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/customer/get-paging",
      data
    );
  }
  addNote(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/customer/comment/create",
      data
    );
  }
}

export default CustomerApi;
