import { useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { Fade, Typography, Popper } from "@mui/material";

import { modifiers } from "./Confirmation.Constants";
import useOutsideHandler from "../../../hooks/useOutsideHandler";
import {
  BoxButtonWrapper,
  StyledConfirmationBody,
} from "./Confirmation.styled";
import Button from "../Buttons/Button/Button";

const Confirmation = (props) => {
  const { t } = useTranslation();
  const outsideOfSelectRef = useRef();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    children,
    onConfirm,
    confirmationLoading = false,
    style = {},
    title = t("COMMON.DO_YOU_WANT_TO_DELETE"),
  } = props;

  useOutsideHandler(outsideOfSelectRef, setOpen);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const onCancel = () => setOpen((prev) => !prev);

  return (
    <>
      <span onClick={handleClick} style={style}>
        {children}
      </span>
      <Popper
        style={{
          zIndex: 9998,
          backgroundColor: "transparent",
          borderRadius: "12px",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        ref={outsideOfSelectRef}
        transition
        modifiers={modifiers}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <StyledConfirmationBody>
              <Typography variant="subtitle2" fontSize="17px">
                {title}
              </Typography>
              <BoxButtonWrapper>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={onCancel}
                >
                  {t("COMMON.NO")}
                </Button>
                <Button
                  loading={confirmationLoading}
                  size="small"
                  variant="contained"
                  onClick={onConfirm}
                  color="error"
                  sx={{ marginLeft: "10px" }}
                >
                  {t("COMMON.YES")}
                </Button>
              </BoxButtonWrapper>
            </StyledConfirmationBody>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default memo(Confirmation);
