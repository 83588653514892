import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { HeaderStyle } from "./Header.style";
import { SendSmsContext } from "../../context";

const Header = ({ onClose }) => {
  const {
    state: {},
    actions: { setIsOpen },
  } = useContext(SendSmsContext);
  return (
    <HeaderStyle>
      <div>Send SMS</div>
      <div className="close-icon" onClick={onClose}>
        <CloseIcon />
      </div>
    </HeaderStyle>
  );
};

export default Header;
