import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow as MuiTableRow,
  TableContainer,
  TableHead,
} from "@mui/material";

import {
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
} from "./InnerTreeTable.style";
import TableRow from "./components/InnerTreeTableRow";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";

const InnerTreeTable = ({
  columns = [],
  data = [],
  renderItemProps,
  onRowClick = () => {},
  emptydatamessage,
  setData,
  loading = false,
  tableBodyRowCount,
  isHeader = false,
}) => {
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <TableHead>
        <MuiTableRow>
          {columns.map((column, cellIndex) => (
            <TableCell
              sx={{ border: 0, ...column?.style }}
              key={cellIndex * 10}
            >
              {t(column?.translationKey)}
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
    );
  };

  const renderBody = () => {
    if (data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <StyledTableCell colSpan={columns.length} align="center">
              {emptydatamessage
                ? emptydatamessage
                : t("table.emptyDataSourceMessage")}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <StyledTableBody>
        {data.map((item, index) => (
          <>
            <TableRow
              isShowLine={true}
              onClick={() => onRowClick(item)}
              key={item._id}
            >
              {columns.map((column, cellIndex) => (
                <StyledTableCell sx={column?.style} key={cellIndex * 10}>
                  {renderRowItem(column, item, cellIndex, index)}
                </StyledTableCell>
              ))}
            </TableRow>
          </>
        ))}
      </StyledTableBody>
    );
  };

  const renderRowItem = (column, dataItem, index, dataIndex) =>
    column.renderComponent
      ? column.renderComponent(
          dataItem,
          { ...renderItemProps, dataIndex },
          index,
          t
        )
      : dataItem[column.dataKey];

  return (
    <TableContainer component={StyledTableContainer}>
      {loading ? (
        <TableSkeleton
          tableBodyRowCount={tableBodyRowCount}
          columns={columns}
        />
      ) : (
        <MuiTable>
          {isHeader && renderHeader()}
          {renderBody()}
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default memo(InnerTreeTable);
