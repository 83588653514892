import { Popper } from "@mui/material";
import { styled } from "@mui/material";

export const RangePickerContainer = styled("div")({
  "& .range_picker_header": {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0 1px 2px 0 #00000008",
    display: "flex",
    height: "43px",
    "& .range_picker_label": {
      cursor: "pointer",
      color: "#5E5873",
      fontSize: " 1rem",
      padding: "10px 0 10px 15px",
      display: "flex",
      alignItems: "center",
    },
    "& .range_picker_header_child": {
      width: "220px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
      color: "#5E5873",
      cursor: "pointer",
    },
    "& .range_picker_input": {
      cursor: "pointer",
      border: "none",
      paddingLeft: "10px",
      fontSize: "1rem",
      lineHeight: "1.45",
      padding: "10px 15px 10px 15px",
      color: "#495057",
      "&:focus": {
        outline: "none",
      },
    },
  },
});

export const PopperStyle = styled(Popper)({
  backgroundColor: "#fff",
  borderRadius: "12px",
  marginTop: "50px",
  zoom: "0.95",
  "& .css-1hbyad5-MuiTypography-root": {
    display: "none",
  },
  zIndex: 9999999,
});

export const RangePickerBody = styled("div")({
  display: "block",
  "& .close_modal": {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    borderBottom: "1px solid #eaeaea",
  },
  "& .calendar": {
    display: "flex",
    "& .Mui-selected": {
      borderRadius: "50% !important",
    },
    "& .css-4l7j15": {
      borderRadius: "0 0 0 12px",
    },
    "& .MuiPickerStaticWrapper-root": {
      borderRadius: "0 0 0 12px",
    },
    "& .select_range_elements": {
      borderLeft: "1px solid #eaeaea",
      width: "200px",
      "& ul": {
        "& li": {
          height: "40px",
          padding: "0 16px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#eaeaea",
          },
        },
        "& .active_interval": {
          backgroundColor: "#9EC1EE",
          color: "#fff",
        },
      },
    },
  },
});
