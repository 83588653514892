import { memo } from "react";

import { ImportItemProvider } from "../context";
import ImportItem from "./ImportItem";

const Index = ({
  importAPI,
  downloadName,
  importOrganization,
  newImport,
  validateSubUrl,
  organizationId,
  sourceOrganizationId,
  destinationOrganizationId,
}) => {
  return (
    <ImportItemProvider>
      <ImportItem
        importAPI={importAPI}
        importOrganization={importOrganization}
        downloadName={downloadName}
        newImport={newImport}
        validateSubUrl={validateSubUrl}
        organizationId={organizationId}
        destinationOrganizationId={destinationOrganizationId}
        sourceOrganizationId={sourceOrganizationId}
      />
    </ImportItemProvider>
  );
};

export default memo(Index);
