import { Drawer, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)({
  "& > *.MuiDrawer-paper": {
    width: 450,
    overflow: "hidden",
  },
});

export const SmsScrollContainer = styled("div")({
  overflowY: "auto",
});
