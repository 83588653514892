import moment from "moment";
import { detectCurrencySide } from "services";

import numberWithSpaces from "services/number_format/number_format";
import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";

export const CustomerTableHeader = [
  { translationKey: "HR.EMPLOYEES.F_I_O", dataKey: "name" },
  {
    translationKey: "COMMON.CONTACT",
    dataKey: "phone_number",
    export: true,
  },
  {
    translationKey: "COMMON.FIRST_SALE",
    renderItem: (row) => moment(row?.first_sale_at).format("L"),
  },
  {
    translationKey: "COMMON.LAST_SALE",
    renderItem: (row) => moment(row?.last_sale_at).format("L"),
  },
  {
    translationKey: "COMMON.COUNT",
    renderItem: (row) => numberWithSpaces(row.total_sale),
    dataKey: "total_sale",
  },
  {
    translationKey: "COMMON.BALANCE",
    cellStyle: { whiteSpace: "nowrap" },
    align: "right",
    renderItem: (row, _, { findCurrency }) => (
      <span
        style={
          Math.sign(row.balance) === -1 ? { color: "red" } : { color: "green" }
        }
      >
        {detectCurrencySide(
          findCurrency(row?.balance_currency_id),
          numberWithSpaces(row.balance)
        )}
      </span>
    ),
    hasAccess: ACCESS_NAMES.customerShowBalance,
  },
  {
    translationKey: "COMMON.GENERAL_SALE",
    cellStyle: { whiteSpace: "nowrap" },
    align: "right",
    renderItem: (row, _, { findCurrency, calculateCashSum }) =>
      detectCurrencySide(
        findCurrency(row?.balance_currency_id),
        numberWithSpaces(calculateCashSum(row?.total_balance, true))
      ),
  },
];

export const CUSTOMER_TYPES = {
  legal: "legal",
  physical: "physical",
};

export const CUSTOMER_TYPE_OPTIONS = [
  { value: CUSTOMER_TYPES.legal, translationKey: "CUSTOMER_PROFILE.legal" },
  {
    value: CUSTOMER_TYPES.physical,
    translationKey: "CUSTOMER_PROFILE.physical",
  },
];

export const DEBT_TYPE_OPTIONS = [
  {
    value: true,
    translationKey: "COMMON.DEBTORS",
  },
  {
    value: false,
    translationKey: "COMMON.RIGHTFUL",
  },
];
