import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { tasksApi } from "services/api";
import { get_Paging_Tasks_Action } from "redux/actions";

function useMyTaskModel(currentTaskEdited) {
  const { t } = useTranslation();
  const [myTasks, setMyTasks] = useState([]);
  const [filter, setFilter] = useState({
    responsible_id: null,
    priority: null,
    state: null,
    search: "",
  });
  const [page, setPage] = useState({ active: 1, total: 0 });
  const dispatch = useDispatch();
  const [myTasksToKanban, setMyTasksToKanban] = useState([]);

  function manageArrayToKanban(data) {
    let exampleTasks = [
      {
        id: 1,
        state: "created",
        title: `${t("TASKS.MY_TASKS.NEW_TASKS")}`,
        boards: [],
      },
      {
        id: 1,
        title: `${t("ENUMS.IN_PROGRESS")}`,
        state: "in_progress",
        boards: [],
      },
      {
        id: 1,
        title: `${t("ENUMS.COMPLETED")}`,
        state: "done",
        boards: [],
      },
    ];
    exampleTasks[0].boards = data.filter((item) => item.state === "created");
    exampleTasks[1].boards = data.filter(
      (item) => item.state === "in_progress"
    );
    exampleTasks[2].boards = data.filter((item) => item.state === "done");
    setMyTasksToKanban(exampleTasks);
  }

  async function getTaskMine() {
    try {
      const payload = {
        limit: 10,
        page: page.active,
        responsible_id: filter.responsibleId,
        state: filter.state,
        search: filter.search ? filter.search : undefined,
      };
      const { data, total } = await tasksApi.getMine(payload);
      setMyTasks(data);
      page.total = total;
      setPage(page);
      await manageArrayToKanban(data);
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function deleteById(id) {
    try {
      await tasksApi.deleteTask({ _id: id });
      await getTaskMine();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function deleteTask(data) {
    if (!data.getAll) {
      await deleteById(data.id);
    } else {
      await deleteById(data.id);

      async function getAllActiveData() {
        await dispatch(get_Paging_Tasks_Action({ page: 1, limit: 10 }));
      }
      await getAllActiveData();
    }
    if (currentTaskEdited) currentTaskEdited("delete", data.id);
  }

  const editTask = async (id, name, value, isNotGetTasks, task) => {
    const payload =
      name === "main_concepts"
        ? value
        : {
            _id: id,
            field_name: name,
            [`${name}`]: value,
          };
    try {
      await tasksApi.updateTask(payload);
      if (currentTaskEdited) currentTaskEdited(name, value, task);
      if (!isNotGetTasks) await getTaskMine();
    } catch (e) {}
  };

  async function handlePage(value) {
    setPage(value);
    await getTaskMine();
  }

  return [
    getTaskMine,
    myTasks,
    myTasksToKanban,
    deleteTask,
    editTask,
    page,
    handlePage,
    filter,
    setFilter,
  ];
}

export default useMyTaskModel;
