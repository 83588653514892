import { Box } from "@mui/material";
import { styled } from "@mui/material";

export const StyledConfirmationBody = styled(Box)({
  borderRadius: "12px",
  padding: "8px",
  backgroundColor: "#fff",
});

export const BoxButtonWrapper = styled(Box)({
  marginTop: "20px",
  textAlign: "end",
});
