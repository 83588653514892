import { TableCell, TableBody } from "@mui/material";
import { styled } from "@mui/material";

export const StyledTableContainer = styled("div")({
  width: "100%",
});

export const StyledTableCell = styled(TableCell)({
  padding: "10px",
});

export const StyledTableBody = styled(TableBody)({
  "& tr td": {
    borderBottom: "none",
  },
});
