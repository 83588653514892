import { Autocomplete, Box, styled } from "@mui/material";

export const StyledSelect = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {},
  "& .MuiOutlinedInput-input": {
    padding: "0!important",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 9px)",
    color: "#313649",
  },
  "& .MuiChip-root": {
    height: "25px",
    fontSize: "14px",
    margin: "1.5px",
    "& svg": {
      fontSize: "18px",
    },
  },
  "&.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
  "& .MuiOutlinedInput-root .MuiButtonBase-root path": {
    fill: "#313649",
  },
  "&.error .MuiOutlinedInput-root .MuiButtonBase-root path": {
    fill: theme.palette.error.main,
  },
}));

export const StaticOptionWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "sticky",
  bottom: "0",
  backgroundColor: theme.palette.white.main + "!important",
  "&:hover": {
    backgroundColor: theme.palette.white.main + "!important",
  },
  transition: "1s",
  paddingBottom: "6px",
  paddingTop: "6px",
  paddingLeft: "16px",
  paddingRight: "16px",
}));
