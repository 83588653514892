import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { FileUploader } from "react-drag-drop-files";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  Skeleton,
  Modal,
} from "@mui/material";
import { ArrowCircleDown, InsertDriveFile, Warning } from "@mui/icons-material";

import { Layout } from "components/common";
import OrganizationSelect from "components/formElements/apiFormElements/organizationSelect";
import { ImportItemContext } from "../context";
import CustomDrawer from "components/Drawer";
import ButtonWithIcon from "../../Buttons/ButtonWithIcon/ButtonWithIcon";
import SubmitButtons from "../../Buttons/SubmitButtons/SubmitButtons";
import { StyledBox, StyledUploader } from "./ImportItem.style";

const ImportItem = ({
  importAPI,
  downloadName,
  importOrganization,
  newImport,
  validateSubUrl,
  organizationId,
  sourceOrganizationId,
  destinationOrganizationId,
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  // const [organizationId, setOrganizationId] = useState();
  const { t } = useTranslation();
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  const {
    state: { errors, open },
    actions: {
      downloadExample,
      handleChange,
      submitProducts,
      setOpen,
      setErrors,
    },
  } = useContext(ImportItemContext);

  const onClose = () => {
    setOpen(false);
    setSuccess(false);
    setErrors(false);
  };

  const ErrorItem = ({ error }) => {
    return (
      <>
        <h4>
          {t("import_item.row")}: {error.row}
        </h4>
        {error.data.map((err) => (
          <Box
            py={"1rem"}
            sx={{ display: "flex", alignItems: "center" }}
            width={"100%"}
          >
            <Warning sx={{ color: "grey", fontSize: 25 }} />
            <div style={{ textAlign: "left", marginLeft: "1rem" }}>
              <Typography fontSize={14}>{`${t(`import_from_csv.${err.data}`)} ${
                err.message.split(" ")[0] === "enum"
                  ? `${err.message.split(" ")[1]} ${t(`import_from_csv.enum`)}`
                  : t(`import_from_csv.${err.message}`)
              }`}</Typography>
            </div>
            <div>
              <Typography fontSize={14}>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("import_item.location")}:{" "}
                {`${err.col}${error.row}`}
              </Typography>
            </div>
          </Box>
        ))}
        <hr />
      </>
    );
  };
  return (
    <>
      <ButtonWithIcon
        variant="outlined"
        icon={<ArrowCircleDown fontSize="small" color="primary" />}
        textColor="primary"
        onClick={() => setOpen(true)}
      >
        {t("COMMON.IMPORT")}
      </ButtonWithIcon>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <StyledBox>
          <Card className="create-card-first import-card-first reducerClass">
            <div className="import-items-wrapper">
              <Grid container spacing={3} className="import-items">
                <Grid item xs={6}>
                  <Typography sx={{ margin: "1rem 0" }} variant={"h5"}>
                    {t("import_item.import_item")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {/* <OrganizationSelect
                    optionAll
                    onChange={(e) => setOrganizationId(e.target.value)}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"subtitle1"} style={{ display: "flex" }}>
                    <Typography
                      variant={"subtitle1"}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        downloadExample(
                          id,
                          organizationId,
                          importAPI,
                          downloadName,
                          importOrganization,
                          newImport
                        )
                      }
                      color={"blue"}
                    >
                      {t("import_item.download_template_file")}
                    </Typography>
                    &nbsp;&nbsp;{t("import_item.fixed_element_information")}
                  </Typography>
                </Grid>
              </Grid>
              {!!success && !errors && (
                <>
                  <Typography fontSize={20}>
                    {t("import_item.number_product_create")}{" "}
                    {success.created_count}
                  </Typography>
                  <Typography pb={2} fontSize={20}>
                    {t("import_item.number_product_edit")}{" "}
                    {success.updated_count}
                  </Typography>
                </>
              )}
              {errors && (
                <>
                  <Box
                    py={"1rem"}
                    sx={{ display: "flex", alignItems: "center" }}
                    width={"100%"}
                  >
                    <Warning sx={{ color: "red", fontSize: 30 }} />
                    <div style={{ textAlign: "left", marginLeft: "1rem" }}>
                      <Typography fontSize={15} sx={{ color: "red" }}>
                        {t("import_item.found_errors")} {errors?.data?.length}
                      </Typography>
                      <Typography fontSize={14}>
                        {t(
                          "import_item.fixed_error_in_import_and_try_to_reload"
                        )}
                      </Typography>
                    </div>
                  </Box>
                  <hr />
                  {errors.data?.map((error) => (
                    <ErrorItem error={error} />
                  ))}
                  <Box textAlign={"center"} my={3}>
                    {t("import_item.fixed_error_and_review")}
                  </Box>
                </>
              )}
              <StyledUploader>
                {!loading ? (
                  <FileUploader
                    onTypeError={() =>
                      toast.error("CSV formatdagi malumot kirting")
                    }
                    handleChange={(event) =>
                      handleChange(
                        event,
                        id,
                        organizationId,
                        setLoading,
                        setSuccess,
                        importAPI,
                        newImport,
                        validateSubUrl,
                        destinationOrganizationId,
                        sourceOrganizationId
                      )
                    }
                    name="file"
                    // types={fileTypes}
                  >
                    <Grid container display="flex" alignItems="center">
                      <Grid item xs={2} textAlign={"right"}>
                        <div className="drag-drop-file-icon-style">
                          <InsertDriveFile fontSize={"large"} />
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant={"subtitle1"}>
                          {" "}
                          {t(
                            "import_item.upload_file_here_or_select_from_your_computer"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </FileUploader>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={250}
                  />
                )}
              </StyledUploader>
              {/* <Box mt={4} width={"100%"} textAlign={"right"}>
                <Button
                  variant={"outlined"}
                  onClick={onClose}
                  style={{
                    background: "#ffff",
                    borderColor: "#FFFF",
                    marginRight: "1rem",
                  }}
                  className={"radius-12"}
                >
                  {t("COMMON.CANCEL")}
                </Button>
                <Button
                  disabled={!success || !!errors}
                  className={"radius-12"}
                  onClick={() =>
                    submitProducts(
                      id,
                      organizationId,
                      setLoading,
                      setSuccess,
                      importAPI
                    )
                  }
                  variant={"contained"}
                >
                  {t("COMMON.SAVE")}
                </Button>
              </Box> */}
            </div>
          </Card>
        </StyledBox>
      </Modal>
    </>
  );
};

export default memo(ImportItem);
