import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";

import TimePicker from "../TimePicker/TimePicker";

const ControlledTimePicker = ({ name, control, rules, errors, ...props }) => {
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <TimePicker id={name} {...props} {...field} error={errors?.[name]} />
      )}
    />
  );
};

export default memo(ControlledTimePicker);
