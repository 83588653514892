import axios from "axios";
import { baseUrl, head_token } from "../../../api";
import { toast } from "react-hot-toast";

export const delete_Device_Many_Action = (id) => async (dispatch) => {
  await axios
    .post(`${baseUrl}/back-api/admin/settings/device/delete-many`, id, {
      headers: head_token,
    })
    .then((response) => {
      dispatch({
        type: "DELETE_DEVICE_MANY_0",
        payload: response,
      });
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      } else if (error.response?.data?.code === 10001) {
        toast.error(error.response.message);
      } else if (error.response?.data?.code % 1000 === 1) {
        toast.error(error.response.data.message);
      }
      if (error.response) {
        const code = error.response.data.code;
        switch (code) {
          case 10001:
            dispatch({
              type: "DELETE_DEVICE_MANY_10001",
              payload: "error 10001",
            });
            break;
          case 10000:
            dispatch({
              type: "DELETE_DEVICE_MANY_10000",
              payload: "error 10000",
            });
            break;
          default:
            return error.response;
        }
      }
    });
};
