import { TableBody, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material";

export const StyledTableContainer = styled("div")({
  width: "100%",
  overflowX: "scroll!important",
  paddingLeft: "20px",
  overflowY: "hidden!important",
});

export const StyledTableBody = styled(TableBody)({
  borderLeft: "1px solid #D8D6DE",
  // "&:first-child td": {
  //   paddingLeft: "30px",
  // },
});

export const StyledTableRow = styled(TableRow)(({ isShowLine }) => ({
  "& td, & th": { border: 0 },
  position: "relative",
  "&::after": {
    content: `""`,
    position: "absolute",
    left: "0px",
    top: "25px",
    width: "25px",
    height: "1px",
    backgroundColor: isShowLine && "#D8D6DE",
  },
  "& td:first-child": {
    paddingLeft: "30px",
  },
  "&:last-child td::before": {
    content: `""`,
    position: "absolute",
    left: "-1px",
    top: "26px",
    width: "2px",
    height: "44px",
    backgroundColor: "#fff",
  },
}));

export const StyledTableCell = styled(TableCell)({
  padding: "5px",
  height: "53px",
});
