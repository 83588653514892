import { useTranslation } from "react-i18next";
import { AddCircleOutline } from "@mui/icons-material";

import { StyledButton } from "./ButtonWithIcon.style";

const ButtonWithIcon = ({ children, textColor = "#fff", icon, ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      startIcon={
        icon || <AddCircleOutline color={textColor} fontSize="small" />
      }
      variant="contained"
      {...props}
    >
      {children || t("COMMON.CREATE")}
    </StyledButton>
  );
};

export default ButtonWithIcon;
