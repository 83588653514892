import { styled } from "@mui/material";

export const SmsTemplateStyle = styled("div")({
  "& .sms-card": {
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    margin: "20px",
    padding: "12px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    wordBreak: "break-word",
  },
  "& .active-sms-card": {
    background: "#02a6FF",
    color: "#fff",
  },
  "& .title-sms-template": {
    padding: "20px",
    borderBottom: "1px solid #DADADA",
  },
});
