import { styled } from "@mui/material";
import {
  IconButton,
  Paper,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

export const TableCellHeaderStyle = styled(TableCell)({
  fontSize: "14px",
  color: "rgb(94, 88, 115)",
  whiteSpace: "nowrap",
});

export const TableCellBodyStyle = styled(TableCell)({
  fontSize: "13px",
});

export const StyledIndexTableCell = styled(TableCell)({
  width: "1%",
});

export const StyledTableContainer = styled(TableContainer)({
  height: "calc(100% - 60px)",
});

export const StyledTableBodyRow = styled(TableRow)({});

export const StyledPaper = styled(Paper)(({ height, filterheight = 0 }) => ({
  minHeight: "300px",
  width: "100%",
  overflow: "hidden",
  height: height ? height : `calc(100vh - 105px - ${filterheight}px)`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "12px",
}));

export const StyledTablePagination = styled(TablePagination)({
  height: "60px",
});
// MuiTablePagination-selectLabel

export const StyledDetailPanelTableCell = styled(TableCell)({
  boxShadow: "rgb(223 223 219 / 50%) 0px 0px 20px 0px inset",
});

export const StyledCheckboxTableCell = styled(TableCell)({
  width: "1%",
  position: "relative",
});

export const StyledIconButton = styled(IconButton)({
  padding: "0",
  position: "absolute",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  borderRadius: "0",
});
