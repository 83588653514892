import { styled } from "@mui/material";

export const FilterContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  "@media (min-width: 900px)": {
    display: "initial",
    justifyContent: "initial",
  },
});

export const FilterIconBlock = styled("div")({
  display: "block",
  "@media (min-width: 900px)": {
    display: "none",
  },
});

export const StyledCurtain = styled("div")({
  position: "fixed",
  top: 0,
  left: "-100%",
  width: "100%",
  height: "100vh",
  // transition: "all 0.1s ease-in-out",
  zIndex: -1,
  "&.show": {
    left: 0,
    zIndex: 1009,
    background: "rgba(0, 0, 0, 0.6)",
  },
  "@media (min-width: 900px)": {
    "&.show": {
      zIndex: -1,
      background: "rgba(0, 0, 0, 0)",
    },
  },
});

export const FilterContent = styled("div")({
  position: "fixed",
  top: 0,
  right: "-400px",
  width: "350px",
  height: "100vh",
  background: "#fff",
  zIndex: "1020",
  padding: "30px 20px",
  overflowY: "scroll",
  boxShadow: "0px 0px 6px 0px #333",
  transition: "all 0.2s ease-in-out",
  "&.show": {
    right: 0,
  },
  "@media(min-width: 900px)": {
    position: "initial",
    height: "auto",
    width: "100%",
    background: "transparent",
    padding: "0",
    overflowY: "initial",
    boxShadow: "0px 0px 0px 0px",
  },
});

export const FilterTitle = styled("div")({
  marginBottom: "30px",
  display: "block",
  "@media (min-width: 900px)": {
    display: "none",
  },
});
export const FilterBlock = styled("h4")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "5px",
});
export const Title = styled("h4")({});

export const CloseIconBlock = styled("div")({
  width: "50px",
});
