import { Box } from "@mui/material";
import { styled } from "@mui/material";

export const StyledScreenWrapper = styled(Box)({
  position: "absolute",
  top: "0",
  bottom: "0",
  right: "0",
  left: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "999999999999",
});
