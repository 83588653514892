import React from "react";

const Sms = () => {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 0.625H6.75C5.925 0.625 5.25 1.3 5.25 2.125V8.875C5.25 9.7075 5.925 10.375 6.75 10.375H16.5C17.3325 10.375 18 9.7075 18 8.875V2.125C18 1.3 17.3325 0.625 16.5 0.625ZM16.5 8.875H6.75V3.3775L11.625 5.875L16.5 3.3775V8.875ZM11.625 4.6075L6.75 2.125H16.5L11.625 4.6075ZM3.75 8.875C3.75 9.0025 3.7725 9.1225 3.7875 9.25H0.75C0.336 9.25 0 8.9125 0 8.5C0 8.0875 0.336 7.75 0.75 7.75H3.75V8.875ZM2.25 1.75H3.7875C3.7725 1.8775 3.75 1.9975 3.75 2.125V3.25H2.25C1.8375 3.25 1.5 2.9125 1.5 2.5C1.5 2.0875 1.8375 1.75 2.25 1.75ZM0.75 5.5C0.75 5.0875 1.0875 4.75 1.5 4.75H3.75V6.25H1.5C1.0875 6.25 0.75 5.9125 0.75 5.5Z"
        fill="#EDF1FF"
      />
    </svg>
  );
};

export default Sms;
