import { styled } from "@mui/material";

export const StyledBadge = styled("div")(({ backgroundColor = "#c7bfbf" }) => ({
  padding: "0.3rem 0.6em",
  borderRadius: "10rem",
  display: "inline-block",
  fontSize: "14px",
  fontWeight: 700,
  color: "#fff",
  backgroundColor,
  lineHeight: 1,
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
}));
