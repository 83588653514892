import { Api } from "../api";

export class InventoryApi extends Api {
  inventoryProductChose(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/items/choose",
      data
    );
  }

  getPagingInventory(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/get-paging",
      data
    );
  }

  addInventory(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/create",
      data
    );
  }

  updateInventory(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/update",
      data
    );
  }

  getById(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/get-by-id",
      data
    );
  }

  getItems(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/item/get-paging",
      data
    );
  }

  saveCount(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/item/set-count",
      data
    );
  }

  setCountStatus(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/revision/set-state",
      data
    );
  }
}
