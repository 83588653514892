import { useEffect, useState, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Error from "../Error/Error";
import Label from "../Label/Label";
import {
  StyledIconWrapper,
  StyledInput,
  StyledInputWrapper,
} from "./Input.style";

const Input = ({
  label,
  inputType = "string",
  isMoneyFormat = false,
  type,
  error,
  ...props
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [inputTypeState, setInputTypeState] = useState(type || inputType);
  const containerRef = useRef(null);

  useEffect(() => {
    props.hookformchangehandler &&
      props.defaultValue &&
      props.hookformchangehandler(props.defaultValue);
  }, []);

  const renderLabel = () => <Label disabled={props?.disabled}>{label}</Label>;

  const renderInput = () => (
    <StyledInputWrapper ispassword={type === "password"}>
      <StyledInput
        onWheel={(event) => {
          event.target.blur();
        }}
        error={error}
        type={inputTypeState}
        typeforstyle={type}
        {...props}
      />
      {type === "password" ? (
        <StyledIconWrapper
          ref={containerRef}
          onClick={() => {
            if (isVisible) {
              setInputTypeState("password");
              setIsVisible(false);
            } else {
              setInputTypeState("text");
              setIsVisible(true);
            }
          }}
        >
          <Slide
            direction="up"
            className="visibilityOffIcon"
            in={isVisible}
            container={containerRef.current}
          >
            <VisibilityOffIcon color="error" fontSize="small" />
          </Slide>
          <Slide
            direction="down"
            in={!isVisible}
            container={containerRef.current}
            className="visibilityIcon"
          >
            <VisibilityIcon color="info" fontSize="small" />
          </Slide>
        </StyledIconWrapper>
      ) : (
        ""
      )}
    </StyledInputWrapper>
  );

  const renderError = () => (
    <Error>{error.message || t("error_messages.invalid_value")}</Error>
  );

  return (
    <div>
      {label && renderLabel()}
      {renderInput()}
      {error && renderError(error)}
    </div>
  );
};

export default memo(Input);
