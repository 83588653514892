import { lazy } from "react";
import { RouteLink } from "components/common";
import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";
import { HREF } from "constants/href.constants";

const ProductionModule = lazy(() => import("../pages/production"));
const AuthLogin = lazy(() => import("../pages/auth/login/container"));
const FinanceModule = lazy(() => import("../pages/finance"));
const WarehouseModule = lazy(() => import("../pages/warehouse"));
const SaleModule = lazy(() => import("../pages/sale"));
const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const Pos = lazy(() => import("../pages/pos"));
const TaskModule = lazy(() => import("../pages/tasks"));
const Integration = lazy(() => import("../pages/integration"));
const Profile = lazy(() => import("../pages/profile"));
const HrModule = lazy(() => import("../pages/hr"));
const SettingsModule = lazy(() => import("../pages/settings"));
const StatisticsModule = lazy(() => import("../pages/statistics"));
const SupplierModule = lazy(() => import("../pages/supply"));
const CustomerModule = lazy(() => import("../pages/customer"));
const ProductModule = lazy(() => import("../pages/product"));
const ForgotPassword = lazy(() =>
  import("../pages/auth/forgotPassword/container")
);
const ProductImportItem = lazy(() =>
  import("../pages/product/products/importItem/container")
);

export const routers = [
  {
    href: HREF.login,
    component: AuthLogin,
    section: "default",
  },
  {
    href: HREF.forgotPassword,
    component: ForgotPassword,
    section: "default",
  },
  {
    href: HREF.statistics,
    component: StatisticsModule,
    section: ACCESS_NAMES.statistics,
  },
  {
    href: HREF.pos,
    component: Pos,
    section: ACCESS_NAMES.pos,
  },
  {
    href: HREF.sale,
    component: SaleModule,
    section: ACCESS_NAMES.sales,
  },
  {
    href: HREF.customer,
    component: CustomerModule,
    section: ACCESS_NAMES.customers,
  },
  {
    href: HREF.products,
    component: ProductModule,
    section: ACCESS_NAMES.products,
  },
  {
    href: HREF.importFromcCsv,
    component: ProductImportItem,
  },

  {
    href: HREF.supply,
    component: SupplierModule,
    section: ACCESS_NAMES.supply,
  },
  {
    href: HREF.warehouse,
    component: WarehouseModule,
    section: ACCESS_NAMES.warehouse,
  },
  {
    href: HREF.finance,
    component: FinanceModule,
    section: ACCESS_NAMES.finance,
  },
  {
    href: HREF.hr,
    component: HrModule,
    section: ACCESS_NAMES.hr,
  },
  {
    href: HREF.task,
    component: TaskModule,
    section: ACCESS_NAMES.tasks,
  },
  {
    href: HREF.integration,
    component: Integration,
    section: ACCESS_NAMES.integration,
  },
  {
    href: HREF.production,
    component: ProductionModule,
    section: ACCESS_NAMES.production,
  },
  {
    href: HREF.settings,
    component: SettingsModule,
    section: ACCESS_NAMES.settings,
  },
  {
    href: HREF.route,
    component: () => <RouteLink />,
    section: "default",
  },
  {
    href: HREF.profile,
    component: Profile,
    section: "default",
  },
  {
    href: HREF.notFound,
    component: NotFound,
    section: "default",
  },
];
