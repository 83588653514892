import get from "lodash.get";

const useBoard = (propsData) => {
  const { boardData, setBoardData } = propsData;

  const onDragEnd = (result, moveLane, moveCard) => {
    const { source, destination, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "lane") {
      // Moving lane
      changeLocalStateOnMoveLane(
        source,
        destination,
        boardData,
        setBoardData,
        moveLane
      );
      return;
    }

    const start = get(boardData, "lanes", []).find(
      (lane) => lane?.id === source.droppableId
    );
    const finish = get(boardData, "lanes", []).find(
      (lane) => lane?.id === destination.droppableId
    );

    if (start.id === finish.id) {
      // Moving only one list
      changeLocalStateOnMoveCardOnOneLane(
        start,
        source,
        destination,
        boardData,
        setBoardData,
        moveCard
      );

      return;
    }

    // Moving from one list to another
    changeLocalStateOnMoveCardOnLaneToAnother(
      start,
      finish,
      source,
      destination,
      boardData,
      setBoardData,
      moveCard
    );
  };

  const changeLocalStateOnMoveLane = async (
    source,
    destination,
    boardData,
    setBoardData,
    moveLane
  ) => {
    const newBoardDataLanes = [...(boardData.lanes || [])];
    const clonePrevBoardData = { ...(boardData || {}) };
    const currentLane = newBoardDataLanes[source.index] || {};
    newBoardDataLanes.splice(source.index, 1);
    newBoardDataLanes.splice(destination.index, 0, currentLane);
    setBoardData({ lanes: newBoardDataLanes });
    if (moveLane) {
      try {
        await moveLane(source?.index, destination?.index, currentLane);
      } catch (error) {
        setBoardData(clonePrevBoardData);
      }
    }
  };

  const changeLocalStateOnMoveCardOnOneLane = async (
    start,
    source,
    destination,
    boardData,
    setBoardData,
    moveCard
  ) => {
    const clonePrevBoardData = { ...(boardData || {}) };
    const newCards = [...(start.cards || [])];
    const currentCard = start.cards[source.index];
    newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, currentCard);

    const newLane = { ...start, cards: newCards };
    const newBoardData = {
      lanes: boardData?.lanes?.map((lane) =>
        lane.id === newLane.id ? newLane : lane
      ),
    };
    setBoardData(newBoardData);
    if (moveCard) {
      try {
        await moveCard(
          start,
          start,
          source.index,
          destination.index,
          currentCard
        );
      } catch (error) {
        setBoardData(clonePrevBoardData);
      }
    }
  };

  const changeLocalStateOnMoveCardOnLaneToAnother = async (
    start,
    finish,
    source,
    destination,
    boardData,
    setBoardData,
    moveCard
  ) => {
    const clonePrevBoardData = { ...(boardData || {}) };
    const startCards = [...(start.cards || [])];
    startCards.splice(source.index, 1);
    const startLane = {
      ...start,
      cards: startCards,
      total: +get(start, "total") - 1,
    };

    const currentCard = start.cards[source.index];
    const finishCards = [...(finish.cards || [])];
    finishCards.splice(destination.index, 0, currentCard);
    const finishLane = {
      ...finish,
      cards: finishCards,
      total: +get(finish, "total") + 1,
    };

    const newBoardData = {
      lanes: boardData?.lanes?.map((lane) =>
        lane.id === startLane.id
          ? startLane
          : lane.id === finishLane.id
          ? finishLane
          : lane
      ),
    };
    setBoardData(newBoardData);
    if (moveCard) {
      try {
        await moveCard(
          start,
          finish,
          source.index,
          destination.index,
          currentCard
        );
      } catch (error) {
        setBoardData(clonePrevBoardData);
      }
    }
  };

  const onSubmitAddCard = (editedLane, card) => {
    setBoardData({
      lanes: boardData?.lanes?.map((lane) =>
        lane.id === editedLane.id
          ? {
              ...lane,
              cards: [...(lane.cards || []), card],
              total: (lane?.total || 0) + 1,
            }
          : lane
      ),
    });
  };

  const onSubmitEditCard = (editedLane, editedCard) => {
    setBoardData({
      lanes: boardData?.lanes?.map((lane) =>
        lane.id === editedLane.id
          ? {
              ...lane,
              cards: lane?.cards.map((card) =>
                card.id === editedCard.id ? { ...card, ...editedCard } : card
              ),
            }
          : lane
      ),
    });
  };

  const addNewLane = (newLane) => {
    if (
      boardData?.lanes?.find(
        (prevLane) => get(prevLane, "id") === get(newLane, "id")
      )
    ) {
      return;
    } else {
      setBoardData((prev) => ({
        lanes: [...(prev?.lanes ?? []), newLane],
      }));
    }
  };

  const editLane = (editingLane) => {
    setBoardData({
      lanes: boardData?.lanes?.map((lane) =>
        lane.id === editingLane.id ? editingLane : lane
      ),
    });
  };

  const deleteLane = (deletingLane) => {
    setBoardData({
      lanes: boardData?.lanes?.filter((lane) => lane?.id !== deletingLane?.id),
    });
  };

  const deleteCard = (whichLane, deletingCard) => {
    setBoardData({
      lanes: boardData?.lanes?.map((lane) =>
        lane?.id === whichLane?.id
          ? {
              ...lane,
              cards: lane?.cards?.filter(
                (card) => card?.id !== deletingCard?.id
              ),
            }
          : lane
      ),
    });
  };

  return {
    state: { boardData },
    actions: {
      onDragEnd,
      onSubmitAddCard,
      onSubmitEditCard,
      addNewLane,
      editLane,
      deleteLane,
      deleteCard,
    },
  };
};

export default useBoard;
