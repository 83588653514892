import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";
import currencyFormatter from "services/number_format/currencyFormatter";

import Input from "../Input/Input";

const ControlledInputWithSpace = ({
  control,
  name,
  rules,
  errors,
  ...props
}) => {
  const inputChangeHandler = (e, formChangeHandler) => {
    const output = currencyFormatter(e.target.value)?.replaceAll(" ", "");
    formChangeHandler(output);
    const { onChange } = props;
    if (onChange) {
      onChange({ ...e, target: { ...e?.target, value: output } });
    }
  };
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          id={name}
          {...props}
          {...field}
          onFocus={(e) => {
            if (+(field.value || props.value || props?.defaultValue) === 0) {
              e.target.select();
            }
            props?.onFocus && props?.onFocus();
          }}
          value={currencyFormatter(field.value || props.value)}
          defaultValue={currencyFormatter(props.defaultValue + "")}
          onChange={(e) => inputChangeHandler(e, field.onChange)}
          hookformchangehandler={(defaultValue) =>
            field.onChange(currencyFormatter(defaultValue))
          }
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledInputWithSpace);
