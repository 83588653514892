import { styled } from "@mui/material";
import reactInputMask from "react-input-mask";

export const StyledInputMask = styled(reactInputMask)({
  width: "100%",
  height: "43px",
  borderRadius: "12px",
  border: "1px solid #B7BDC6",
  padding: "11.5px",
  boxShadow: "none",
  "&:focus": {
    outline: "none",
    borderColor: "#1976d2",
    boxShadow: "0 0 0 1px #1976d2",
  },
  "&.error": {
    borderColor: "#fc0707!important",
    "&:focus": {
      outline: "none",
      boxShadow: "0 0 0 1px #fc0707 !important",
    },
  },
});
