import { styled } from "@mui/material";

export const StyledDatePicker = styled("div")(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
  "&.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#fc0707",
  },
}));
