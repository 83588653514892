import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material";

export const Card = styled("div")({
  boxSizing: "border-box",
  backgroundColor: "#fff",
  padding: "15px",
  borderRadius: "7px",
});

export const GridBottom = styled(Grid)({
  display: "flex",
  justifyContent: "end",
  "& div": {
    display: "flex",
  },
});

export const ImgContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: "100px",
  height: "100px",
  borderRadius: "5px",
  marginLeft: "10px",
  cursor: "pointer",
  backgroundColor: "#F2F2F2",
  "&.disabled::before": {
    position: "absolute",
    content: '""',
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    borderRadius: "5px",
    backgroundColor: "rgba(240, 240, 240, 0.6)",
    zIndex: 999,
    cursor: "auto",
  },
  "& .file_delete": {
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: 1000,
    "&:hover": {
      color: "red",
    },
  },
  "& a": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .fileLabel": {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    opacity: "0",
    cursor: "pointer",
  },
  "& img": {
    width: "100px",
    height: "100px",
    borderRadius: "7px",
    objectFit: "cover",
  },
});
