import ImportItemContext from "./ImportItemContext";
import { useImportItem } from "./useImportItem";

const ImportItemProvider = ({ children }) => {
  const store = useImportItem();
  return (
    <ImportItemContext.Provider value={store}>
      {children}
    </ImportItemContext.Provider>
  );
};

export default ImportItemProvider;
