import { toast } from "react-hot-toast";
import { messages } from "./data";

export default function error_message_handler(code) {
  const language = localStorage.getItem("i18nextLng");
  let error_texts = language
    ? messages.func(code)[language]
    : messages.func(code).uz;

  if (code === 10000) {
    toast.error(error_texts.unknown);
  }
  if (code > 10000 && code % 1000 === 0) {
    toast.error(error_texts.not_found);
  }
  if (code > 10000 && code !== 11001 && code % 1000 === 1) {
    // toast.error(error_texts.exist);
  }
}
