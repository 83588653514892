import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "@mui/icons-material";

import { Menu, MenuItem } from "@mui/material";

import {
  ButtonWrapper,
  StyledIconButton,
  StyledKeyboardArrowDownIcon,
} from "../Common.style";
import {
  StyledDivider,
  StyledFlag,
  StyledMenuTypography,
  StyledTypography,
} from "./LanguageSelect.style";

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "uz"
  );
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchor(null);
  };

  const onClickMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const changeLanguage = (clickedLanguage) => {
    if (clickedLanguage !== language) {
      setLanguage(clickedLanguage);
      i18n.changeLanguage(clickedLanguage);
    }
    handleClose();
  };

  const isOpen = useMemo(() => Boolean(anchor), [anchor]);

  return (
    <>
      <ButtonWrapper isopen={isOpen} onClick={onClickMenu}>
        <StyledIconButton isopen={isOpen}>
          <Language sx={{ marginRight: "5px" }} />
          {
            <StyledTypography>
              {language === "uz" ? "Uz" : "Ru"}
            </StyledTypography>
          }
          <StyledKeyboardArrowDownIcon isopen={isOpen} />
        </StyledIconButton>
      </ButtonWrapper>
      <Menu
        id="menu-appBar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={handleClose}
        sx={{ mt: "2px" }}
      >
        <MenuItem
          onClick={() => {
            changeLanguage("uz");
            handleClose();
          }}
        >
          <StyledMenuTypography>
            <StyledFlag
              src={"/images/language/flag-uz-3d-round-250.png"}
              alt="flag-uz"
            />
            Uz
          </StyledMenuTypography>
        </MenuItem>
        <StyledDivider />
        <MenuItem
          onClick={() => {
            changeLanguage("ru");
            handleClose();
          }}
        >
          <StyledMenuTypography>
            <StyledFlag src={"/images/language/flag-ru.png"} alt="flag-uz" />
            Ru
          </StyledMenuTypography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelect;
