import { currency_convertor_data } from "../../../store/sozlamalar/currency/currency";
import { toast } from "react-hot-toast";

const get_Currency_Convertor_Reducer = (
  state = currency_convertor_data,
  action
) => {
  switch (action.type) {
    case "GET_CURRENCY_SETTINGS_0":
      return {
        data: action.payload.data.data,
      };
    case "GET_CURRENCY_SETTINGS_10000":
      toast.error("Unknown error to load all data");
      break;
    default:
      return state;
  }
};
export default get_Currency_Convertor_Reducer;
