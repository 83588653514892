import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";

import {
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
} from "./TreeTable.style";
import TableRow from "./components/TreeTableRow";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";

const TreeTable = ({
  columns = [],
  data = [],
  renderItemProps,
  onRowClick = () => {},
  emptydatamessage,
  innerTree,
  loading = false,
  tableBodyRowCount,
  isShowTableHeader = false,
}) => {
  const { t } = useTranslation();

  const renderHeader = useMemo(
    () => (
      <TableHead>
        <MuiTableRow>
          {columns.map((column, index) => (
            <StyledTableCell
              sx={{ ...(column?.style || {}), whiteSpace: "nowrap" }}
              key={index}
            >
              {column.translationKey ? t(column.translationKey) : column.header}
            </StyledTableCell>
          ))}
        </MuiTableRow>
      </TableHead>
    ),
    [columns]
  );

  const renderBody = () => {
    if (data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <StyledTableCell colSpan={columns.length} align="center">
              {emptydatamessage
                ? emptydatamessage
                : t("table.emptyDataSourceMessage")}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <StyledTableBody>
        {data.map((item, index) => (
          <>
            <TableRow
              sx={{ border: "none" }}
              onClick={() => onRowClick(item)}
              key={item._id}
            >
              {columns.map((column, cellIndex) => (
                <StyledTableCell
                  sx={column?.style}
                  key={`${cellIndex * 10 + 2.2}`}
                >
                  {renderRowItem(column, item, cellIndex, index)}
                </StyledTableCell>
              ))}
            </TableRow>

            {innerTree && (
              <TableRow
                sx={{
                  borderBottom:
                    data?.length === index + 1 ? "none" : "1.5px solid #D8D6DE",
                }}
                key={`detailPanelKey${index}`}
              >
                <StyledTableCell colSpan={columns.length + 2}>
                  {innerTree(item, index)}
                </StyledTableCell>
              </TableRow>
            )}
          </>
        ))}
      </StyledTableBody>
    );
  };

  const renderRowItem = (column, dataItem, index, dataIndex) =>
    column.renderComponent
      ? column.renderComponent(
          dataItem,
          { ...renderItemProps, dataIndex },
          index,
          t
        )
      : dataItem[column.dataKey];

  return (
    <TableContainer
      sx={{ overflowX: "scroll!important" }}
      component={StyledTableContainer}
    >
      {loading ? (
        <TableSkeleton
          tableBodyRowCount={tableBodyRowCount}
          columns={columns}
        />
      ) : (
        <MuiTable>
          {isShowTableHeader && renderHeader}
          {renderBody()}
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default memo(TreeTable);
