import { Suspense } from "react";
import ReactDOM from "react-dom";
import "moment/locale/uz-latn";
import { Provider } from "react-redux";

import { GlobalProvider } from "context";
import { Loader } from "components/common";

import App from "./App";
import store from "./redux/reducers";
import "./i18n";
import "./sass/style.css";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
