import { memo } from "react";

import { filterConst } from "constants";

import { StyledDiv } from "./FilterWrapperWithId.style";

const FilterWrapperWithId = ({ children }) => {
  return <StyledDiv id={filterConst.id}>{children}</StyledDiv>;
};

export default memo(FilterWrapperWithId);
