import {Api} from "../api";

export default class ProductionOrderApi extends Api {
    createOrder(data) {
        return this.execute("post", "/production/order/create", data);
    }
    updateOrder(data) {
        return this.execute("post", "/production/order/update", data);
    }
    getByIdOrder(data) {
        return this.execute("post", "/production/order/get-by-id", data);
    }
}
