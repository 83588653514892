import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import { useRequest, REQUEST_STATUSES, useQueryParams } from "hooks";
import { universal } from "services/api";
import { fileTypes } from "../constants/ImportItem.constants";
import { base64 } from "utils";
import { ProductListTableContext } from "components/common/ProductListTable/context";

export const useImportItem = () => {
  const {
    state: { list },
    actions: { setList },
  } = useContext(ProductListTableContext);

  const [errors, setErrors] = useState();
  const [open, setOpen] = useState(false);
  const { getParam, setParam } = useQueryParams();
  const [importItemClient, importItemData, importItemStatus, importItemError] =
    useRequest();
  const [
    importItemValidatorClient,
    ,
    importItemValidatorStatus,
    importItemValidatorError,
  ] = useRequest();
  const [
    importItemSubmitClient,
    ,
    importItemSubmitStatus,
    importItemSubmitError,
  ] = useRequest();
  const history = useHistory();
  const util = (a) => base64.encode(JSON.stringify(a));

  const downloadExample = async (
    id,
    organizationId,
    importAPI,
    downloadName,
    importOrganization,
    newImport
  ) => {
    try {
      const importUrl = `/back-api/admin/import/template/${importAPI}`;

      const data = await universal.client(importUrl, undefined, "get");
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${downloadName}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      toast.error("Error");
    }
  };

  const handleChange = async (
    e,
    id,
    organizationId,
    setLoading,
    setSuccess,
    // setErrors,
    importAPI,
    newImport,
    validateSubUrl,
    destinationOrganizationId,
    sourceOrganizationId
  ) => {
    //created by kahero
    const extension = e.name.split(".").pop();
    if (!fileTypes.includes(extension)) {
      toast.error("Csv file kiriting");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", e, e.name);
      setLoading(true);

      const validateUrlOrganizationId = `/back-api/admin/${validateSubUrl}/${organizationId}/validate`;
      const validateDoubleOrganizationId = `/back-api/admin/${validateSubUrl}/${sourceOrganizationId}/${destinationOrganizationId}/validate`;
      const validateSourceOrganizationId = `/back-api/admin/${validateSubUrl}/${sourceOrganizationId}/validate`;

      const validateUrl =
        destinationOrganizationId && sourceOrganizationId
          ? validateDoubleOrganizationId
          : sourceOrganizationId
          ? validateSourceOrganizationId
          : validateUrlOrganizationId;
      const data = await importItemValidatorClient.post(validateUrl, formData);
      setSuccess(data);
      setErrors(false);
      setLoading(false);
      setList(data);
      setOpen(false);
      toast.success("Muvaffaqiyatli");
    } catch (e) {
      setErrors(e);
      setLoading(false);
      toast.error("Error");
    }
  };

  const submitProducts = async (
    id,
    organizationId,
    setLoading,
    setSuccess,
    importAPI
  ) => {
    // try {
    //   const formData = new FormData();
    //   // Update the formData object
    //   setLoading(true);
    //   await importItemSubmitClient.post(
    //     organizationId
    //       ? `/back-api/admin/${
    //           importAPI === "material" || importAPI === "semi_product"
    //             ? `warehouse/${
    //                 importAPI === "semi_product" ? "semi-product" : importAPI
    //               }`
    //             : importAPI
    //         }/import/${organizationId}/confirm/${fileName}`
    //       : `/back-api/admin/${
    //           importAPI === "material" || importAPI === "semi_product"
    //             ? `warehouse/${
    //                 importAPI === "semi_product" ? "semi-product" : importAPI
    //               }`
    //             : importAPI
    //         }/import/confirm/${fileName}`,
    //     formData
    //   );
    //   setLoading(false);
    //   setOpen(false);
    //   setSuccess(false);
    //   toast.success("Muvaffaqiyatli");
    // } catch (e) {
    //   setSuccess(undefined);
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    if (importItemValidatorStatus === REQUEST_STATUSES.failed) {
      setErrors(importItemValidatorError);
    }
  }, [importItemValidatorStatus]);

  useEffect(() => {
    if (importItemSubmitStatus === REQUEST_STATUSES.failed) {
      setErrors(importItemSubmitError);
    }
    if (importItemSubmitStatus === REQUEST_STATUSES.success) {
      setParam("page", util(1));
    }
  }, [importItemSubmitStatus]);

  return {
    state: {
      errors,
      importItemState: {
        importItemData,
        importItemStatus,
        importItemError,
      },
      open,
    },

    actions: {
      downloadExample,
      handleChange,
      submitProducts,
      setOpen,
      setErrors,
    },
  };
};
