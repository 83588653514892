import { useEffect } from "react";

export default function useRefresh(alertText) {
  const refreshHandler = () => "bizni yozuv";

  useEffect(() => {
    window.onbeforeunload = refreshHandler;
    return () => (window.onbeforeunload = null);
  }, [alertText]);
}
