import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";

export const SECTIONS = [
  {
    translationKey: "SIDEBAR.STATISTICS.ON_TRADE",
    hasAccess: ACCESS_NAMES.statisticsSale,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.ON_GOODS",
    hasAccess: ACCESS_NAMES.statisticsProduct,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.ON_CATEGORIES",
    hasAccess: ACCESS_NAMES.statisticsCategory,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.ON_EMPLOYEE",
    hasAccess: ACCESS_NAMES.statisticsEmployee,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.ON_CUSTOMER",
    hasAccess: ACCESS_NAMES.statisticsCustomer,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.BY_TYPE_OF_PAYMENT",
    hasAccess: ACCESS_NAMES.statisticsPaymentMethod,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.ALL_BOUNDARIES",
    hasAccess: ACCESS_NAMES.statisticsReceipt,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.WAREHOUSE",
    hasAccess: ACCESS_NAMES.statisticsWarehouse,
  },
  {
    translationKey: "SIDEBAR.STATISTICS.CASHBOX",
    hasAccess: ACCESS_NAMES.statisticsCashbox,
  },
  {
    translationKey: "SIDEBAR.POS",
    hasAccess: ACCESS_NAMES.pos,
  },
  {
    translationKey: "SIDEBAR.SALE.SALE_CONTRACTS",
    hasAccess: ACCESS_NAMES.saleContract,
  },
  {
    translationKey: "SIDEBAR.CUSTOMERS.CUSTOMERS",
    hasAccess: ACCESS_NAMES.statisticsCustomer,
  },
  {
    translationKey: "SIDEBAR.CUSTOMERS.SALE_ORDERS",
    hasAccess: ACCESS_NAMES.saleOrder,
  },
  {
    translationKey: "SIDEBAR.CUSTOMERS.RECORD_CALLS",
    // hasAccess: ACCESS_NAMES.re,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.PRODUCTS",
    hasAccess: ACCESS_NAMES.product,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.CATEGORIES",
    hasAccess: ACCESS_NAMES.productCategory,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.ETIKET",
    hasAccess: ACCESS_NAMES.label,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.INCOME",
    hasAccess: ACCESS_NAMES.productIncome,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.TRANSFER",
    hasAccess: ACCESS_NAMES.productTransfer,
  },
  {
    translationKey: "SIDEBAR.PRODUCTS.RETURN",
    hasAccess: ACCESS_NAMES.productReturn,
  },
  {
    translationKey: "SIDEBAR.SUPPLY.PRODUCT_ORDERS",
    hasAccess: ACCESS_NAMES.productOrder,
  },
  {
    translationKey: "SIDEBAR.SUPPLY.MATERIAL_ORDERS",
    hasAccess: ACCESS_NAMES.materialOrder,
  },
  {
    translationKey: "SIDEBAR.SUPPLY.SUPPLIERS",
    hasAccess: ACCESS_NAMES.supplier,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.TMB",
    hasAccess: ACCESS_NAMES.warehouse,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.CATEGORIES",
    hasAccess: ACCESS_NAMES.materialCategory,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.ACTIVES",
    hasAccess: ACCESS_NAMES.active,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.INCOME",
    hasAccess: ACCESS_NAMES.materialIncome,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.TRANSFER",
    hasAccess: ACCESS_NAMES.materialTransfer,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.RETURN",
    hasAccess: ACCESS_NAMES.materialReturn,
  },
  {
    translationKey: "SIDEBAR.WAREHOUSE.YTM",
    // hasAccess: ACCESS_NAMES.,
  },
  {
    translationKey: "SIDEBAR.FINANCE.CASH",
    hasAccess: ACCESS_NAMES.cashbox,
  },
  {
    translationKey: "SIDEBAR.FINANCE.INVENTORY",
    hasAccess: ACCESS_NAMES.inventorization,
  },
  {
    translationKey: "SIDEBAR.FINANCE.CURRENCY",
    hasAccess: ACCESS_NAMES.currency,
  },
  {
    translationKey: "SIDEBAR.FINANCE.TAX",
    hasAccess: ACCESS_NAMES.tax,
  },
  {
    translationKey: "SIDEBAR.HR.EMPLOYEES",
    hasAccess: ACCESS_NAMES.employee,
  },
  {
    translationKey: "SIDEBAR.HR.SECTION",
    hasAccess: ACCESS_NAMES.section,
  },
  {
    translationKey: "SIDEBAR.HR.POSITION",
    hasAccess: ACCESS_NAMES.position,
  },
  {
    translationKey: "SIDEBAR.HR.ROLES",
    hasAccess: ACCESS_NAMES.role,
  },
  {
    translationKey: "SIDEBAR.HR.ATTENDANCE",
    hasAccess: ACCESS_NAMES.attendance,
  },
  {
    translationKey: "SIDEBAR.HR.SALARY",
    hasAccess: ACCESS_NAMES.salary,
  },
  {
    translationKey: "SIDEBAR.TASKS.TASKS",
    hasAccess: ACCESS_NAMES.tasks,
  },
  {
    translationKey: "SIDEBAR.INTEGRATION.TITLE",
    hasAccess: ACCESS_NAMES.integration,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.PLANNING",
    hasAccess: ACCESS_NAMES.productionPlan,
  },
  {
    // translationKey: "SIDEBAR.PRODUCTION.BOARD",
    // hasAccess: ACCESS_NAMES.boar,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.ORDERS",
    hasAccess: ACCESS_NAMES.productionOrder,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.PROCESS",
    hasAccess: ACCESS_NAMES.productionProcess,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.TEMPLATE",
    hasAccess: ACCESS_NAMES.productionProcessTemplate,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.PRODUCED_PRODUCTS",
    hasAccess: ACCESS_NAMES.productionProcessProduced,
  },
  {
    translationKey: "SIDEBAR.PRODUCTION.TRANSFERS",
    hasAccess: ACCESS_NAMES.productionProcessTransfer,
  },
  {
    translationKey: "SIDEBAR.SETTINGS",
    hasAccess: ACCESS_NAMES.settings,
  },
];
