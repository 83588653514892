import { useContext, useEffect, useMemo, memo } from "react";
import { Grid } from "@mui/material";

import { ListMultiTableContext } from "../context";
import MultiTable from "../../MultiTable/MultiTable";

const ListMultiTable = ({
  payload,
  renderItemProps,
  data = [],
  deleteIcon = true,
  headers,
  footers,
  total,
  indexing,
  indexStyle,
  tableStyle,
  ...props
}) => {
  const {
    state: { list },
    actions: { tableCellChangeHandler, setList },
  } = useContext(ListMultiTableContext);

  useEffect(() => {
    setList(data);
  }, []);

  const renderTable = useMemo(
    () => (
      <MultiTable
        loading={props.tableLoading}
        columns={props.columns}
        data={list}
        headers={headers}
        footers={footers}
        total={total}
        indexing={indexing}
        indexStyle={indexStyle}
        renderItemProps={{
          ...renderItemProps,
          onChange: tableCellChangeHandler,
        }}
        tableStyle={tableStyle}
      />
    ),
    [list, tableCellChangeHandler, renderItemProps, props.tableLoading]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderTable}
      </Grid>
    </Grid>
  );
};

export default memo(ListMultiTable);
