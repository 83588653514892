import { ArrowBack } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";

import DropMenu from "../DropMenu";

export default function ModalHeader(props) {
  const { headerMenuButtonOptions } = props;

  return (
    <div>
      <AppBar className={"zoom-to-response"} position="static">
        <Toolbar>
          <IconButton
            onClick={props.backFunc.bind(false, this)}
            edge="start"
            sx={{ marginRight: "16px" }}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {props.theme}
          </Typography>
          {headerMenuButtonOptions && (
            <IconButton
              edge="start"
              sx={{ marginRight: "16px" }}
              aria-label="menu"
              size="large"
            >
              <DropMenu
                style={{ color: "white" }}
                options={headerMenuButtonOptions}
              />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
