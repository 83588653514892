import AppBarContext from "./AppBarContext";
import { useAppBar } from "./useAppBar";

const AppBarProvider = ({ children }) => {
  const store = useAppBar();
  return (
    <AppBarContext.Provider value={store}>{children}</AppBarContext.Provider>
  );
};

export default AppBarProvider;
