import { styled } from "@mui/material";
import { Box } from "@mui/material";

export const StyledBox = styled(Box)({
  position: "absolute",
  minWidth: "70%",
  maxWidth: "800px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  padding: "20px",
  borderRadius: 12,
  boxShadow: 24,
  width: "calc(100% - 20px)",
  "@media(min-width: 600px)": {
    width: "500px",
    padding: "30px",
  },
  "@media(min-width: 900px)": {
    width: "700px",
  },
});

export const StyledUploader = styled("div")({
  marginTop: "20px",
  "& label": {
    padding: "1rem",
    maxWidth: "unset",
    height: 250,
    border: 0,
  },
  "& .drag-drop-file-icon-style": {
    width: "100px",
    height: "100% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F2F2F2",
    minHeight: "100px",
    cursor: "pointer",
    borderRadius: "12px",
  },
});
