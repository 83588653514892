import { styled } from "@mui/material";
import { Paper } from "@mui/material";

export const StyledFormField = styled(Paper)({
  display: "flex",
  borderRadius: 12,
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 11px) scale(1)",
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    transform: "translate(14px, -9px) scale(.75)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    borderRadius: "12px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #313649 !important",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #026aff !important",
  },
  "@media (min-width: 900px)": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      borderRadius: "initial",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
});
