import { styled } from "@mui/material";

export const HeaderStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 20,
  "& .close-icon": {
    cursor: "pointer",
  },
  borderBottom: "1px solid #DADADA",
});
