import { Divider, styled, Typography } from "@mui/material";

export const StyledDivider = styled(Divider)({
  margin: "0 !important",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
