import { Api } from "../api";

export default class ProductsApi extends Api {
  getProductById(data) {
    return this.execute("post", "/back-api/admin/product/get-by-id", data);
  }

  getProductForChoose(data) {
    return this.execute("post", "/back-api/admin/product/get-for-choose", data);
  }

  createProduct(data) {
    return this.execute("post", "/back-api/admin/product/create", data);
  }

  updateProduct(data) {
    return this.execute("post", "/back-api/admin/product/update", data);
  }

  getProductIncomeAutoComplete(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/income-autocomplete",
      data
    );
  }

  getProductOrderAutoComplete(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/order-autocomplete",
      data
    );
  }

  getProductOrderById(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/order/get-by-id",
      data
    );
  }

  createProductOrder(data) {
    return this.execute("post", "/back-api/admin/product/order/create", data);
  }

  updateProductOrder(data) {
    return this.execute("post", "/back-api/admin/product/order/update", data);
  }

  setProductOrderState(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/order/set-status",
      data
    );
  }

  getProductCategoryForChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/get-for-choose",
      data
    );
  }

  getProductCategoryPaging(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/get-paging",
      data
    );
  }

  cancelProductTransfer(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/transfer/cancel",
      data
    );
  }

  getProductForOffer(data) {
    return this.execute("post", "/back-api/admin/product/get-for-offer", data);
  }

  getProductPaging(data) {
    return this.execute("post", "/back-api/admin/product/get-paging", data);
  }

  getProductPagingPosSale(data) {
    return this.execute("post", "/back-api/admin/product/pos/get-paging", data);
  }

  deleteProductMany(data) {
    return this.execute("post", "/back-api/admin/product/delete-many", data);
  }

  deleteProductCategoryMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/delete-many",
      data
    );
  }

  chooseCategoryParent(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/choose-parent",
      data
    );
  }
}
