import { useContext, memo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import get from "lodash.get";

import { Lane } from "../components";
import { BoardContext } from "../context";
import {
  StyledBoardContainer,
  StyledBoardWrapper,
  StyledLaneContainer,
} from "../style/Board.style";

const Board = () => {
  const {
    state: { boardData },
    actions: { onDragEnd, addNewLane, editLane },
    props: { moveLane, moveCard, renderAddLane, boardContainerStyle },
  } = useContext(BoardContext);

  return (
    <StyledBoardWrapper>
      <DragDropContext
        onDragEnd={(onDragEndProps) =>
          onDragEnd(onDragEndProps, moveLane, moveCard)
        }
      >
        <Droppable droppableId="all-lanes" direction="horizontal" type="lane">
          {(provided) => (
            <StyledBoardContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={boardContainerStyle}
            >
              {boardData?.lanes?.map((lane, index) => (
                <Lane
                  lane={lane}
                  index={index}
                  style={{ border: "5px solid red" }}
                  key={get(lane, "id", Math.random())}
                />
              ))}
              {provided.placeholder}
            </StyledBoardContainer>
          )}
        </Droppable>
      </DragDropContext>
      {renderAddLane ? (
        <StyledLaneContainer id="add_lane_button">
          {renderAddLane(addNewLane, editLane)}
        </StyledLaneContainer>
      ) : (
        ""
      )}
    </StyledBoardWrapper>
  );
};

export default memo(Board);
