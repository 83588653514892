import { detectCurrencySide } from "services";
import numberWithSpaces from "services/number_format/number_format";

export const CustomerTableHeader = [
  {
    translationKey: "STATISTICS.TABLE.NAME",
    renderItem: (row, _, { mainCurrency }) => row?.supplier?.name,
  },
  {
    translationKey: "STATISTICS.TABLE.total_summ",
    cellStyle: { whiteSpace: "nowrap" },
    dataKey: "totalSumm",
    renderItem: (row, _, { mainCurrency }) =>
      detectCurrencySide(mainCurrency, numberWithSpaces(row.totalSumm)),
  },
  {
    translationKey: "STATISTICS.TABLE.refund_summ",
    cellStyle: { whiteSpace: "nowrap" },
    renderItem: (row, _, { mainCurrency }) =>
      detectCurrencySide(mainCurrency, numberWithSpaces(row.refundSumm)),
  },
  {
    translationKey: "STATISTICS.TABLE.total_count",
    cellStyle: { whiteSpace: "nowrap" },
    dataKey: "totalCount",
  },
  {
    translationKey: "STATISTICS.TABLE.refund_count",
    cellStyle: { whiteSpace: "nowrap" },
    dataKey: "refundCount",
  },
];

const SUPPLIER_TYPE_VALUES = {
  materialIncome: "material_income",
  productIncome: "product_income",
};
export const SUPPLIER_TYPE_OPTIONS = [
  {
    value: SUPPLIER_TYPE_VALUES.productIncome,
    translationKey: "statistics.product_income",
  },
  {
    value: SUPPLIER_TYPE_VALUES.materialIncome,
    translationKey: "statistics.material_income",
  },
];
