import {Api} from "../api";

class Auth extends Api {
    sendNumberToLogin(data) {
        return this.execute("post", "/login", data);
    }

    sendOtp(data) {
        return this.execute("post", "/verify", data);
    }
}

export default Auth;
