import toast from "react-hot-toast";
import {create_warehouse_category} from "../../../store/warehouse/category/create"
import {Success} from "../../../api";

const create_WareHouse_Category_Reducer = (state = create_warehouse_category, action) => {
    switch (action.type) {
        case "CREATE_WAREHOUSE_CATEGORY_0" :
            return Success("/warehouse/active-category", "Successfully created!", action.payload.history);
        case "CREATE_WAREHOUSE_CATEGORY_23001" :
            return toast.error(action.payload)
        case "CREATE_WAREHOUSE_CATEGORY_10001" :
            return toast.error(action.payload)
        case "CREATE_WAREHOUSE_CATEGORY_10000" :
            return toast.error(action.payload)
        default :
            return state
    }
}
export default create_WareHouse_Category_Reducer