import { useRef, memo } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon, Grid } from "@mui/material";
import { ClearRounded } from "@mui/icons-material";

import { universal } from "services/api";
import { newFileApi } from "services/api";

import { ImgContainer } from "./styled";

const MultipleImageUpload = ({ url, formStore, name, disabled = false }) => {
  const inputRef = useRef(null);
  const { watch, setValue } = formStore;
  const { id } = useParams();

  const deleteItem = async (imgId, index) => {
    setValue(
      name,
      watch(name).filter((item, ind) => ind !== index && item)
    );
    if (id) {
      await universal.client(
        `/back-api/admin/attachment/${imgId}`,
        {},
        "delete"
      );
    }
  };

  const imageHandler = async (event) => {
    let file = event.target.files[0];
    let path = URL.createObjectURL(event.target.files[0]);
    if (!id) {
      setValue(
        name,
        watch(name)
          ? [
              ...watch(name),
              { _id: watch(name).length, path, type: file.type, file },
            ]
          : [{ _id: 1, path, type: file.type, file }]
      );
    } else {
      const data = await newFileApi.universalSingle(url, file, id);
      setValue(
        name,
        watch(name)
          ? [
              ...watch(name),
              { _id: data._id, path: data.path, type: data.type },
            ]
          : [{ _id: data._id, path: data.path, type: data.type }]
      );
    }
  };

  return (
    <Grid container spacing={2}>
      {watch(name)?.map((item, index) => (
        <Grid item xs={"auto"} key={item._id}>
          <ImgContainer>
            <Icon
              onClick={() => deleteItem(item._id, index)}
              className="file_delete"
            >
              <ClearRounded />
            </Icon>
            <a
              href={
                id
                  ? `${process.env.REACT_APP_BASE_UPLOAD_URL}/public/${item.path}`
                  : item.path
              }
              target="_blank"
              style={{ color: "#000" }}
            >
              <Box className="img_item_box">
                {item?.type?.split("/")[0] === "image" ? (
                  <img
                    src={
                      id
                        ? `${process.env.REACT_APP_BASE_UPLOAD_URL}/public/${item.path}`
                        : item.path
                    }
                    alt="posView"
                  />
                ) : (
                  <Box>{item.type.split("/")[0]}</Box>
                )}
              </Box>
            </a>
          </ImgContainer>
        </Grid>
      ))}
      <Grid item xs={"auto"}>
        <ImgContainer className={disabled && "disabled"}>
          <Box>
            <img src={"/images/defaultImage.svg"} alt="posView" />
          </Box>
          <input
            ref={inputRef}
            style={{ cursor: "pointer" }}
            type="file"
            name="img-upload"
            id="input"
            className="img-holder-input"
            //      accept="image/*"
            onChange={(event) => imageHandler(event)}
          />
          <label htmlFor="input" className="fileLabel">
            Rasm tanlang
          </label>
        </ImgContainer>
      </Grid>
    </Grid>
  );
};

export default memo(MultipleImageUpload);
