import { styled } from "@mui/material";
import { Select } from "@mui/material";

export const StyledSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    width: "100%",
    borderRadius: 12,
  },
  "& .MuiOutlinedInput-input::after": {
    content: "none",
  },
  ".MuiModal-root .MuiPaper-root.MuiPaper-elevation": {
    boxShadow: "0 2px 4px rgba(0,0,0,.24)!important",
    marginTop: "5px",
  },
});
