import { Api } from "../api";

export default class ProductionApi extends Api {
  getProccess(data) {
    return this.execute("post", "/admin/production/process/get-paging", data);
  }

  getProcessForChoose(data) {
    return this.execute(
      "post",
      "/admin/production/process/get-for-choose",
      data
    );
  }

  productionUniversal(url, data) {
    return this.execute("post", url, data);
  }

  createProcess(data) {
    return this.execute("post", "/admin/production/process/create", data);
  }

  updateProcess(data) {
    return this.execute("post", "/admin/production/process/update", data);
  }

  getProcessesById(data) {
    return this.execute("post", "/admin/production/process/get-by-id", data);
  }

  getBlockById(data) {
    return this.execute(
      "post",
      "/admin/production/process/block/get-by-id",
      data
    );
  }

  deleteBlock(data) {
    return this.execute("post", "/admin/production/process/block/delete", data);
  }

  deleteLine(data) {
    return this.execute("post", "/admin/production/process/line/delete", data);
  }

  getLineById(data) {
    return this.execute(
      "post",
      "/admin/production/process/line/get-by-id",
      data
    );
  }

  changeCoordinates(data) {
    return this.execute(
      "post",
      "/admin/production/process/block/set-coordinates",
      data
    );
  }

  createBlockTemplate(data) {
    return this.execute("post", "/admin/production/process/block/create", data);
  }

  createLine(data) {
    return this.execute("post", "/admin/production/process/line/create", data);
  }

  updateBlock(data) {
    return this.execute("post", "/admin/production/process/block/update", data);
  }

  attachFile(data) {
    return this.execute(
      "post",
      "/admin/production/process/attachment/add",
      data
    );
  }

  deleteFile(data) {
    return this.execute(
      "post",
      "/admin/production/process/attachment/remove",
      data
    );
  }

  addMaterial(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/create",
      data
    );
  }

  getMaterials(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/get-all",
      data
    );
  }

  setUsed(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/set-used",
      data
    );
  }

  transfer(data) {
    return this.execute(
      "post",
      "/admin/production/process/transfer/create",
      data
    );
  }

  transferUpdate(data) {
    return this.execute(
      "post",
      "/admin/production/process/transfer/update",
      data
    );
  }

  receive(data) {
    return this.execute(
      "post",
      "/admin/production/process/transfer/receive",
      data
    );
  }

  cancel(data) {
    return this.execute(
      "post",
      "/admin/production/process/transfer/cancel",
      data
    );
  }

  getBlocks(data) {
    return this.execute(
      "post",
      "/admin/production/process/block/get-connected",
      data
    );
  }

  getBlocksAll(data) {
    return this.execute(
      "post",
      "/admin/production/process/block/get-all",
      data
    );
  }

  getProducts(data) {
    return this.execute(
      "post",
      "/admin/production/process/transfer/get-paging",
      data
    );
  }

  updateLineMaterial(data) {
    return this.execute("post", "/admin/production/process/line/update", data);
  }

  updateMaterialAmount(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/update",
      data
    );
  }

  deleteMaterialAmount(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/delete",
      data
    );
  }

  getMaterialsByLineId(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/choose-for-line",
      data
    );
  }

  getTransferAutoComplete(data) {
    return this.execute(
      "post",
      "/admin/production/process/material/transfer-autocomplete",
      data
    );
  }
}
