import { Api } from "../api";

export default class WarehouseApi extends Api {
  getActiveById(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/active/get-by-id",
      data
    );
  }

  createActive(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/active/create",
      data
    );
  }

  updateActive(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/active/update",
      data
    );
  }

  getMaterialIncomeAutoComplete(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/income-autocomplete",
      data
    );
  }

  getMaterialOrderAutoComplete(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/order-autocomplete",
      data
    );
  }

  getMaterialOrderById(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/order/get-by-id",
      data
    );
  }

  createMaterialOrder(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/order/create",
      data
    );
  }

  updateMaterialOrder(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/order/update",
      data
    );
  }

  setMaterialOrderState(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/order/set-status",
      data
    );
  }

  getWarehouseCategoryForChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/category/get-for-choose",
      data
    );
  }

  deleteMaterialMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/delete-many",
      data
    );
  }

  deleteMaterialCategoryMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/category/delete-many",
      data
    );
  }

  deleteMaterialActiveMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/active/delete-many",
      data
    );
  }

  deleteSupplierMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/supplier/delete-many",
      data
    );
  }

  getCategoryChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/category/choose-parent",
      data
    );
  }
}
