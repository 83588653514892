import OrganizationsSwitchContext from "./OrganizationsSwitchContext";
import { useOrganizationsSwitch } from "./useOrganizationsSwitch";

const OrganizationsSwitchProvider = ({ children }) => {
  const store = useOrganizationsSwitch();
  return (
    <OrganizationsSwitchContext.Provider value={store}>
      {children}
    </OrganizationsSwitchContext.Provider>
  );
};

export default OrganizationsSwitchProvider;
