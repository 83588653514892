import { postAxios } from "redux/api";
import error_message_handler from "services/error_message_handler";

export const get_Sale_Contract_Template_Is_Main =
  (state) => async (dispatch) => {
    try {
      await postAxios(`/back-api/admin/settings/template/get-main`, state)
        .then((response) => {
          return dispatch({
            type: "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_0",
            payload: response?.data?.data,
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace("/login");
          } else {
            error_message_handler(error.response.data.code);
          }
          if (error.response) {
            const code = error.response.data.code;
            switch (code) {
              case 10001:
                dispatch({
                  type: "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_10001",
                  payload: error.response.data.message,
                });
                break;
              case 10000:
                dispatch({
                  type: "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_10000",
                  payload: error.response.data.message,
                });
                break;
              default:
                return error;
            }
          }
        });
    } catch (err) {}
  };
