import { useState, useEffect } from "react";
import { getToken } from "../../../firebaseInit";

const Notifications = () => {
  const [, setTokenFound] = useState(false);
  async function subscribeTokenToTopic(token, topic) {
    fetch(
      "https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic,
      {
        method: "POST",
        headers: new Headers({
          Authorization: "key=" + process.env.REACT_APP_FIREBASE_SERVER_KEY,
        }),
      }
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw (
            "Error subscribing to topic: " +
            response.status +
            " - " +
            response.text()
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      const user = JSON.parse(localStorage.getItem("userData"));
      await subscribeTokenToTopic(data, user.is_boss ? user._id : user.boss_id);
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};
Notifications.propTypes = {};
export default Notifications;
