import { BoardProvider } from "../context";
import Board from "./Board";

const Index = (props) => {
  return (
    <BoardProvider propsData={props}>
      <Board />
    </BoardProvider>
  );
};

export default Index;
