export const HREF = {
  login: "/login",
  forgotPassword: "/forgot-password",
  statistics: "/statistics",
  pos: "/pos",
  sale: "/sale",
  customer: "/customer",
  customersBalance: "/customers-balance",
  products: "/products",
  importFromcCsv: "/import/from-csv/:id",
  supply: "/supply",
  warehouse: "/warehouse",
  finance: "/finance",
  hr: "/hr",
  task: "/task",
  integration: "/integration",
  production: "/production",
  settings: "/settings",
  route: "/route",
  profile: "/profile",
  notFound: "*",
};
