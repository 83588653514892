import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";

import { ControlledSimpleSelect } from "components/form";
import { SendSmsContext } from "../../context";
import { ContentStyle, StyledTextareaBox } from "./Content.style";
import { Button } from "components/common";

const Content = () => {
  const {
    state: {
      formStore: {
        handleSubmit,
        control,
        formState: { errors },
        register,
      },
      smsText,
      getSmsTypeState: { getSmsTypeData, getSmsTypeStatus },
    },
    actions: { submit, setSmsText, getSmsType },
  } = useContext(SendSmsContext);

  const { t } = useTranslation();

  useEffect(() => {
    getSmsType();
  }, []);

  const optionsSmsType = getSmsTypeData
    ?.filter((data) => {
      if (data?.isActive) {
        return data;
      }
    })
    ?.map((item) => ({
      ...item,
      label: item.name,
      value: item._id,
    }));

  return (
    <ContentStyle>
      <div>Yuboruvchi: 3700</div>
      <form onSubmit={handleSubmit(submit)}>
        <StyledTextareaBox>
          <TextField
            value={smsText}
            rows={4}
            multiline
            id="outlined-basic"
            label="Xabarlarni kiriting"
            variant="outlined"
            sx={{ width: "100%" }}
            {...register("content", {
              required: true,
              value: smsText,
              onChange: (e) => setSmsText(e?.target?.value),
            })}
          />
        </StyledTextareaBox>
        <div className="d-flex justify-between" style={{ alignItems: "end" }}>
          <ControlledSimpleSelect
            rules={{ required: true }}
            name="integrationId"
            control={control}
            options={optionsSmsType}
            errors={errors}
            style={{ minWidth: "150px" }}
            label="Sms"
          />
          <Button
            variant="contained"
            type="submit"
            className="submit_btn"
            size="small"
            //   loading={deleteNoteLoader}
          >
            Sms yuborish
          </Button>
        </div>
      </form>
    </ContentStyle>
  );
};

export default Content;
