import { useEffect, memo } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import get from "lodash.get";

import { Label } from "..";
import Error from "../Error/Error";
import { StyledSelect } from "./Select.style";

const Select = ({
  options = [],
  label,
  onChange,
  itemLabelKey,
  inputType = "string",
  error,
  ...props
}) => {
  const { t } = useTranslation();

  const renderLabel = (label) => (
    <Label disabled={props?.disabled}>{label}</Label>
  );

  const changeHandler = (event, option) => {
    onChange(option);
  };

  const getOptionLabel = (option) => {
    return typeof props.getOptionLabel === "function"
      ? props.getOptionLabel(option)
      : option.label || t(option.translationKey);
  };
  const getItemLabel = (option) => {
    return itemLabelKey
      ? typeof itemLabelKey === "function"
        ? itemLabelKey(option)
        : get(option, itemLabelKey, "")
      : t(option.translationKey);
  };

  useEffect(() => {
    if (props.hookformchangehandler && props.defaultValue) {
      props.hookformchangehandler(props.defaultValue);
    }
  }, [props.defaultValue]);

  const renderSelect = () => (
    <StyledSelect
      className={error && "error"}
      renderInput={(params) => <TextField {...params} type={inputType} />}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <MenuItem {...props}>{getItemLabel(option)}</MenuItem>
      )}
      blurOnSelect
      loadingText={t("COMMON.LOADING")}
      onChange={changeHandler}
      {...props}
    />
  );

  const renderError = (error) => (
    <Error>{error.message || "Invalid field"}</Error>
  );

  return (
    <div>
      {label && renderLabel(label)}
      {renderSelect()}
      {error && renderError(error)}
    </div>
  );
};
export default memo(Select);
