import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material";

export const StyledButton = styled(Button)({
  height: "38px",
  borderRadius: "12px",
});

export const StyledTypography = styled(Typography)({
  fontSize: "15px",
  marginLeft: "5px",
  fontWeight: 500,
  lineHeight: "26px",
  display: "none",
  "@media(min-width: 600px)": {
    display: "block",
  },
});
