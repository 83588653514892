import React, { useContext, useEffect, useState } from "react";

import { REQUEST_STATUSES, useRequest } from "hooks";
import { CustomerListContext } from "pages/customer/customers/list/context";
import { useForm } from "react-hook-form";
import { PaginationTableContext } from "components/common/PaginationTable/context";
import toast from "react-hot-toast";

const useSendSms = () => {
  const {
    state: { rows },
    actions: { setRows },
  } = useContext(PaginationTableContext);

  const [isOpen, setIsOpen] = useState(false);
  const [smsText, setSmsText] = useState("");

  const [client, data, status] = useRequest();
  const [getSmsTemplatesClient, getSmstemplatesData, getSmsTemplatesStatus] =
    useRequest();
  const [getSmsTypeClient, getSmsTypeData, getSmsTypeStatus] = useRequest();
  const [smsTemplatesList, setSmsTemplatesList] = useState([]);

  const formStore = useForm();
  const {
    setError,
    reset,
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = formStore;

  const submit = (data) => {
    client.post("/back-api/admin/integration/sms/send", data);
  };
  const getSmsTemplates = async (data) => {
    await getSmsTemplatesClient.post(
      `/back-api/admin/settings/sms-template/get-paging`,
      data
    );
  };
  const getSmsType = () => {
    getSmsTypeClient.post("/back-api/admin/settings/integration/get-all", {
      service: "sms",
    });
  };
  useEffect(() => {
    if (getSmsTemplatesStatus === REQUEST_STATUSES.success) {
      setSmsTemplatesList((prev) => prev.concat(getSmstemplatesData?.data));
    }
  }, [getSmsTemplatesStatus]);
  useEffect(() => {
    if (status === REQUEST_STATUSES.success) {
      const checkedRows = rows.map((row) => {
        row.checked = false;
        return row;
      });
      setRows(checkedRows);
      toast.success("Muvaffaqiyatli");
    }
  }, [status]);

  return {
    state: {
      isOpen,
      formStore,
      smsText,
      getSmsTemplatesState: { getSmstemplatesData, getSmsTemplatesStatus },
      getSmsTypeState: { getSmsTypeData, getSmsTypeStatus },
      smsTemplatesList,
      status,
    },
    actions: {
      setIsOpen,
      submit,
      setSmsText,
      getSmsTemplates,
      getSmsType,
      setSmsTemplatesList,
    },
  };
};

export default useSendSms;
