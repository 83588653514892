import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Pagination,
  Typography,
} from "@mui/material";

import { REQUEST_STATUSES, useRequest } from "hooks";

import { StyledDialogContent } from "./AutoCompleteDialog.style";
import Loader from "../Loader/Loader";
import SubmitButtons from "../Buttons/SubmitButtons/SubmitButtons";

const AutoCompleteDialog = (props) => {
  const { t } = useTranslation();
  const {
    url,
    payload,
    label,
    onSubmit,
    submitStatus,
    onFocus,
    isAllItem,
    setIsAllItem,
    isShowAddAllCheckbox,
    defaultChoseItems = [],
    onSubmitLoader = false,
  } = props;
  const [choseItemStore, setChoseItemStore] = useState(defaultChoseItems);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [getDataByChooseClient, getDataByChooseData, getDataByChooseStatus] =
    useRequest();
  const [getAllDataClient] = useRequest();

  const getDataByChoose = (page) => {
    setIsOpenDialog(true);
    getDataByChooseClient.post(url, { limit: 10, page, ...payload });
  };

  const renderLabel = () => (
    <Typography
      onFocus={onFocus}
      onClick={() => getDataByChoose(1)}
      color="primary"
      variant="subtitle1"
      sx={{ fontWeight: "bold", cursor: "pointer" }}
    >
      {label}
    </Typography>
  );

  const onChangeChoseStore = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setChoseItemStore((prevState) => {
        const nextGenerationState = [...prevState, id];
        setIsAllItem &&
          setIsAllItem(
            nextGenerationState?.length === getDataByChooseData?.total
          );
        return nextGenerationState;
      });
    } else {
      setChoseItemStore((prevState) => {
        const nextGenerationState = prevState.filter((itm) => itm !== id);
        setIsAllItem &&
          setIsAllItem(
            nextGenerationState?.length === getDataByChooseData?.total
          );
        return nextGenerationState;
      });
    }
  };

  const onChangeAddAll = async () => {
    if (isAllItem) {
      setIsAllItem && setIsAllItem(false);
      setChoseItemStore([]);
    } else {
      setIsAllItem && setIsAllItem(true);
      const { data } = await getAllDataClient.post(url, {
        page: 1,
        limit: getDataByChooseData?.total || 1,
        ...payload,
      });
      setChoseItemStore(data?.map((item) => item?._id) || []);
    }
  };

  useEffect(() => {
    if (submitStatus === REQUEST_STATUSES.success) {
      console.log("Hello");
      setIsOpenDialog(false);
    }
  }, [submitStatus]);

  const renderDialog = () => (
    <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{label}</Typography>
        {isShowAddAllCheckbox && (
          <FormControlLabel
            sx={{ userSelect: "none" }}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="medium" />}
                checked={isAllItem}
                checkedIcon={<CheckBox fontSize="medium" />}
                name="all"
                color="primary"
                onChange={onChangeAddAll}
              />
            }
            label={t("COMMON.ADD_ALL")}
          />
        )}
      </DialogTitle>
      <StyledDialogContent dividers>
        {getDataByChooseStatus === REQUEST_STATUSES.loading ? (
          <Loader />
        ) : (
          <>
            {getDataByChooseData?.data?.map((chooseItem, index) => (
              <FormControlLabel
                key={index}
                sx={{ userSelect: "none" }}
                control={
                  <Checkbox
                    id={chooseItem?._id}
                    value={chooseItem?._id}
                    icon={<CheckBoxOutlineBlank fontSize="medium" />}
                    checked={choseItemStore?.includes(chooseItem?._id)}
                    checkedIcon={<CheckBox fontSize="medium" />}
                    onChange={onChangeChoseStore}
                    name="checked"
                    color="primary"
                  />
                }
                label={chooseItem?.name}
              />
            ))}
            {renderPagination()}
          </>
        )}
      </StyledDialogContent>
      <DialogActions>
        <SubmitButtons
          loading={onSubmitLoader}
          onClickCancel={() => setIsOpenDialog(false)}
          onClickSubmit={() => onSubmit(choseItemStore)}
        />
      </DialogActions>
    </Dialog>
  );

  const renderPagination = () => (
    <Pagination
      count={Math.ceil(getDataByChooseData?.total / 10)}
      color="primary"
      onChange={(e, v) => getDataByChoose(v)}
    />
  );

  return (
    <Box>
      {renderLabel()}
      {renderDialog()}
    </Box>
  );
};

export default memo(AutoCompleteDialog);
