import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material";

export const StyledButton = styled(Button)({
  borderRadius: "12px",
  position: "relative",
  transition: "all .2s",
});
export const StyledTypography = styled(Typography)({
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: "26px",
});
