import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { AddCircle, Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import { StyledAppBar, StyledIconButtonBlock } from "../style/AppBar.style";
import { CREATE_SECTIONS } from "../constants/AppBar.constants";
import SectionsVideoToUseList from "../components/SectionsVideoToUseList/SectionsVideoToUseList";
import { LanguageSelect, MenuOption, UserMenu } from "../components";

const AppBar = ({
  handleDrawer,
  handleDrawerToggled,
  divRef,
  open,
  toggled,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const goCreatePage = (section) => {
    history.push(section?.href);
  };

  return (
    <StyledAppBar position="static">
      <Toolbar
        style={{ justifyContent: "space-between" }}
        className="nav-wrapper"
      >
        <StyledIconButtonBlock>
          <IconButton
            className="md"
            onClick={handleDrawer}
            ref={divRef}
            color="inherit"
            edge="start"
            aria-label="menu"
            size="large"
          >
            {open ? <Close /> : <MenuIcon />}
          </IconButton>
          <IconButton
            className="sm"
            onClick={handleDrawerToggled}
            ref={divRef}
            color="inherit"
            edge="start"
            aria-label="menu"
            size="large"
          >
            {toggled ? <Close /> : <MenuIcon />}
          </IconButton>
        </StyledIconButtonBlock>
        <Typography variant="h5" style={{ flexGrow: 1 }}></Typography>
        <MenuOption
          options={CREATE_SECTIONS}
          icon={<AddCircle />}
          innerLabel={t("COMMON.CREATE_SECTION")}
          onClickMenuItem={goCreatePage}
        />
        {/* <SectionsVideoToUseList /> */}
        <LanguageSelect />
        <UserMenu />
      </Toolbar>
    </StyledAppBar>
  );
};

export default memo(AppBar);
