import { useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import RoleManager from "services/role_management";

import { getToken } from "../../../Token";
import { routers } from "../../../routes";
import { WindowLoader } from "..";

const RouteLink = () => {
  const roles = useSelector((state) => state.get_hr_access_reducer);
  const [hasAccess] = RoleManager();
  const history = useHistory();

  useEffect(() => {
    if (!roles?.loading && !roles.data?.data?.length) {
      history.push("/pos/choose-organization");
    }
    if (!roles?.loading && roles.data?.data?.length) {
      const rolesObject = roles.data?.data[0]?.role;
      Object.keys(rolesObject)?.every(function (key, index) {
        if (hasAccess(key)) {
          if (routers.find((item) => item.section === key)) {
            history.push(routers.find((item) => item.section === key).url);
            return false;
          }
        }
        return true;
      });
    }
  }, [roles?.loading]);
  if (getToken() && roles?.loading) {
    return (
      <div>
        <WindowLoader w100={true} />
      </div>
    );
  }
  return null;
};

export default memo(RouteLink);
