import { useState } from "react";

export const usePosProducts = () => {
  const [products, setProducts] = useState(getPosData);
  const setProductsFuck = (value) => {
    setProducts(value);
    setPosData(value);
  };
  return [products, setProductsFuck];
};

export function getPosData() {
  return JSON.parse(localStorage.getItem("pos"));
}

export function setPosData(data) {
  localStorage.setItem("pos", JSON.stringify(data));
}
