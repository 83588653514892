import AsideContext from "./AsideContext";
import { useAside } from "./useAside";

const AsideProvider = ({ children }) => {
  const store = useAside();
  return (
    <AsideContext.Provider value={store}>{children}</AsideContext.Provider>
  );
};

export default AsideProvider;
