import GlobalContext from "./GlobalContext";
import useGlobal from "./useGlobal";

const GlobalProvider = ({ children }) => {
  const store = useGlobal();
  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;
