import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@mui/material";
import { Close, FilterAlt } from "@mui/icons-material";

import {
  DateRangePicker,
  DebounceInput,
  LabeledSelect,
  MultiSelect,
  TimeRangePicker,
} from "components/form";
import { useQueryParams } from "hooks";

import { FilterFieldTypes } from "../constants/Filter.constants";
import useFilter from "../hooks/useFilter";
import { GridSkeletonStyle } from "../../Skeletons/GridSkeleton/GridSkeleton.style";
import {
  CloseIconBlock,
  FilterBlock,
  FilterContainer,
  FilterContent,
  FilterIconBlock,
  FilterTitle,
  StyledCurtain,
  Title,
} from "./Filter.style";

const Filter = ({ fields = [] }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const {
    dateRangePickerCloseHandler,
    selectChangeHandler,
    debounceInputChangeHandler,
    multiSelectChangeHandler,
    timeRangePickerChangeHandler,
  } = useFilter();
  const { getParam } = useQueryParams();

  const renderItem = (item) => {
    switch (item.type) {
      case FilterFieldTypes.select:
        return (
          <LabeledSelect
            defaultValue={getParam(item.name) ?? "all"}
            onChange={selectChangeHandler}
            {...item}
          />
        );
      case FilterFieldTypes.dateRangePicker:
        return (
          <DateRangePicker
            defaultValues={[
              getParam(item.fromDateName),
              getParam(item.toDateName),
            ]}
            onClose={dateRangePickerCloseHandler}
            {...item}
          />
        );
      case FilterFieldTypes.debounceInput:
        return (
          <DebounceInput
            defaultValue={getParam(item.name)}
            onChange={debounceInputChangeHandler}
            {...item}
          />
        );
      case FilterFieldTypes.multiSelect:
        return (
          <MultiSelect
            defaultValue={getParam(item.name) ?? []}
            onChangeLocal={multiSelectChangeHandler}
            {...item}
          />
        );
      case FilterFieldTypes.timeRangePicker:
        return (
          <TimeRangePicker
            defaultValues={[
              getParam(item.fromTimeName || "from_time") || "00",
              getParam(item.toTimeName || "to_time") || "00",
            ]}
            onChange={timeRangePickerChangeHandler}
            {...item}
          />
        );
      case FilterFieldTypes.component:
        return item.renderComponent();
      default:
        return <></>;
    }
  };

  return (
    <FilterContainer>
      <FilterIconBlock>
        <IconButton onClick={() => setShow((prevState) => !prevState)}>
          <FilterAlt />
        </IconButton>
      </FilterIconBlock>
      <StyledCurtain
        className={show ? "show" : ""}
        onClick={() => setShow((prevState) => !prevState)}
      />
      <FilterContent className={show ? "show" : ""}>
        <FilterTitle>
          <FilterBlock>
            <Title>{t("COMMON.FILTER")}</Title>
            <CloseIconBlock>
              <IconButton onClick={() => setShow((prevState) => !prevState)}>
                <Close />
              </IconButton>
            </CloseIconBlock>
          </FilterBlock>
          <hr />
        </FilterTitle>
        <Grid container spacing={1} classes={{ root: "justify-content-end" }}>
          {fields.map(({ grid, loading = false, ...item }, index) => {
            return loading ? (
              <Grid xs={3} {...grid} item key={index}>
                <GridSkeletonStyle
                  animation="wave"
                  variant="circular"
                  height={43}
                />
              </Grid>
            ) : (
              <Grid xs={3} {...grid} item key={index}>
                {renderItem(item)}
              </Grid>
            );
          })}
        </Grid>
      </FilterContent>
    </FilterContainer>
  );
};

export default memo(Filter);
