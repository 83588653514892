import { styled } from "@mui/material";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)(({ typeforstyle }) => ({
  display: "block",
  minWidth: "80px",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderRadius: 12,
  },
  "& .error .MuiOutlinedInput-root": {
    borderColor: "red !important",
  },
  "& input": {
    paddingRight: typeforstyle === "password" ? "40px !important" : "0",
  },
}));

export const StyledIconWrapper = styled("div")({
  position: "absolute",
  bottom: "0",
  right: "10px",
  height: "43px",
  width: "30px",
  overflow: "hidden",
  borderRadius: 12,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "& .visibilityIcon": {
    position: "absolute",
    right: "0",
  },
  "& .visibilityOffIcon": {
    position: "absolute",
    right: "0",
  },
});

export const StyledInputWrapper = styled("div")(({ ispassword }) => ({
  position: ispassword && "relative",
}));
