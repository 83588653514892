import { useContext, useEffect, useMemo, useState, memo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Checkbox,
  Collapse,
} from "@mui/material";
import get from "lodash.get";
import { NavigateNext } from "@mui/icons-material";
import { filterConst } from "constants";

import { REQUEST_STATUSES, useQueryParams } from "hooks";

import { PaginationTableContext } from "../context";
import { DEFAULT_ROW_LIMIT } from "../constants/PaginationTable.constants";
import TableSkeleton from "../../Skeletons/TableSkeleton/TableSkeleton";
import PaginationSkeleton from "../../Skeletons/TableSkeleton/PaginationSkeleton";
import {
  StyledCheckboxTableCell,
  StyledDetailPanelTableCell,
  StyledIconButton,
  StyledIndexTableCell,
  StyledPaper,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTablePagination,
  TableCellBodyStyle,
  TableCellHeaderStyle,
} from "../style/PaginationTable.style";
import RoleManager from "services/role_management";

const PaginationTable = ({ ...props }) => {
  const {
    url,
    columns = [],
    renderItemProps,
    onRowClick,
    detailPanel,
    selection = false,
    filterWrapperId = filterConst.id,
    indexing = true,
    filterWatch = [],
  } = props;
  const [pageHasChanged, setPageHasChanged] = useState(false);
  const [fetchedLastPage, setFetchedLastPage] = useState(-1);
  const [hasAccess] = RoleManager();

  const { search } = useLocation();
  const { t } = useTranslation();
  const { getParam, setParam, getAllParams } = useQueryParams();
  const {
    state: { contentStore, rows, allRowsAreChecked },
    actions: {
      getContent,
      onChangeCheckBoxInHeader,
      stopPropagation,
      onChangeCheckBoxTableRow,
    },
  } = useContext(PaginationTableContext);

  //-detect filterHeigh

  const [filterHeight, setFilterHeight] = useState(
    document.getElementById(filterWrapperId)?.clientHeight
  );

  window.addEventListener("resize", function () {
    setFilterHeight(document.getElementById(filterWrapperId)?.clientHeight);
  });

  useEffect(() => {
    setFilterHeight(document.getElementById(filterWrapperId)?.clientHeight);
  }, [document.getElementById(filterWrapperId), ...filterWatch]);

  //+detect filterHeigh

  const filterParams = useMemo(() => getAllParams(), [search]);

  const [oldPage, setOldPage] = useState(getParam("page") || 0);
  const rowsPerPage = useMemo(
    () => parseInt(getParam("limit")) || DEFAULT_ROW_LIMIT,
    [search]
  );
  const currentPage = useMemo(() => parseInt(getParam("page")) || 0, [search]);
  const currentRows = useMemo(
    () =>
      rows?.slice(rowsPerPage * currentPage, rowsPerPage * (currentPage + 1)),
    [rows, currentPage, rowsPerPage]
  );

  const handleChangePage = (event, newPage) => {
    setParam("page", parseInt(newPage));
    setPageHasChanged(true);
  };

  const resetPage = () => {
    if (getParam("page") !== 0) setParam("page", 0);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setParam("limit", newRowsPerPage);
  };

  useEffect(() => {
    const filter = {
      ...filterParams,
      page: 1 + +filterParams.page || 1,
      limit: +filterParams.limit || DEFAULT_ROW_LIMIT,
    };
    if (oldPage === filter?.page) {
      resetPage();
      getContent(url, filter, true);
      setFetchedLastPage(-1);
    } else {
      setOldPage(filter?.page);
      if (filterParams.page > fetchedLastPage) {
        getContent(url, filter);
        setFetchedLastPage(filterParams.page);
      }
    }
    if (!pageHasChanged) resetPage();
    else setPageHasChanged(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const renderHeader = (columns) => {
    return (
      <TableHead>
        <TableRow>
          {selection && (
            <TableCell style={{ width: currentRows.length ? "auto" : "56px" }}>
              <Checkbox
                onChange={onChangeCheckBoxInHeader}
                sx={{ padding: 0 }}
                checked={allRowsAreChecked}
                indeterminate={allRowsAreChecked}
              />
            </TableCell>
          )}
          {detailPanel && <TableCell />}
          {currentRows.length ? indexing && <TableCell /> : null}
          {columns.map(
            (column, index) =>
              (column?.hasAccess === undefined ||
                hasAccess(column?.hasAccess)) && (
                <TableCellHeaderStyle
                  align={column.align || "left"}
                  key={index}
                >
                  {column.translationKey
                    ? t(column.translationKey)
                    : column.name}
                </TableCellHeaderStyle>
              )
          )}
        </TableRow>
      </TableHead>
    );
  };

  const renderItem = (row, column) =>
    column.renderItem
      ? column.renderItem(row, t, renderItemProps)
      : get(row, column.dataKey);

  const renderBody = (store) => {
    const { status, error } = store;

    if (status === REQUEST_STATUSES.failed)
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length + 1} align="center">
              {error?.message}
            </TableCell>
          </TableRow>
        </TableBody>
      );

    return (
      <TableBody>
        {currentRows.map((row, index) => (
          <DetailPaneWithTableBody
            detailPanel={detailPanel}
            index={index}
            onRowClick={onRowClick}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            columns={columns}
            row={row}
            key={index}
          >
            {selection && (
              <StyledCheckboxTableCell
                onClick={stopPropagation}
                align={"left"}
                component="th"
                scope="row"
              >
                <StyledIconButton
                  onClick={() => {
                    onChangeCheckBoxTableRow(row);
                  }}
                >
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={row.checked || false}
                  />
                </StyledIconButton>
              </StyledCheckboxTableCell>
            )}
            {indexing && (
              <StyledIndexTableCell>
                {currentPage * rowsPerPage + index + 1}
              </StyledIndexTableCell>
            )}
            {columns.map(
              (column, index) =>
                (column?.hasAccess === undefined ||
                  hasAccess(column?.hasAccess)) && (
                  <TableCellBodyStyle
                    align={column.align || "left"}
                    key={index}
                    component="th"
                    scope="row"
                    style={{ ...column.cellStyle }}
                  >
                    {renderItem(row, column)}
                  </TableCellBodyStyle>
                )
            )}
          </DetailPaneWithTableBody>
        ))}
      </TableBody>
    );
  };

  const renderPagination = (store) => (
    <StyledTablePagination
      rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
      colSpan={columns.length + 1}
      count={get(contentStore, "res.total", 0)}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      component="div"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  return (
    <StyledPaper {...props} filterheight={filterHeight}>
      <StyledTableContainer>
        {contentStore?.status === REQUEST_STATUSES.loading ? (
          <TableSkeleton tableBodyRowCount={10} columns={columns} />
        ) : (
          <Table
            id={"customMaterialTable"}
            stickyHeader
            aria-label="custom pagination table"
          >
            {renderHeader(columns)}
            {renderBody(contentStore)}
          </Table>
        )}
      </StyledTableContainer>
      {contentStore?.status === REQUEST_STATUSES.loading ? (
        <PaginationSkeleton />
      ) : (
        renderPagination()
      )}
    </StyledPaper>
  );
};

const DetailPaneWithTableBody = ({ children, ...props }) => {
  const [isShowDetailPanel, setIsShowDetailPanel] = useState(false);
  const { index, onRowClick, detailPanel, columns, row } = props;
  return (
    <>
      <StyledTableBodyRow
        key={index + 1}
        sx={{
          "&:hover": {
            backgroundColor: onRowClick && "rgba(0, 0, 0, 0.04)",
            cursor: onRowClick && "pointer",
          },
        }}
        onClick={() => onRowClick && onRowClick(row)}
      >
        {detailPanel && (
          <StyledIndexTableCell>
            <IconButton onClick={() => setIsShowDetailPanel((prev) => !prev)}>
              <NavigateNext
                sx={{
                  transform: `rotate(${isShowDetailPanel ? "90deg" : "0deg"})`,
                  transition: ".3s",
                }}
              />
            </IconButton>
          </StyledIndexTableCell>
        )}

        {children}
      </StyledTableBodyRow>
      {detailPanel && (
        <StyledTableBodyRow key={index * 11.9}>
          <StyledDetailPanelTableCell
            sx={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 2}
          >
            <Collapse in={isShowDetailPanel}>{detailPanel(row)}</Collapse>
          </StyledDetailPanelTableCell>
        </StyledTableBodyRow>
      )}
    </>
  );
};

export default memo(PaginationTable);
