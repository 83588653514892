import {
  lastMonthEnd,
  lastMonthStart,
  lastSevenDay,
  lastThirtyDay,
  lastWeekEnd,
  lastWeekStart,
  thisMonth,
  thisWeek,
  today,
  yesterday,
} from "./LastDays";

export const CUSTOM_DATE_RANGE = [
  {
    translationKey: "date_picker.today",
    isFocusedLabel: { from: today, to: today },
  },
  {
    translationKey: "date_picker.yesterday",
    isFocusedLabel: { from: yesterday, to: yesterday },
  },
  {
    translationKey: "date_picker.this_week",
    isFocusedLabel: { from: thisWeek, to: today },
  },
  {
    translationKey: "date_picker.last_week",
    isFocusedLabel: { from: lastWeekStart, to: lastWeekEnd },
  },
  {
    translationKey: "date_picker.this_month",
    isFocusedLabel: { from: thisMonth, to: today },
  },
  {
    translationKey: "date_picker.last_month",
    isFocusedLabel: { from: lastMonthStart, to: lastMonthEnd },
  },
  {
    translationKey: "date_picker.last_7_days",
    isFocusedLabel: { from: lastSevenDay, to: today },
  },
  {
    translationKey: "date_picker.last_30_days",
    isFocusedLabel: { from: lastThirtyDay, to: today },
  },
];
