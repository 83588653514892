import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";
import get from "lodash.get";

import SelectWithStaticOption from "../SelectWithStaticOption/SelectWithStaticOption";

const ControlledSelectWithStaticOption = ({
  name,
  control,
  rules,
  errors,
  ...props
}) => {
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <SelectWithStaticOption
          id={name}
          {...field}
          {...props}
          isRequired={
            typeof get(rules, "required", false) === "boolean"
              ? get(rules, "required", false)
              : get(rules, "required.value", false)
          }
          hookformchangehandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledSelectWithStaticOption);
