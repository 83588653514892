import { useEffect, memo } from "react";
import { MenuItem, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import get from "lodash.get";

import { Label } from "..";
import Error from "../Error/Error";
import { StyledSelect } from "./SelectWithStaticOption.style";
import AdditionOption from "./AdditionOption";

const SelectWithStaticOption = ({
  label,
  error,
  onChange,
  options = [],
  itemLabelKey,
  inputType = "string",
  additionOption,
  isRequired,
  ...props
}) => {
  const { t } = useTranslation();

  const renderLabel = (label) => (
    <Label disabled={props?.disabled} isRequired={isRequired}>
      {label}
    </Label>
  );

  const changeHandler = (event, option) => {
    onChange(option);
  };

  const getOptionLabel = (option) => {
    return typeof props.getOptionLabel === "function"
      ? props.getOptionLabel(option)
      : option.label || t(option.translationKey);
  };

  const getItemLabel = (option) => {
    return itemLabelKey
      ? get(option, itemLabelKey, "")
      : t(option.translationKey);
  };

  const _filterOptions = createFilterOptions();
  const filterOptions = (filterOptions, state) => {
    const results = _filterOptions(filterOptions, state);
    if (!results.includes(additionOption)) {
      results.push(additionOption);
    }

    return results;
  };

  useEffect(() => {
    if (props.hookformchangehandler && props.defaultValue) {
      props.hookformchangehandler(props.defaultValue);
    }
  }, [props.defaultValue]);

  const renderSelect = () => (
    <StyledSelect
      className={error && "error"}
      renderInput={(params) => <TextField {...params} type={inputType} />}
      options={[...(options || []), additionOption]}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) =>
        option?.type === "staticOption" ? (
          <AdditionOption option={option} />
        ) : (
          <MenuItem {...props}>{getItemLabel(option)}</MenuItem>
        )
      }
      blurOnSelect
      loadingText={t("common.loading")}
      onChange={changeHandler}
      {...props}
    />
  );

  const renderError = (error) => (
    <Error>{error.message || t("error_messages.invalid_value")}</Error>
  );

  return (
    <div>
      {label && renderLabel(label)}
      {renderSelect()}
      {error && renderError(error)}
    </div>
  );
};
export default memo(SelectWithStaticOption);
