import { memo } from "react";
import Loader from "../Loader/Loader";

import { StyledWindowLoader } from "./WindowLoader.style";

const WindowLoader = () => {
  return (
    <StyledWindowLoader>
      <Loader />
    </StyledWindowLoader>
  );
};

export default memo(WindowLoader);
