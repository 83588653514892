const getThisWeek = () => {
  let today = new Date();
  let thisWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay()
  );
  return thisWeek;
};

const getLastWeekStart = () => {
  let today = new Date();
  let lastWeekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() - 7
  );
  return lastWeekStart;
};

const getThisWeekEnd = () => {
  let today = new Date();
  let thisWeekEnd = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() - 1
  );
  return thisWeekEnd;
};

const getLastMonthStart = () => {
  let today = new Date();
  let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1);
  return lastMonthStart;
};
const getLastMonthEnd = () => {
  let today = new Date();
  let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
  return lastMonthEnd;
};
const getThisMonth = () => {
  let today = new Date();
  let lastMonthEnd = new Date(today.getFullYear(), today.getMonth());
  return lastMonthEnd;
};
const getLastOptionalDays = (number) => {
  const today = new Date();
  const lastOptionalDay = new Date(today.setDate(today.getDate() - number + 1));
  return lastOptionalDay;
};

export const thisWeek = getThisWeek();
export const lastWeekStart = getLastWeekStart();
export const lastWeekEnd = getThisWeekEnd();
export const today = new Date();
export const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
export const lastMonthStart = getLastMonthStart();
export const lastMonthEnd = getLastMonthEnd();
export const thisMonth = getThisMonth();
export const lastSevenDay = getLastOptionalDays(7);
export const lastThirtyDay = getLastOptionalDays(30);
