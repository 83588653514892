import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";

import { Select } from "..";

const ControlledSelect = ({ name, control, rules, errors, ...props }) => {
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Select
          id={name}
          {...field}
          {...props}
          hookformchangehandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledSelect);
