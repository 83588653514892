import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";

import Input from "../Input/Input";

const ControlledInput = ({ control, name, rules, errors, ...props }) => {
  const inputChangeHandler = (e, formChangeHandler) => {
    formChangeHandler(e);
    const { onChange } = props;
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          id={name}
          {...props}
          {...field}
          onFocus={(e) => {
            if (+(field.value || props.value || props?.defaultValue) === 0) {
              e.target.select();
            }
            props?.onFocus && props?.onFocus();
          }}
          value={field.value || props.value}
          onChange={(e) => inputChangeHandler(e, field.onChange)}
          hookformchangehandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledInput);
