import { PaginationTableProvider } from "../context";
import PaginationTable from "./PaginationTable";

export default function Index(props) {
  return (
    <PaginationTableProvider>
      <PaginationTable {...props} />
    </PaginationTableProvider>
  );
}
