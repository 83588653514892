import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";

import { StyledTableContainer } from "./Table.style";
import TableRow from "./components/TableRow";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";
import { NavigateNext } from "@mui/icons-material";

const Table = ({
  columns = [],
  data = [],
  renderItemProps,
  onRowClick = () => {},
  emptydatamessage,
  detailPanel,
  setData,
  loading = false,
  tableBodyRowCount,
  tableStyle = {},
}) => {
  const { t } = useTranslation();

  const renderHeader = useMemo(
    () => (
      <TableHead>
        <MuiTableRow>
          {detailPanel && <TableCell />}
          {columns.map((column, index) => (
            <TableCell sx={column?.style} key={index}>
              {column.translationKey ? t(column.translationKey) : column.header}
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
    ),
    [columns]
  );

  const renderBody = () => {
    if (data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              {emptydatamessage
                ? emptydatamessage
                : t("table.emptyDataSourceMessage")}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {data.map((item, index) => (
          <>
            <TableRow onClick={() => onRowClick(item)} key={item._id}>
              {detailPanel && (
                <TableCell>
                  <IconButton
                    onClick={() =>
                      setData((prev) =>
                        prev?.map((prevItem, prevIndex) =>
                          prevIndex === index
                            ? {
                                ...prevItem,
                                isShowDetailPanel: !prevItem.isShowDetailPanel,
                              }
                            : prevItem
                        )
                      )
                    }
                  >
                    <NavigateNext
                      sx={{
                        transform: `rotate(${
                          item.isShowDetailPanel ? "90deg" : "0deg"
                        })`,
                        transition: ".3s",
                      }}
                    />
                  </IconButton>
                </TableCell>
              )}
              {columns.map((column, cellIndex) => (
                <TableCell sx={column?.style} key={cellIndex * 10}>
                  {renderRowItem(column, item, cellIndex)}
                </TableCell>
              ))}
            </TableRow>
            {detailPanel && (
              <TableRow
                sx={{
                  display: !item.isShowDetailPanel && "none",
                  transition: ".3s",
                }}
                key={`detailPanelKey${index}`}
              >
                <TableCell colSpan={columns.length + 2}>
                  {detailPanel(item, index)}
                </TableCell>
              </TableRow>
            )}
          </>
        ))}
      </TableBody>
    );
  };

  const renderRowItem = (column, dataItem, index) =>
    column.renderComponent
      ? column.renderComponent(dataItem, renderItemProps, index, t)
      : dataItem[column.dataKey];

  return (
    <TableContainer
      sx={{ overflowX: "scroll!important" }}
      component={StyledTableContainer}
    >
      {loading ? (
        <TableSkeleton
          tableBodyRowCount={tableBodyRowCount}
          columns={columns}
        />
      ) : (
        <MuiTable>
          {renderHeader}
          {renderBody()}
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default memo(Table);
