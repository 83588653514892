import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

import RoleManager from "services/role_management";

import CustomFormSelect from "../CustomFormSelect";

const OrganizationSelect = (props) => {
  const {
    access_name = "all",
    firstSelected = false,
    label = "COMMON.ORGANIZATION",
    disabled_id,
    backendError,
  } = props;
  const [, accessedOrganizations] = RoleManager();

  const { t } = useTranslation();
  return (
    <CustomFormSelect
      {...props}
      defaultValue={
        firstSelected
          ? accessedOrganizations(access_name)[0]?._id
          : props.defaultValue
      }
      label={t(label)}
      backendError={backendError}
    >
      {props.optionAll && <MenuItem value={undefined}>Barchasi</MenuItem>}
      {accessedOrganizations(access_name)?.map((org) => (
        <MenuItem
          disabled={disabled_id === org._id}
          key={org._id}
          value={org._id}
        >
          {org.name}
        </MenuItem>
      ))}
    </CustomFormSelect>
  );
};

export default OrganizationSelect;
