import { Info } from "@mui/icons-material";

import { messages } from "services/error_message_handler/data";

import {
  CustomLabel,
  CustomStyledSelect,
  ErrorLabelStyle,
  StyledFormControl,
} from "./select.style";

const CustomFormSelect = ({
  label,
  name,
  onBlur,
  autoFocus = false,
  width,
  children,
  onChange,
  params,
  error,
  defaultValue,
  disabled = false,
  backendError,
  errorLabel = "Majburiy qator !",
}) => {
  const language = localStorage.getItem("i18nextLng");
  return (
    <StyledFormControl fullWidth>
      {label && <CustomLabel>{label}</CustomLabel>}
      <CustomStyledSelect
        autoFocus={autoFocus}
        style={{ width: width }}
        onChange={onChange}
        defaultValue={defaultValue}
        className={`${(error || !!backendError) && "error-style"}`}
        error={error}
        name={name}
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onBlur={onBlur}
        {...params}
      >
        {children}
      </CustomStyledSelect>
      {(error || !!backendError) && (
        <ErrorLabelStyle>
          <Info fontSize={"small"} /> &nbsp;&nbsp;
          {backendError?.code
            ? backendError.code % 1000 === 0
              ? messages.func(backendError.code, backendError?.data[0])[
                  language ? language : "uz"
                ].not_found_field
              : backendError.message
            : errorLabel}
        </ErrorLabelStyle>
      )}
    </StyledFormControl>
  );
};

export default CustomFormSelect;
