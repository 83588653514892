import { memo } from "react";

import { StyledCard } from "./Card.style";

const Card = ({ style, children, ...props }) => (
  <StyledCard
    sx={{ marginBottom: 2, borderRadius: "12px", ...style }}
    {...props}
  >
    {children}
  </StyledCard>
);

export default memo(Card);
