import { CircularProgress } from "@mui/material";
import { memo } from "react";

import { StyledScreenWrapper } from "./Loader.style";

const Loader = ({ ...props }) => {
  return (
    <StyledScreenWrapper>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        thickness={4}
        value={80}
        size={40}
        sx={{ animationDuration: "550ms" }}
        {...props}
      />
    </StyledScreenWrapper>
  );
};

export default memo(Loader);
