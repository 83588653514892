import { useRequest } from "hooks";
import { useCallback, useState } from "react";

export const useListTable = () => {
  const [list, setList] = useState([]);
  const [client, data, status] = useRequest();

  const deleteRowItem = useCallback(
    (item) => {
      const filteredList = list.filter((listItem) => listItem._id !== item._id);
      setList(filteredList);
    },
    [list, setList]
  );

  const addRowItem = useCallback(
    (item) => {
      if (item) {
        const itemFromList = list.find((listItem) => listItem._id === item._id);
        !itemFromList && setList([...list, item]);
      }
    },
    [list, setList]
  );

  const fetchProductsList = (url, payload) => {
    client.post(url, {
      limit: 50,
      page: 1,
      ...payload,
    });
  };

  const searchProductList = (event) => {
    fetchProductsList({ search: event?.target?.value });
  };

  const tableCellChangeHandler = useCallback(
    (dataId, name, value) => {
      const editedList = list.map((item) => {
        if (item._id === dataId) {
          item[name] = value;
        }
        return item;
      });
      setList(editedList);
    },
    [list, setList]
  );

  return {
    state: {
      list,
      data,
      status,
    },
    actions: {
      setList,
      deleteRowItem,
      addRowItem,
      fetchProductsList,
      searchProductList,
      tableCellChangeHandler,
    },
  };
};
