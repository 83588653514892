import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";

import InputMask from "../InputMask/InputMask";

const ControlledInputMask = ({
  name,
  control,
  transform = {
    input: (value) => value,
    output: (e) => {
      const output = e.target.value?.replaceAll(" ", "")?.replaceAll("-", "");
      return output;
    },
  },
  rules,
  onChange,
  errors,
  ...props
}) => {
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      setValue={(v) => v?.replaceAll(" ", "")?.replaceAll("-", "")}
      render={({ field }) => (
        <InputMask
          id={name}
          {...field}
          {...props}
          onChange={(e) => {
            field.onChange(transform.output(e));
            onChange && onChange(transform.output(e));
          }}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledInputMask);
