import { styled } from "@mui/material";
import { FormControl, Select } from "@mui/material";

export const StyledFormControl = styled(FormControl)({
  "& .error-style fieldset": {
    borderColor: "#fc0707!important",
  },
  "& .error-style:nthChild(1) fieldset": {
    borderColor: "#fc0707!important",
  },
  "& .error-style svg": {
    color: "#fc0707!important",
  },
});
export const CustomStyledSelect = styled(Select)({
  borderRadius: "12px",
  color: "#313649",
  "& fieldset": {
    borderColor: "#B7BDC6!important",
    borderWidth: "1px",
    borderRadius: "12px!important",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2!important",
  },
  "& div:after": {
    display: "none",
  },
});
export const CustomLabel = styled("span")({
  color: "#313649",
  fontSize: 14,
  marginBottom: ".5rem",
});

export const ErrorLabelStyle = styled("div")({
  backgroundColor: "#EBEEF2",
  borderRadius: "8px",
  color: "#fc0707",
  padding: "8px 12px",
  fontSize: "12px",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
});

export const PhoneNumberStyle = styled(FormControl)({
  // padding: "8px",
  "& .error-style fieldset": {
    borderColor: "#fc0707!important",
    boxShadow: "none",
  },
  "& .error-style:nthChild(1) fieldset": {
    borderColor: "#fc0707!important",
    boxShadow: "none",
  },
  "& .error-style": {
    borderColor: "#fc0707!important",
    "&:focus": {
      outline: "none",
      boxShadow: "none !important",
    },
  },
  "& .error-style svg": {
    color: "#fc0707!important",
  },
  "& .inputMask": {
    padding: "11.5px",
    borderRadius: "12px",
    width: "100%",
    border: "1px solid #B7BDC6",
    "&:focus": {
      outline: "none",
      border: "1px solid #1976d2",
      boxShadow: "inset 0 0 0 1px #1976d2",
    },
  },
});
