import { Divider, styled, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: "5px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledMenuTypography = styled(Typography)({
  paddingTop: 0,
  paddingBottom: 0,
  display: "flex",
  alignItems: "center",
  height: "30px",
  width: "60px",
});

export const StyledDivider = styled(Divider)({
  margin: "0 !important",
});

export const StyledFlag = styled("img")({
  height: "30px",
  width: "30px",
  borderRadius: "16px",
  marginRight: "10px",
});
