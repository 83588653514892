import { useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import {
  StyledCardSizeInner,
  StyledCardSizeWrapper,
  StyledCreateCardDiv,
} from "./Layout.style";

function getWindow() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindow());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindow());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Layout = ({ children, maxWidth = false }) => {
  const { width } = useWindowDimensions();
  const checkToggle = useSelector((state) => state.external.check);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { isExpired } = useJwt(token);

  useEffect(() => {
    if (!token || isExpired) {
      window.location.href = "/";
      localStorage.clear();
    }
  }, [location, isExpired, token]);
  useEffect(() => {
    let styleProps = document.querySelector(".card-size-wrapper");
    if (width <= "576") {
      if (!checkToggle) {
        styleProps.style.paddingLeft = "80px";
      }
    }
  }, [checkToggle]);
  return (
    <StyledCardSizeWrapper>
      <StyledCardSizeInner>
        <StyledCreateCardDiv
          style={maxWidth ? { maxWidth: "100%" } : undefined}
        >
          {children}
        </StyledCreateCardDiv>
      </StyledCardSizeInner>
    </StyledCardSizeWrapper>
  );
};

export default memo(Layout);
