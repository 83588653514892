function numberWithSpaces(x, def = "0") {
  if (typeof x == "number") {
    x = +x.toFixed(10) + 0;
  }
  //  else {
  //   x = +x?.toFixed(2);
  // }
  let str = String(x)?.replace(/[^\d.-]/g, "");
  if (!str) {
    return def;
  }
  let parts = str.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (parts[1] && parts[1].length > 3 && parts[1].slice(0, 3) === "000") {
    if (parts[0] === "0") {
      for (let i = 0; i < parts[1].length; ++i) {
        if (parts[1][i] !== "0") {
          parts[1] = parts[1].slice(0, i + 1);
          return parts.join(".");
        }
      }
    }
    return parts[0];
  } else if (parts[1]) {
    parts[1] = parts[1].slice(0, 3);
    return parts.join(".");
  }
  return parts[0];
}

export default numberWithSpaces;
