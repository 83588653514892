import { useContext, useMemo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material";

import "./sass/app.css";
import {
  get_All_Finance_Currency_Action,
  get_hr_access_action,
  get_Organization_All_Data,
  get_Payment_All_Action,
  get_page_limit,
  get_sale_settings,
  get_currency_settings,
} from "./redux/actions";
import { getToken } from "./Token";
import { onMessageListener } from "./firebaseInit";
import { WindowLoader, Notifications, Loader } from "components/common";
import NotificationServices from "./services/notificationServices/Container";
import Comps from "./pages";
import { GlobalContext } from "./context";
import { get_Sale_Contract_Template_Is_Main } from "redux/actions/sale/contract/template/SaleContractTemplate";
import { getSaleContractTemplateShippingLetterIsMain } from "redux/actions/sale/contract/template/SaleContractTemplateShippingLetter";

function App() {
  const [, setShow] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [notificationAccess] = NotificationServices();

  const {
    state: { muiTheme },
  } = useContext(GlobalContext);

  useMemo(() => {
    async function getAllData() {
      setLoader(true);
      await dispatch(get_Organization_All_Data({}));
      await dispatch(get_All_Finance_Currency_Action({}));
      await dispatch(get_Payment_All_Action());
      await dispatch(get_hr_access_action({}));
      setLoader(false);
      await dispatch(
        get_Sale_Contract_Template_Is_Main({
          type: "contract",
        })
      );
      await dispatch(
        getSaleContractTemplateShippingLetterIsMain({
          type: "shipping_letter",
        })
      );
    }
    if (getToken()) getAllData();
  }, [dispatch]);
  useMemo(() => {
    async function getAllData() {
      await dispatch(get_sale_settings({}));
      await dispatch(get_currency_settings({}));
      await dispatch(get_page_limit());
    }
    if (getToken()) getAllData();
  }, [getToken()]);

  // Sentry.init({
  //     dsn: "https://2776fa00b13643a88f72a5c95ce9aa1f@o1063488.ingest.sentry.io/6053892",
  //     integrations: [new Integrations.BrowserTracing()],
  //
  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for performance monitoring.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0,
  // });

  onMessageListener()
    .then((payload) => {
      setShow(true);
      payload.data.data = JSON.parse(payload?.data?.data);
      notificationAccess(payload.data.data);
    })
    .catch((err) => ({}));

  if (getToken() && loader) return <Loader />;
  return (
    <Router>
      <ThemeProvider theme={muiTheme}>
        <Comps />
        <div style={{ display: "none" }} id={"generalLoader"}>
          <WindowLoader w100 />
        </div>
        {/* <Toaster
        position="top-right"
        reverseOrder={false}
      /> */}
        <Notifications />
      </ThemeProvider>
    </Router>
  );
}

export default App;
