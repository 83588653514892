import { AppBar, Box, Typography } from "@mui/material";
import { styled } from "@mui/material";

export const StyledIconButtonBlock = styled("div")`
  & .sm {
    display: flex !important;
  }
  & .md {
    display: none !important;
  }
  @media (min-width: 769px) {
    & .sm {
      display: none !important;
    }
    & .md {
      display: flex !important;
    }
  }
`;

export const MenuTypography = styled(Typography)({
  fontWeight: 600,
  color: "#313649",
});

export const BoxWrapper = styled(Box)({
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  cursor: "pointer",
  height: "43px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#313649",
  padding: "0 8px",
});

export const VideoPlayerWrapper = styled(Box)({
  padding: "20px",
  "& iframe": {
    borderRadius: "12px",
  },
});

export const StyledAppBar = styled(AppBar)({
  zIndex: 10,
  position: "relative",
  display: "flex",
  boxShadow: "none!important",
});

export const MiniPlayerWrapper = styled(Box)({
  width: "400px",
  boxShadow: "0px 0px 20px -6px rgba(0,0,0,0.9)",
  background: "#fff",
  borderRadius: "12px 12px 0 0",
  color: "#000",
  "& iframe": {
    width: "100%",
    height: "225px",
    borderRadius: "12px 12px 0 0",
  },
});

export const MultiPlayerBody = styled(Box)({
  justifyContent: "space-between",
  alignItems: "center",
  userSelect: "none",
  display: "flex",
  padding: "5px",
});

export const IconsWrapper = styled(Box)({});
