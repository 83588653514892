import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { ButtonWithIcon } from "components/common";

import { StaticOptionWrapper } from "./SelectWithStaticOption.style";

const AdditionOption = ({ option }) => {
  const { t } = useTranslation();
  return (
    <StaticOptionWrapper>
      <ButtonWithIcon
        icon={<NavigateNextIcon color="primary" fontSize="medium" />}
        iconPosition="endIcon"
        variant="text"
        color="primary"
        size="small"
        sx={{
          "& .MuiSvgIcon-root": {
            transition: "0.1s",
            marginLeft: "0px",
          },
          "&:hover .MuiSvgIcon-root": {
            marginLeft: "5px",
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={option.goToAdd}
      >
        {t("common.button.add")}
      </ButtonWithIcon>
    </StaticOptionWrapper>
  );
};

export default AdditionOption;
