import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";

import {
  StyledTableCellFooter,
  StyledTableCellHeader,
  StyledTableContainer,
} from "./MultiTable.style";
import TableRow from "./components/MultiTable";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";

const MultiTable = ({
  columns = [],
  data = [],
  renderItemProps,
  onRowClick = () => {},
  emptyDataMessage,
  loading = false,
  tableBodyRowCount,
  headers = 1,
  footers = 1,
  total = {
    translationKey: "HR.SALARY.TOTAL",
    colSpan: 2,
  },
  indexing = true,
  indexStyle = { styleHeader: {}, styleBody: {}, styleFooter: {} },
  tableStyle = {},
}) => {
  const { t } = useTranslation();

  const renderMultiHeader = useMemo(
    () => (
      <TableHead>
        {[...new Array(headers)].map((head, indexHeader) => (
          <MuiTableRow>
            {indexing && headers === indexHeader + 1 && (
              <StyledTableCellHeader
                style={{
                  ...indexStyle.styleHeader,
                }}
              >
                №
              </StyledTableCellHeader>
            )}
            {columns.map(
              (column) =>
                column?.headers?.[indexHeader] && (
                  <StyledTableCellHeader
                    colSpan={column?.headers?.[indexHeader]?.colSpan || 1}
                    sx={{
                      ...(headers === indexHeader + 1 && column.style),
                      ...(column?.headers?.[indexHeader] &&
                        column?.headers[indexHeader]?.style),
                    }}
                  >
                    {t(column?.headers?.[indexHeader]?.translationKey)}
                  </StyledTableCellHeader>
                )
            )}
          </MuiTableRow>
        ))}
      </TableHead>
    ),
    [columns]
  );

  const renderRowFooter = (column) =>
    column.renderComponent && column.renderComponent(renderItemProps, t);

  const renderMultiFooter = useMemo(
    () => (
      <TableFooter sx={{ fontWeight: "500" }}>
        {[...new Array(footers)].map((head, indexFooter) => (
          <MuiTableRow>
            {indexing && footers === indexFooter + 1 && (
              <StyledTableCellFooter
                colSpan={total?.colSpan}
                style={{
                  ...total?.style,
                  ...indexStyle.styleFooter,
                }}
              >
                {t(total?.translationKey)}
              </StyledTableCellFooter>
            )}
            {columns?.map(
              (column) =>
                column?.footers?.[indexFooter] && (
                  <StyledTableCellFooter
                    colSpan={column?.footers?.[indexFooter]?.colSpan || 1}
                    sx={{
                      fontWeight: "500",
                      color: "#006aff",
                      boxShadow: "inset 0px 1px 0 rgba(224, 224, 224, 1)",
                      ...(indexFooter === 0 && column?.style),
                      ...(column?.footers?.[indexFooter] &&
                        column?.footers[indexFooter]?.style),
                    }}
                  >
                    {renderRowFooter(column?.footers?.[indexFooter])}
                  </StyledTableCellFooter>
                )
            )}
          </MuiTableRow>
        ))}
      </TableFooter>
    ),
    [columns, renderItemProps]
  );

  const renderRowItem = (column, dataItem) =>
    column.renderComponent
      ? column.renderComponent(dataItem, renderItemProps, t)
      : dataItem[column.dataKey];

  const renderBody = () => {
    if (data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              {emptyDataMessage
                ? emptyDataMessage
                : t("table.emptyDataSourceMessage")}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          {data.map((item, index) => (
            <TableRow onClick={() => onRowClick(item)} key={item._id}>
              {indexing && (
                <TableCell
                  style={{ whiteSpace: "nowrap", ...indexStyle.styleBody }}
                >
                  {index + 1}
                </TableCell>
              )}
              {columns.map((column, cellIndex) => (
                <TableCell sx={column?.style} key={cellIndex * 10}>
                  {renderRowItem(column, item, cellIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <TableContainer
      sx={{
        overflowX: "auto !important",
        overflowY: "auto",
        minHeight: "500px",
        height: "calc(100vh - 280px)",
        ...tableStyle,
      }}
      component={StyledTableContainer}
    >
      {loading ? (
        <TableSkeleton
          tableBodyRowCount={tableBodyRowCount}
          columns={columns}
        />
      ) : (
        <MuiTable>
          {renderMultiHeader}
          {renderBody()}
          {data.length ? renderMultiFooter : ""}
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default memo(MultiTable);
