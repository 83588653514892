import toast from "react-hot-toast";
import { postAxios } from "../../../api";

export const get_currency_settings = (state) => async (dispatch) => {
  try {
    await postAxios(`/back-api/admin/settings/currency-settings/get`, state)
      .then((response) => {
        dispatch({
          type: "GET_CURRENCY_SETTINGS_0",
          payload: response,
        });
      })
      .catch((error) => {
        if (error.response.data.code === 10000) {
          dispatch({
            type: "GET_CURRENCY_SETTINGS_10000",
            payload: "error",
          });
        } else {
          toast.error("Internet connection error");
        }
      });
  } catch (error) {
    return toast.error("Internet connection error");
  }
};
