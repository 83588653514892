import { service_constants } from "constants";
import numberWithSpaces from "services/number_format/number_format";

const detectCurrencySide = (currency, value) => {
  if (currency?.side === service_constants.side.start) {
    return `${currency?.symbol || currency.name} ${numberWithSpaces(value)}`;
  }
  if (currency?.side === service_constants.side.end) {
    return `${numberWithSpaces(value)} ${currency?.symbol || currency.name}`;
  }
  return value;
};

export default detectCurrencySide;
