import { StyledDrawer } from "./Drawer.style";

import ModalHeader from "./ModalHeader";

export default function CustomDrawer(props) {
  const { setOpen, isOpen, theme, headerMenuButtonOptions } = props;

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={() => setOpen(!isOpen)}>
      {theme && (
        <ModalHeader
          headerMenuButtonOptions={headerMenuButtonOptions}
          theme={theme}
          backFunc={setOpen}
        />
      )}
      {props.children}
    </StyledDrawer>
  );
}
