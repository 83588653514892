export const ACCESS_NAMES = {
  all: "all",
  isBoss: "is_boss",

  // statistics
  statistics: "statistics",
  statisticsSale: "statistics_sale",
  statisticsCashbox: "statistics_cashbox",
  statisticsProduct: "statistics_product",
  statisticsCategory: "statistics_category",
  statisticsEmployee: "statistics_employee",
  statisticsCustomer: "statistics_customer",
  statisticsPaymentMethod: "statistics_payment_method",
  statisticsReceipt: "statistics_receipt",
  statisticsWarehouse: "statistics_warehouse",
  statisticsSupplier: "statistics_customer", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi

  // Poss
  pos: "pos",
  receiptRefund: "receipt_refund",
  // Sale
  sales: "sales",
  saleContract: "sale_contract",
  saleContractCreate: "sale_contract_create",
  saleContractUpdate: "sale_contract_update",
  saleContractDelete: "sale_contract_delete",
  // Discount
  discount: "discount",
  discountCreate: "discount_create",
  discountUpdate: "discount_update",
  discountDelete: "discount_delete",

  // Customer
  customers: "customers",
  // Customer customers
  customer: "customer",
  customerCreate: "customer_create",
  customerUpdate: "customer_update",
  customerDelete: "customer_delete",
  customerShowBalance: "customers", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi
  callRecord: "call_record",
  callRecordUpdate: "call_record_update",
  callRecordDelete: "call_record_delete",
  // Customer customers
  customersBalance: "customers", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi
  // customer order
  saleOrder: "sale_order",
  saleOrderCreate: "sale_order_create",
  saleOrderUpdate: "sale_order_update",
  saleOrderDelete: "sale_order_delete",

  // product
  products: "products",
  product: "product",
  productCreate: "product_create",
  productUpdate: "product_update",
  productDelete: "product_delete",
  productHistory: "product_history",
  // product category
  productCategory: "product_category",
  productCategoryCreate: "product_category_create",
  productCategoryUpdate: "product_category_update",
  productCategoryDelete: "product_category_delete",
  // Etiketka
  label: "etiket",
  // product income
  productIncome: "product_income",
  productIncomeCreate: "product_income_create",
  productIncomeDelete: "product_income_delete",
  // product transfer
  productTransfer: "product_transfer",
  productTransferCreate: "product_transfer_create",
  productTransferUpdate: "product_transfer_update",
  productTransferDelete: "product_transfer_delete",
  // product return
  productReturn: "product_return",
  productReturnCreate: "product_return_create",
  productReturnDelete: "product_return_delete",

  // supply productOrder
  productOrder: "product_order",
  productOrderCreate: "product_order_create",
  productOrderUpdate: "product_order_update",
  productOrderDelete: "product_order_delete",
  // supply materialOrder
  materialOrder: "material_order",
  materialOrderCreate: "material_order_create",
  materialOrderUpdate: "material_order_update",
  materialOrderDelete: "material_order_delete",
  // supply supplier
  supply: "supply",
  supplier: "supplier",
  supplierCreate: "supplier_create",
  supplierUpdate: "supplier_update",
  supplierDelete: "supplier_delete",
  supplierShowBalance: "supplier", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi
  // supply suppliers-balance
  suppliersBalance: "supplier", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi

  // warehouse tmb
  warehouse: "warehouse",
  material: "material",
  materialCreate: "material_create",
  materialUpdate: "material_update",
  materialDelete: "material_delete",
  // warehous ytm
  semiProduct: "semi_product",
  semiProductCreate: "semi_product_create",
  semiProductUpdate: "semi_product_update",
  semiProductDelete: "semi_product_delete",
  // warehouse category
  materialCategory: "material_category",
  materialCategoryCreate: "material_category_create",
  materialCategoryUpdate: "material_category_update",
  materialCategoryDelete: "material_category_delete",
  // warehouse active
  active: "active",
  activeCreate: "active_create",
  activeUpdate: "active_update",
  activeDelete: "active_delete",
  // warehouse income
  materialIncome: "material_income",
  materialIncomeCreate: "material_income_create",
  materialIncomeUpdate: "material_income_update",
  materialIncomeDelete: "material_income_delete",
  // warehouse transfer'
  materialTransfer: "material_transfer",
  materialTransferCreate: "material_transfer_create",
  materialTransferUpdate: "material_transfer_update",
  materialTransferDelete: "material_transfer_delete",
  // warehouses write off
  writeOff: "write_off",
  writeOffCreate: "write_off_create",
  writeOffUpdate: "write_off_update",
  // warehouse return
  materialReturn: "material_return",
  materialReturnCreate: "material_return_create",
  materialReturnUpdate: "material_return_update",
  materialReturnDelete: "material_return_delete",

  // finance cash
  finance: "finance",
  cashbox: "cashbox",
  cashboxDelete: "cashbox_delete",
  cashIncome: "cash_income",
  cashExpense: "cash_expense",
  cashTransfer: "cash_transfer",
  cashUpdate: "cash_update",
  cashExchange: "cash_exchange",
  cashboxCreate: "cashbox_create",
  // finance inventorization
  inventorization: "inventorization",
  inventorizationCreate: "inventorization_create",
  inventorizationUpdate: "inventorization_update",
  inventorizationDelete: "inventorization_delete",
  // finance currency
  currency: "currency",
  currencyCreate: "currency_create",
  currencyUpdate: "currency_update",
  currencyDelete: "currency_delete",

  // finance tax
  tax: "tax",
  taxCreate: "tax_create",
  taxUpdate: "tax_update",
  taxDelete: "tax_delete",

  // Hr employee access
  hr: "hr",
  employee: "employee",
  employeeCreate: "employee_create",
  employeeUpdate: "employee_update",
  employeeDelete: "employee_delete",
  // Hr employees-balance access
  employeesBalance: "employee", //Role qoshilishi kerak. Kiyin qiymati o'zgartiriladi
  // Hr section access
  section: "section",
  sectionCreate: "section_create",
  sectionUpdate: "section_update",
  sectionDelete: "section_delete",
  // Hr position access
  position: "position",
  positionCreate: "position_create",
  positionUpdate: "position_update",
  positionDelete: "position_delete",
  // Hr role access
  role: "role",
  roleCreate: "role_create",
  roleUpdate: "role_update",
  roleDelete: "role_delete",
  // Hr attandance access
  attendance: "attendance",
  attendanceCreate: "attendance_create",
  attendanceUpdate: "attendance_update",
  attendanceDelete: "attendance_delete",
  // Hr salary access
  salary: "salary",
  salaryCreate: "salary_create",
  salaryUpdate: "salary_update",
  salaryDelete: "salary_delete",

  // Task
  tasks: "tasks",
  taskCreate: "task_create",
  taskUpdate: "task_update",
  taskDelete: "task_delete",

  // Integration
  // integration: "integration",
  integration: "task",

  // production
  production: "production",
  productionPlan: "production_plan",
  productionPlanCreate: "production_plan_create",
  productionPlanUpdate: "production_plan_update",
  productionPlanDelete: "production_plan_delete",
  productionOrder: "production_order",
  productionOrderCreate: "production_order_create",
  productionOrderUpdate: "production_order_update",
  productionProcessTemplate: "production_process_template",
  productionProcessTemplateCreate: "production_process_template_create",
  productionProcessTemplateUpdate: "production_process_template_update",
  productionProcessTemplateDelete: "production_process_template_delete",
  productionProcess: "production_process",
  productionProcessCreate: "production_process_create",
  productionProcessUpdate: "production_process_update",
  productionProcessDelete: "production_process_delete",
  productionProcessProduced: "production_process_produced",
  productionProcessProducedCreate: "production_process_produced_create",
  productionProcessTransfer: "production_process_transfer",
  productionProcessTransferCreate: "production_process_transfer_create",
  productionProcessTransferUpdate: "production_process_transfer_update",
  // settings
  settings: "settings",
  // Settings payment method
  paymentMethods: "payment_methods",
  paymentMethodCreate: "payment_method_create",
  paymentMethodUpdate: "payment_method_update",
  paymentMethodDelete: "payment_method_delete",
  // Settings detail
  receiptDetailGet: "receipt_detail_get",
  receiptDetailSet: "receipt_detail_set",
  // Settings devices
  devices: "devices",
  deviceCreate: "device_create",
  deviceUpdate: "device_update",
  deviceDelete: "device_delete",
};
