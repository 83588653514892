import { Api } from "../api";

class SupplementApi extends Api {
  createSupplier(data) {
    return this.execute("post", "/warehouse/supplier/create", data);
  }

  editSupplier(data) {
    return this.execute("post", "/warehouse/supplier/update", data);
  }

  deleteSupplier(data) {
    return this.execute(
      "post",
      "back-api/admin/warehouse/supplier/delete",
      data
    );
  }

  getSupplierById(data) {
    return this.execute("post", "/warehouse/supplier/get-by-id", data);
  }

  getSupplierPaging(data) {
    return this.execute("post", "/warehouse/supplier/get-paging", data);
  }

  getSupplierChoose(data) {
    return this.execute("post", "warehouse/supplier/get-for-choose", data);
  }
}

export default SupplementApi;
