import { memo, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";
import RoleManager from "services/role_management";

import { SECTIONS } from "../constants/Aside.constants";
import "../style/style.css";
import { AsideWrapper } from "../style/AppBar.style";
import { AsideContext } from "../context";

const Aside = ({ collapse, toggled, setToggled }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [hasAccess, getAccessedOrganizations] = RoleManager();

  const {
    state: { globalPagingLimit },
    actions: { reClickDisabler },
  } = useContext(AsideContext);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <AsideWrapper>
      <ProSidebar
        className={"style-aside zoom-to-response"}
        collapsed={collapse}
        toggled={toggled}
        setToggled={setToggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        <SidebarContent>
          <Menu style={{ paddingTop: "0" }}>
            {SECTIONS.map(
              (section, sectionIndex) =>
                (section.hasAccess ? hasAccess(section.hasAccess) : true) &&
                section.isShow &&
                (section.subSections ? (
                  <SubMenu
                    className={
                      location.pathname.startsWith(section.activeInUrl) &&
                      "active"
                    }
                    title={t(section.title)}
                    icon={
                      <LazyLoadImage
                        src={section.icon}
                        alt={section.iconAlt}
                        className="navbar-icon"
                      />
                    }
                    key={sectionIndex}
                  >
                    {collapse && (
                      <Box color="grey" textAlign="center" pb={1} mr={2}>
                        {t(section.title)}
                      </Box>
                    )}
                    {section.subSections?.map(
                      (subSection, subSectionIndex) =>
                        (subSection.hasAccess
                          ? hasAccess(subSection.hasAccess)
                          : true) &&
                        subSection.isShow && (
                          <MenuItem
                            key={(subSectionIndex + 8) * Math.random() * 100}
                          >
                            <NavLink
                              onClick={(e) =>
                                reClickDisabler(e, subSection.reClickDisabler)
                              }
                              to={() =>
                                subSection.to({
                                  globalPagingLimit,
                                  allOrganization: getAccessedOrganizations(
                                    ACCESS_NAMES.all
                                  ),
                                  hasAccess,
                                })
                              }
                              className={
                                location.pathname.startsWith(
                                  subSection?.activeInUrl
                                ) && "nav_link_submenu_active"
                              }
                            >
                              {t(subSection.title)}
                            </NavLink>
                          </MenuItem>
                        )
                    )}
                  </SubMenu>
                ) : (
                  <MenuItem
                    active={location.pathname.startsWith(section.activeInUrl)}
                    onClick={(e) => reClickDisabler(e, section.reClickDisabler)}
                    icon={
                      <LazyLoadImage
                        src={section.icon}
                        alt={section.iconAlt}
                        className="navbar-icon"
                      />
                    }
                    key={(sectionIndex + 5) * Math.random()}
                  >
                    <NavLink
                      to={() =>
                        section.to({
                          globalPagingLimit,
                          allOrganization: getAccessedOrganizations(
                            ACCESS_NAMES.all
                          ),
                          hasAccess,
                        })
                      }
                      className={
                        location.pathname.startsWith(section?.activeInUrl) &&
                        "nav_link_submenu_active"
                      }
                    >
                      {t(section.title)}
                    </NavLink>
                  </MenuItem>
                ))
            )}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </AsideWrapper>
  );
};

export default memo(Aside);
