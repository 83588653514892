import { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import get from "lodash.get";

import { BoardContext } from "../../context";
import {
  StyledLaneContainer,
  StyledLeanHeaderContainer,
  StyledLeanBodyContainer,
} from "../../style/Board.style";
import LaneBody from "./LaneBody";
import LaneHeader from "./LaneHeader";

const Lane = ({ lane, index }) => {
  const {
    actions: { onSubmitAddCard, onSubmitEditCard },
    props: { cardWrapperStyle = {}, renderAddCard, boardBodyHeight = "80%" },
  } = useContext(BoardContext);

  return (
    <Draggable draggableId={get(lane, "id")} index={index}>
      {(provided) => (
        <StyledLaneContainer
          {...provided.draggableProps}
          ref={provided.innerRef}
          styleCameOut={cardWrapperStyle}
        >
          <StyledLeanHeaderContainer>
            <LaneHeader provided={provided} lane={lane} />
          </StyledLeanHeaderContainer>
          <StyledLeanBodyContainer sx={{ maxHeight: boardBodyHeight }}>
            <LaneBody laneIndex={index} lane={lane} />
          </StyledLeanBodyContainer>
          {renderAddCard &&
            renderAddCard(lane, onSubmitAddCard, onSubmitEditCard)}
        </StyledLaneContainer>
      )}
    </Draggable>
  );
};

export default Lane;
