import { useEffect, memo } from "react";
import { Controller } from "react-hook-form";

import Checkbox from "../Checkbox/Checkbox";

const ControlledCheckbox = ({ control, name, rules, errors, ...props }) => {
  useEffect(() => {
    if (errors) {
      const errorsArray = Object.keys(errors);
      document.getElementById(errorsArray[0] === name && name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errors]);

  const inputChangeHandler = (e, formChangeHandler) => {
    formChangeHandler(e);
    const { onChange } = props;
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Checkbox
          id={name}
          {...props}
          {...field}
          onChange={(e) => inputChangeHandler(e, field.onChange)}
          hookformchangehandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default memo(ControlledCheckbox);
