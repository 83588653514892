import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, MenuItem } from "@mui/material";

import RoleManager from "services/role_management";

import { StyledMenu } from "./MenuOption.style";
import {
  StyledIconButton,
  ButtonWrapper,
  MenuTypography,
  StyledKeyboardArrowDownIcon,
} from "../Common.style";

const MenuOption = ({
  options = [],
  icon,
  onClickMenuItem,
  innerLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const [hasAccess] = RoleManager();
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchor(null);
  };

  const onClickMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const isOpen = useMemo(() => Boolean(anchor), [anchor]);

  return (
    <>
      <ButtonWrapper isopen={isOpen} onClick={onClickMenu}>
        <StyledIconButton isopen={isOpen}>
          {icon} <StyledKeyboardArrowDownIcon isopen={isOpen} />
        </StyledIconButton>
      </ButtonWrapper>
      <StyledMenu
        id="menu-add-sections"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        {...props}
      >
        <MenuItem disabled>{innerLabel}</MenuItem>
        <Divider />
        {options.map(
          (option) =>
            hasAccess(option.hasAccess) && (
              <MenuItem
                onClick={() => {
                  onClickMenuItem(option);
                  handleClose();
                }}
              >
                <MenuTypography>{t(option.translationKey)}</MenuTypography>
              </MenuItem>
            )
        )}
      </StyledMenu>
    </>
  );
};

export default MenuOption;
