import { DialogContent, Box, Button } from "@mui/material";
import { styled } from "@mui/material";

export const StyledDialogContent = styled(DialogContent)({
  display: "grid",
});

export const BoxContainerStyle = styled(Box)({
  margin: "20px 0",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});

export const BoxButtonWrapperStyle = styled(Box)({
  width: "100%",
  textAlign: "right",
});

export const CancelButton = styled(Button)({
  marginRight: "1rem",
});

export const SaveButton = styled(Button)({});
