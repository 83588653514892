import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { base64 } from "utils";

export default function useQueryParams() {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const getParam = (name) => {
    const value = params.get(name);
    const decoded = base64.decode(value);
    return JSON.parse(decoded);
  };

  const setParam = (name, value) => {
    const stringified = JSON.stringify(value);
    const encoded = base64.encode(stringified);
    value !== undefined ? params.set(name, encoded) : params.delete(name);
    const newParams = params?.toString();
    push(`${pathname}?${newParams}`);
  };

  const getAllParams = () => {
    const res = {};
    params.forEach((value, name) => {
      const decoded = base64.decode(value);
      res[name] = JSON.parse(decoded);
    });
    return res;
  };

  return {
    getParam,
    setParam,
    getAllParams,
  };
}
