import { useSelector } from "react-redux";

export default function RoleManager() {
  const rolesFromServer = useSelector(
    (state) => state.get_hr_access_reducer?.data
  );
  const organizations = useSelector(
    (state) => state.get_Organization_All_Reducer?.data
  );

  // getTokenFireBase(alert)

  function hasAccess(section_name, organization_id) {
    if (rolesFromServer.code === 0) {
      if (rolesFromServer.data.length === 0) {
        return true;
      } else {
        let hasAccess = false;
        for (const role of rolesFromServer.data) {
          if (organization_id && role.organization_id === organization_id) {
            hasAccess = role.role[section_name];
            return hasAccess;
          }
          hasAccess = hasAccess || role.role[section_name];
        }
        if (organization_id) return false;
        return hasAccess;
      }
    } else {
      return false;
    }
  }

  function getAccessedOrganizations(section_name) {
    if (rolesFromServer.code === 0) {
      if (rolesFromServer.data.length === 0 || section_name === "all") {
        return organizations.data;
      } else {
        const organizationsHash = {};
        organizations.data.forEach((organization) => {
          organizationsHash[organization._id] = organization;
        });
        let accessedOnes = [];
        rolesFromServer.data.forEach((role) => {
          if (role.role[section_name])
            accessedOnes.push(organizationsHash[role.organization_id]);
        });
        return accessedOnes;
      }
    } else {
      return [];
    }
  }

  return [hasAccess, getAccessedOrganizations];
}
