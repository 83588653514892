import CustomerListContext from "./CustomerListContext";
import useCustomerList from "./useCustomerList";

const CustomerListProvider = ({ children }) => {
  const store = useCustomerList();
  return (
    <CustomerListContext.Provider value={store}>
      {children}
    </CustomerListContext.Provider>
  );
};

export default CustomerListProvider;
