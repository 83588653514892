import { memo } from "react";

import { AppBarProvider } from "../context";
import AppBar from "./AppBar";
const Index = (props) => {
  return (
    <AppBarProvider>
      <AppBar {...props} />
    </AppBarProvider>
  );
};

export default memo(Index);
