import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  BoxButtonWrapperStyle,
  BoxContainerStyle,
  CancelButton,
  SaveButton,
  StyledBox,
} from "./SubmitButtons.style";

const SubmitButtons = ({
  children,
  childrenposition = "start",
  onClickCancel,
  onClickSubmit,
  loading = false,
  submitButtonText,
  ...props
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <BoxContainerStyle {...props}>
      <StyledBox>{childrenposition === "start" && children}</StyledBox>
      <BoxButtonWrapperStyle>
        <CancelButton
          variant="contained"
          onClick={onClickCancel ? onClickCancel : () => history.goBack()}
          color="white"
          size="medium"
        >
          {t("COMMON.CANCEL")}
        </CancelButton>
        <SaveButton
          loading={loading}
          onClick={onClickSubmit && onClickSubmit}
          variant="contained"
          size="medium"
          childrenposition={childrenposition}
          color="primary"
          type={onClickSubmit ? "button" : "submit"}
        >
          {t(submitButtonText ? submitButtonText : "COMMON.SAVE")}
        </SaveButton>
        {childrenposition === "end" && children}
      </BoxButtonWrapperStyle>
    </BoxContainerStyle>
  );
};

export default SubmitButtons;
