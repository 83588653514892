import { AsideProvider } from "../context";
import Aside from "./Aside";

const Index = (props) => {
  return (
    <AsideProvider>
      <Aside {...props} />
    </AsideProvider>
  );
};

export default Index;
