import { Box } from "@mui/material";
import { styled } from "@mui/material";

export const ContentStyle = styled("div")({
  padding: "20px",
});

export const StyledTextareaBox = styled(Box)({
  marginTop: "16px",
  marginBottom: "16px",
});
