import { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { Toaster } from "react-hot-toast";

import RoleManager from "services/role_management";
import Aside from "components/Navbar/Aside/container";
import AppBar from "components/Navbar/AppBar/container";
import { Loader } from "components/common";

import { routers } from "../routes";
import { getToken } from "../Token";

const MainPage = () => {
  const location = useLocation();
  const [collapse, setCollapse] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [hasAccess] = RoleManager();
  const [existNavbar, SetExistNavbar] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const pathName = window.location.pathname;
    const openPages =
      pathName === "/login" ||
      pathName === "/forgot-password" ||
      pathName === "/sign-up" ||
      pathName === "/" ||
      pathName === "/sms-code";
    if (!getToken() && !openPages) {
      history.push("/login");
    }
    if (getToken()) {
      if (window.location.pathname === "/") {
        history.push("/route");
      }
    }
    const existNavbar =
      pathName === "/login" ||
      pathName === "/forgot-password" ||
      pathName === "/sign-up" ||
      pathName === "/" ||
      pathName === "/sms-code" ||
      pathName === "/products/label/create-labels";
    SetExistNavbar(!existNavbar);
  }, [location]);

  const handleDrawerToggled = async () => {
    collapse && (await setCollapse(false));
    setToggled(!toggled);
  };

  return (
    <>
      {existNavbar && (
        <AppBar
          handleDrawer={() => setCollapse(!collapse)}
          handleDrawerToggled={handleDrawerToggled}
          open={!collapse}
          toggled={toggled}
          LazyLoadImage={LazyLoadImage}
        />
      )}
      <div style={{ display: "flex" }}>
        {existNavbar && (
          <Aside
            collapse={collapse}
            toggled={toggled}
            setToggled={setToggled}
          />
        )}
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            {routers.map(
              (route) =>
                (hasAccess(route.section) || route.section === "default") && (
                  <Route
                    key={route.href}
                    path={route.href}
                    component={route.component}
                  />
                )
            )}
          </Switch>
        </Suspense>
        <Toaster />
      </div>
    </>
  );
};
export default MainPage;
