import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { SendSmsContext } from "../../context";
import { SmsTemplateStyle } from "./SmsTemplate.style";

const SmsTemplate = () => {
  const {
    state: {
      getSmsTemplatesState: { getSmstemplatesData, getSmsTemplatesStatus },
      smsTemplatesList,
      formStore: { setValue },
    },
    actions: { setSmsText, getSmsTemplates },
  } = useContext(SendSmsContext);
  const [page, setPage] = useState(1);
  const [activeSms, setActiveSms] = useState("");

  const SmsCard = (props) => {
    return (
      <div
        className={`sms-card ${props?.activeSms ? "active-sms-card" : ""}`}
        {...props}
      >
        {props?.text}
      </div>
    );
  };
  useEffect(() => {
    getSmsTemplates({
      page: 1,
      limit: 20,
    });
  }, []);
  const fetchMoreData = () => {
    if (smsTemplatesList.length <= getSmstemplatesData?.total) {
      setPage((prev) => prev + 1);
      requestSmsTemplates(page + 1);
    }
  };
  const requestSmsTemplates = (page) => {
    getSmsTemplates({
      page: page,
      limit: 20,
    });
  };
  const clickCard = (text, index) => {
    setValue("content", text?.content);
    setSmsText(text?.content);
    setActiveSms(index);
  };
  return (
    <SmsTemplateStyle>
      <div className="title-sms-template">
        <h3>SMS shablonlar</h3>
      </div>
      <div>
        <InfiniteScroll
          className="overflow-hidden"
          dataLength={smsTemplatesList.length || 0}
          next={fetchMoreData}
          hasMore={true}
          loader={<></>}
          scrollableTarget="11"
        >
          {smsTemplatesList?.length > 0 ? (
            smsTemplatesList?.map((text, index) => {
              return (
                <SmsCard
                  text={text?.content}
                  onClick={() => clickCard(text, index)}
                  activeSms={activeSms === index}
                />
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>No sms templates</div>
          )}
        </InfiniteScroll>
      </div>
    </SmsTemplateStyle>
  );
};

export default SmsTemplate;
