import {toast} from "react-hot-toast"
import {get_access_store} from "../../../store/hr/employee/get_access_store";



const get_hr_access_reducer = (state = get_access_store, action) => {
    switch (action.type) {
        case "GET_HR_ACCESS_LOADING" :
            return {data: [], loading: true};
        case "GET_HR_ACCESS_0" :
            return {data: action.payload, loading: false};
        case "GET_HR_ACCESS_401" :
            return toast.error({data: action.payload, loading: false});
        default :
            return state;
    }
}
export default get_hr_access_reducer