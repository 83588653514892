import error_message_handler from "services/error_message_handler";
import { postAxios } from "../../../api";

export const get_hr_access_action = (state) => async (dispatch) => {
  dispatch({
    type: "GET_HR_ACCESS_LOADING",
  });
  try {
    await postAxios(`/back-api/admin/hr/access/get`, state)
      .then((response) => {
        return dispatch({
          type: "GET_HR_ACCESS_0",
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
        } else {
          error_message_handler(error.response.data.code);
        }
        if (error.response) {
          const code = error.response.data.code;
          switch (code) {
            case 401:
              dispatch({
                type: "GET_HR_ACCESS_401",
                payload: error.response.data.message,
              });
              break;
            default:
              return error;
          }
        }
      });
  } catch (err) {}
};
