import { memo } from "react";

import "../style/checkbox.css";

const Switch = ({ value = false, onChange, id, style, disabled = false }) => {
  return (
    <div
      style={{ ...style, opacity: disabled ? "0.5" : 1 }}
      className={`button-switch ${value ? "button-switch-checked" : ""}`}
    >
      <input
        disabled={disabled}
        value={value}
        type="checkbox"
        id={id}
        className="switch"
        onChange={onChange}
      />
      <label htmlFor="switch-orange" className="lbl-off">
        <img src="/images/customers/true.svg" alt="" />
      </label>
      <label htmlFor="switch-orange" className="lbl-on">
        <img src="/images/customers/false.svg" alt="" />
      </label>
    </div>
  );
};

export default memo(Switch);
