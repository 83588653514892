import { Box } from "@mui/material";
import { styled } from "@mui/material";

import Button from "../Button/Button";

export const BoxContainerStyle = styled(Box)({
  margin: "20px 0",
  width: "100%",
  display: "block",
  justifyContent: "space-between",
  "@media(min-width: 400px)": {
    display: "flex",
  },
});

export const BoxButtonWrapperStyle = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "end",
});

export const CancelButton = styled(Button)({
  marginRight: "1rem",
});

export const SaveButton = styled(Button)(({ childrenposition }) => ({
  marginRight: childrenposition === "end" && "1rem",
}));

export const StyledBox = styled("div")({
  marginBottom: "20px",
  "@media(min-width: 400px)": {
    marginBottom: "0px",
  },
});
