/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { StyledCustomOrganizationSwitch } from "../components/style";
import { OrganizationsSwitchContext } from "../context";
import Switch from "../../Switch/container";
import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";

const OrganizationsSwitch = ({
  formStore,
  name,
  organizationAccessName = ACCESS_NAMES.all,
}) => {
  const { t } = useTranslation();
  const { register, setValue, watch } = formStore;

  const organizationIds = watch(name) ? watch(name) : [];

  const {
    state: { allOrganizations },
    actions: { handleOrganization },
  } = useContext(OrganizationsSwitchContext);

  useEffect(() => {
    if (allOrganizations(organizationAccessName)) {
      register(name, {
        required: true,
        value: allOrganizations(organizationAccessName)?.map(
          (item) => item._id
        ),
      });
    }
  }, [allOrganizations(organizationAccessName)]);

  return (
    <StyledCustomOrganizationSwitch container>
      {!organizationIds?.length && (
        <Grid item xs={12} mb={3}>
          <Typography variant="subtitle1" color="red">
            {t("error_messages.min_one_organization")}
          </Typography>
        </Grid>
      )}
      {allOrganizations(organizationAccessName)?.map((org) => (
        <React.Fragment key={org._id}>
          <Grid item xs={6}>
            <span>{org.name}</span>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="end">
            <Switch
              value={organizationIds?.includes(org._id)}
              id={org._id}
              onChange={(event) =>
                handleOrganization(event, organizationIds, setValue, name)
              }
            />
          </Grid>
        </React.Fragment>
      ))}
    </StyledCustomOrganizationSwitch>
  );
};

export default OrganizationsSwitch;
