import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ButtonWithIcon from "../../Buttons/ButtonWithIcon/ButtonWithIcon";
import Sms from "../assets/icons/Sms";
import Header from "../components/Header/Header";
import { SendSmsContext } from "../context";
import Content from "../components/Content/Content";
import SmsTemplate from "../components/Smstemplate/SmsTemplate";
import { REQUEST_STATUSES } from "hooks";
import toast from "react-hot-toast";
import { SmsScrollContainer, StyledDrawer } from "../style/SendSms.style";

const SendSms = ({ type, checkedTableRows }) => {
  const {
    state: {
      isOpen,
      formStore: { reset },
      status,
    },
    actions: { setIsOpen, setSmsText, setSmsTemplatesList },
  } = useContext(SendSmsContext);

  const { t } = useTranslation();

  const onClose = () => {
    setIsOpen(false);
    reset();
    setSmsTemplatesList([]);
    setSmsText("");
  };
  useEffect(() => {
    reset({ [type]: true, ids: checkedTableRows?.map((item) => item._id) });
  }, [checkedTableRows]);
  useEffect(() => {
    if (status === REQUEST_STATUSES.failed) {
      toast.error("Error");
    } else if (status === REQUEST_STATUSES.success) {
      onClose();
    }
  }, [status]);
  return (
    <div>
      <ButtonWithIcon
        className="d-flex align-items-center justify-content-center"
        onClick={() => setIsOpen(true)}
        type="button"
        icon={<Sms />}
      >
        <span className="ms-2">Sms yuborish</span>
      </ButtonWithIcon>
      <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
        <SmsScrollContainer>
          <Header onClose={onClose} />
          <Content />
          <SmsTemplate />
        </SmsScrollContainer>
      </StyledDrawer>
    </div>
  );
};

export default SendSms;
