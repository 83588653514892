import { Api } from "../api";

class TasksApi extends Api {
  createTask(data) {
    return this.execute("post", "/back-api/admin/tasks/task/create", data);
  }

  updateTask(data) {
    return this.execute("post", "/back-api/admin/tasks/task/update", data);
  }

  getMine(data) {
    return this.execute("post", "/back-api/admin/tasks/task/get-mine", data);
  }

  deleteTask(data) {
    return this.execute("post", "/back-api/admin/tasks/task/delete", data);
  }

  getCheckList(data) {
    return this.execute(
      "post",
      "/back-api/admin/tasks/checklist/get-all",
      data
    );
  }

  updateCheckList(data) {
    return this.execute("post", "/back-api/admin/tasks/checklist/update", data);
  }

  updateSubTask(data) {
    return this.execute("post", "/back-api/admin/tasks/subtask/update", data);
  }

  deleteCheckList(data) {
    return this.execute("post", "/back-api/admin/tasks/checklist/remove", data);
  }

  deleteSubTask(data) {
    return this.execute("post", "/back-api/admin/tasks/subtask/remove", data);
  }

  addCheckList(data) {
    return this.execute("post", "/back-api/admin/tasks/checklist/add", data);
  }

  addSubTask(data) {
    return this.execute("post", "/back-api/admin/tasks/subtask/add", data);
  }
}

export default TasksApi;
