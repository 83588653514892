import { useEffect, memo } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useDebounce } from "hooks";

import { StyledFormField } from "../style/Form.style";

const DebounceInput = ({ label, labelTranslationKey, ...props }) => {
  const [debouncedValue, debounce] = useDebounce();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.onChange && debouncedValue) {
      props.onChange(debouncedValue);
    }
  }, [debouncedValue]);

  const onChange = (value) => debounce(value, 500);

  return (
    <StyledFormField>
      <TextField
        fullWidth
        {...props}
        label={labelTranslationKey ? t(labelTranslationKey) : label}
        onChange={onChange}
      />
    </StyledFormField>
  );
};

export default memo(DebounceInput);
