import { Card } from "@mui/material";
import { styled } from "@mui/material";

export const StyleCardSizeWrapper = styled("div")`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  &.checkTogglePadding {
    padding-left: 80px;
  }
  @media (min-width: 768px) {
    padding: 10px;
  }
`;

export const StyledCard = styled(Card)`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 10px;
  color: #000;
`;
