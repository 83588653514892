import { useSelector } from "react-redux";

export const useAside = () => {
  const currentPathname = document.location.pathname;

  const globalPagingLimit = useSelector(
    (state) => state.get_Page_Limit_Reducer.page_limit?.data?.page_limit
  );

  const reClickDisabler = (e, path) => {
    if (path === currentPathname) e.preventDefault();
  };

  return {
    state: { globalPagingLimit },
    actions: {
      reClickDisabler,
    },
  };
};
