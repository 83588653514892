import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";

import { REQUEST_STATUSES, useRequest } from "hooks";

import {
  MenuTypography,
  ButtonWrapper,
  StyledIconButton,
  StyledKeyboardArrowDownIcon,
} from "../Common.style";
import { StyledDivider, StyledTypography } from "./UserMenu.styled";

const UserMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [logOutClient, , logOutStatus] = useRequest();
  const [anchor, setAnchor] = useState(null);

  const handleClose = () => {
    setAnchor(null);
  };

  const onClickMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const logOut = () => {
    logOutClient.post("/back-api/admin/logout");
  };
  const goProfile = () => {
    history.push("/profile");
    handleClose();
  };

  const isOpen = useMemo(() => Boolean(anchor), [anchor]);

  useEffect(() => {
    if (logOutStatus === REQUEST_STATUSES.success) {
      window.location.href = "/";
      localStorage.clear();
    }
  }, [logOutStatus]);

  return (
    <>
      <ButtonWrapper isopen={isOpen} onClick={onClickMenu}>
        <StyledIconButton isopen={isOpen}>
          <AccountCircle />
          {
            <StyledTypography>
              {JSON.parse(localStorage.getItem("userData"))?.full_name}
            </StyledTypography>
          }
          <StyledKeyboardArrowDownIcon isopen={isOpen} />
        </StyledIconButton>
      </ButtonWrapper>
      <Menu
        id="menu-appBar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={handleClose}
        sx={{ mt: "2px" }}
      >
        <MenuItem onClick={goProfile}>
          <MenuTypography>{t("COMMON.PROFILE")}</MenuTypography>
        </MenuItem>
        <StyledDivider />
        <MenuItem onClick={logOut}>
          <MenuTypography>{t("COMMON.LOG_OUT")}</MenuTypography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
