import { useSelector } from "react-redux";

export default function CurrencyService() {
  const currencies = useSelector(
    (state) => state.get_All_Finance_Currency_Reducer.data.data
  );
  const mainCurrency = currencies?.find((item) => item.is_main);
  let currencyMap = {};
  currencies?.forEach((item) => {
    currencyMap[item._id] = item;
  });

  function findCurrency(id) {
    return currencyMap[id];
  }

  return [mainCurrency, findCurrency];
}
