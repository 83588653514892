import { Info } from "@mui/icons-material";
import { styled } from "@mui/material";

export const StyledError = styled("span")({
  backgroundColor: "#EBEEF2",
  borderRadius: "8px",
  color: "#fc0707",
  padding: "8px 12px",
  fontSize: "13px",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
});

export const StyledInfoIcon = styled(Info)({
  marginRight: "7px",
});
