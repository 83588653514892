import { styled } from "@mui/material";
import { StyledFormField } from "../style/Form.style";

export const StyledSelect = styled(StyledFormField)({
  display: "flex",
  alignItems: "center",
  "& .MuiSelect-select:after": {
    display: "none",
  },
  "& .MuiSelect-select:focus": {
    borderRadius: "12px",
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
    {
      paddingRight: "32px !important",
      color: "#313649",
    },
  paddingLeft: 0,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    borderRadius: "12px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #313649 !important",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #026aff !important",
  },
  "@media (min-width: 900px)": {
    paddingLeft: 15,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      borderRadius: "initial",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
});

export const StyledSearchInputBlock = styled("div")({
  width: "100%",
  padding: "8px",
});
