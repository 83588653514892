import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { FilterFieldTypes } from "components/common";
import RoleManager from "services/role_management";
import { base64 } from "utils";

import {
  CUSTOMER_TYPE_OPTIONS,
  DEBT_TYPE_OPTIONS,
} from "../constants/CustomerList.constants";
import { PaginationTableContext } from "components/common/PaginationTable/context";

const useCustomerList = () => {
  const {
    state: { rows: tableRows },
  } = useContext(PaginationTableContext);

  const history = useHistory();
  const [, accessedOrganizations] = RoleManager();
  const util = (a) => base64.encode(JSON.stringify(a));

  const checkedTableRows = useMemo(
    () => tableRows?.filter((row) => row?.checked) || [],
    [tableRows]
  );

  const onRowClick = (row) => {
    history.push(
      `/customer/customers/profile/${row?._id}?page=${util(0)}&limit=${util(
        10
      )}&date_from=${util(moment(new Date()).startOf("month"))}&date_to=${util(
        new Date()
      )}&customer_id=${util(row?._id)}`
    );
  };

  const filterFields = useMemo(
    () => [
      {
        fromDateName: "date_from",
        toDateName: "date_to",
        startText: "date_picker.from",
        endText: "date_picker.to",
        type: FilterFieldTypes.dateRangePicker,
        labelTranslationKey: "COMMON.INTERVAL",
        grid: { xs: 12, md: "auto" },
      },
      {
        name: "organization_id",
        type: FilterFieldTypes.select,
        labelTranslationKey: "COMMON.ORGANIZATION",
        itemLabelKey: "name",
        itemValueKey: "_id",
        options: accessedOrganizations("customer"),
        grid: { xs: 12, md: 7, lg: 4, xl: 3 },
      },
      {
        name: "type",
        type: FilterFieldTypes.select,
        labelTranslationKey: "CUSTOMER_PROFILE.CUSTOMER_TYPE",
        options: CUSTOMER_TYPE_OPTIONS,
        grid: { xs: 12, md: 8, lg: 4, xl: 3 },
      },
      {
        name: "debt",
        type: FilterFieldTypes.select,
        labelTranslationKey: "COMMON.DEBTOR",
        options: DEBT_TYPE_OPTIONS,
        grid: { xs: 12, md: 7, lg: 4, xl: 3 },
      },
      {
        name: "search",
        type: FilterFieldTypes.debounceInput,
        labelTranslationKey: "COMMON.SEARCH",
        grid: { xs: 12, md: 5, lg: "auto" },
      },
    ],
    []
  );
  return { state: { filterFields, checkedTableRows }, actions: { onRowClick } };
};

export default useCustomerList;
