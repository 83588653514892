import React from "react";

import SendSmsContext from "./SendSmsContext";
import useSendSms from "./useSendSms";

const SendSmsProvider = ({ children }) => {
  const value = useSendSms();
  return (
    <SendSmsContext.Provider value={value}>{children}</SendSmsContext.Provider>
  );
};

export default SendSmsProvider;
