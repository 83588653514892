import { errorCodeName } from "./errorCodeName";
import { fieldName } from "./errorCodeName";

function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const messages = {
  func(code, field) {
    let prevNumber = code - 1;
    return {
      uz: {
        0: "Muvaffaqiyat",
        10001: `${capitalizeFirstLetter(fieldName.uz[field])} haqiqiy ${
          fieldName.uz[field]
        } boʻlishi kerak`,
        51005: "Blok holatini o'zgartirib bo'lmaydi",
        12163: "Internetga ulanish xatosi!",
        11005: "Juda ko'p urinishlar,2 minutdan keyin yana urinib ko'ring",
        11000: "Noma'lum xatolik",
        not_found: `Bunday ${errorCodeName.uz[code]} topilmadi`,
        not_found_field: `Bunday ${errorCodeName.uz[code]} ni ${fieldName.uz[field]}i topilmadi`,
        exist: `${
          code === 10001
            ? "Tog'ri qiymat kiriting"
            : `Bunday ${errorCodeName.uz[prevNumber]} ni ${fieldName.uz[field]} allaqachon mavjud`
        }`,
        unknown: "Nomalum xatolik",
      },
    };
  },
};
