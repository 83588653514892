import { HREF } from "constants/href.constants";
import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";

export const LANGUAGES = [
  { value: "uz", label: "uz" },
  { value: "ru", label: "ru" },
];

export const CREATE_SECTIONS = [
  {
    href: `${HREF.sale}/contract/create`,
    translationKey: "ADD_SECTIONS.CONTRACT",
    hasAccess: ACCESS_NAMES.saleContractCreate,
  },
  {
    href: `${HREF.customer}/customers/create`,
    translationKey: "ADD_SECTIONS.CUSTOMER",
    hasAccess: ACCESS_NAMES.customerCreate,
  },
  {
    href: `${HREF.customer}/orders/create`,
    translationKey: "ADD_SECTIONS.CUSTOMER_ORDER",
    hasAccess: ACCESS_NAMES.saleOrderCreate,
  },
  {
    href: `${HREF.products}/products/create`,
    translationKey: "ADD_SECTIONS.PRODUCT",
    hasAccess: ACCESS_NAMES.productCreate,
  },
  {
    href: `${HREF.products}/categories/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_CATEGORY",
    hasAccess: ACCESS_NAMES.productCategoryCreate,
  },
  {
    href: `${HREF.products}/label/print`,
    translationKey: "ADD_SECTIONS.LABEL",
    hasAccess: ACCESS_NAMES.label,
  },
  {
    href: `${HREF.products}/income/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_INCOME",
    hasAccess: ACCESS_NAMES.productIncomeCreate,
  },
  {
    href: `${HREF.products}/transfer/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_TRANSFER",
    hasAccess: ACCESS_NAMES.productTransferCreate,
  },
  {
    href: `${HREF.products}/return/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_RETURN",
    hasAccess: ACCESS_NAMES.productReturnCreate,
  },
  {
    href: `${HREF.supply}/product-orders/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_ORDER",
    hasAccess: ACCESS_NAMES.productOrderCreate,
  },
  {
    href: `${HREF.supply}/material-orders/create`,
    translationKey: "ADD_SECTIONS.MATERIAL_ORDER",
    hasAccess: ACCESS_NAMES.materialOrderCreate,
  },
  {
    href: `${HREF.supply}/supplier/create`,
    translationKey: "ADD_SECTIONS.SUPPLIER",
    hasAccess: ACCESS_NAMES.supplierCreate,
  },
  {
    href: `${HREF.warehouse}/tmb/create`,
    translationKey: "ADD_SECTIONS.TMB",
    hasAccess: ACCESS_NAMES.materialCreate,
  },
  {
    href: `${HREF.warehouse}/ytm/create`,
    translationKey: "ADD_SECTIONS.YTM",
    hasAccess: ACCESS_NAMES.semiProductCreate,
  },
  {
    href: `${HREF.warehouse}/category/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_CATEGORY",
    hasAccess: ACCESS_NAMES.materialCategoryCreate,
  },
  {
    href: `${HREF.warehouse}/active/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_ACTIVE",
    hasAccess: ACCESS_NAMES.activeCreate,
  },
  {
    href: `${HREF.warehouse}/income/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_INCOME",
    hasAccess: ACCESS_NAMES.materialIncomeCreate,
  },
  {
    href: `${HREF.warehouse}/transfer/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_TRANSFER",
    hasAccess: ACCESS_NAMES.materialTransferCreate,
  },
  {
    href: `${HREF.warehouse}/return/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_RETURN",
    hasAccess: ACCESS_NAMES.materialReturnCreate,
  },
  {
    href: `${HREF.warehouse}/revision/create`,
    translationKey: "ADD_SECTIONS.REVISION",
    hasAccess: ACCESS_NAMES.inventorizationCreate,
  },
  {
    href: `${HREF.finance}/tax/create`,
    translationKey: "ADD_SECTIONS.TAX",
    hasAccess: ACCESS_NAMES.taxCreate,
  },
  {
    href: `${HREF.hr}/employees/create`,
    translationKey: "ADD_SECTIONS.EMPLOYEE",
    hasAccess: ACCESS_NAMES.employeeCreate,
  },
  {
    href: `${HREF.hr}/section/create`,
    translationKey: "ADD_SECTIONS.SECTION",
    hasAccess: ACCESS_NAMES.sectionCreate,
  },
  {
    href: `${HREF.hr}/position/create`,
    translationKey: "ADD_SECTIONS.POSITION",
    hasAccess: ACCESS_NAMES.positionCreate,
  },
  {
    href: `${HREF.hr}/role/create`,
    translationKey: "ADD_SECTIONS.ROLE",
    hasAccess: ACCESS_NAMES.roleCreate,
  },
  {
    href: `${HREF.hr}/attendance/create`,
    translationKey: "ADD_SECTIONS.ATTENDANCE",
    hasAccess: ACCESS_NAMES.attendanceCreate,
  },
  {
    href: `${HREF.task}/board/create`,
    translationKey: "ADD_SECTIONS.TASK",
    hasAccess: ACCESS_NAMES.taskCreate,
  },
  {
    href: `${HREF.production}/planing/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_PLANNING",
    hasAccess: ACCESS_NAMES.productionPlanCreate,
  },
  {
    href: `${HREF.production}/orders/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_ORDER",
    hasAccess: ACCESS_NAMES.productionOrderCreate,
  },
  {
    href: `${HREF.production}/template/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_TEMPLATE",
    hasAccess: ACCESS_NAMES.productionProcessTemplateCreate,
  },
  {
    href: `${HREF.production}/produced-products/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_PRODUCED_PRODUCTS",
    hasAccess: ACCESS_NAMES.productionProcessProducedCreate,
  },
  {
    href: `${HREF.production}/transfers/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_TRANSFER",
    hasAccess: ACCESS_NAMES.productionProcessTransferCreate,
  },
];
