import { useState, useMemo } from "react";

import { useRequest } from "hooks";

import { DEFAULT_ROW_LIMIT } from "../constants/PaginationTable.constants";

export const usePaginationTable = () => {
  const [checkedTableCell, setCheckedTableCell] = useState([]);
  const [rows, setRows] = useState([]);
  const [getBodyClient, getBodyRes, tableBodyStatus, tableBodyError] =
    useRequest();

  const allRowsAreChecked = useMemo(() => {
    if (rows.length === 0) return false;
    const uncheckedRow = rows.find(
      (row) => row.checked === undefined || row.checked === false
    );
    return uncheckedRow ? false : true;
  }, [rows]);

  const getContent = async (
    url,
    filterParams = { page: 1, limit: DEFAULT_ROW_LIMIT },
    reset = false
  ) => {
    try {
      const res = await getBodyClient.post(url, filterParams, false, false);
      if (reset) {
        setRows(res.data);
      } else {
        setRows([...rows, ...res.data]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeCheckBoxInHeader = () => {
    const checkedRows = rows.map((row) => {
      row.checked = !allRowsAreChecked;
      return row;
    });
    setRows(checkedRows);
  };

  const onChangeCheckBoxTableRow = (selectedRow) => {
    const rowsWithCheckedNewItem = rows.map((row) => {
      if (row._id === selectedRow._id) {
        row.checked = !row.checked;
      }
      return row;
    });
    setRows(rowsWithCheckedNewItem);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return {
    state: {
      checkedTableCell,
      allRowsAreChecked,
      rows,
      contentStore: {
        res: getBodyRes,
        status: tableBodyStatus,
        error: tableBodyError,
      },
    },
    actions: {
      getContent,
      setCheckedTableCell,
      onChangeCheckBoxInHeader,
      onChangeCheckBoxTableRow,
      stopPropagation,
      setRows,
    },
  };
};
