import { TableCell } from "@mui/material";
import { styled } from "@mui/material";

export const StyledTableContainer = styled("div")({
  width: "100%",
});

export const StyledTableCellHeader = styled(TableCell)({
  position: "sticky",
  top: 0,
  whiteSpace: "nowrap",
  border: "none",
  boxShadow: "inset 0 -1px 0 rgba(224, 224, 224, 1)",
  background: "#F6F6F6",
  zIndex: 1,
});

export const StyledTableCellFooter = styled(TableCell)({
  position: "sticky",
  bottom: 0,
  whiteSpace: "nowrap",
  fontWeight: "500",
  color: "#006aff",
  boxShadow: "inset 0px 1px 0 rgba(224, 224, 224, 1)",
  borderBottom: 0,
  background: "#FFF",
  zIndex: 1,
});
