import { CircularProgress, Collapse } from "@mui/material";

import { StyledButton, StyledTypography } from "./Button.style";

const Button = ({ children, loading, refProp, ...props }) => {
  return (
    <StyledButton ref={refProp} disabled={loading} size="medium" {...props}>
      <Collapse in={loading} orientation="horizontal">
        <CircularProgress
          variant="indeterminate"
          color="primary"
          disableShrink
          thickness={4}
          value={70}
          size={15}
          sx={{
            marginRight: "5px",
            marginTop: "5px",
            animationDuration: "550ms",
          }}
        />
      </Collapse>
      <StyledTypography>{children}</StyledTypography>
    </StyledButton>
  );
};

export default Button;
