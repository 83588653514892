import { useContext } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import get from "lodash.get";

import { StyledLaneBody } from "../../style/Board.style";
import { BoardContext } from "../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, LinearProgress } from "@mui/material";

const LaneBody = ({ lane, laneIndex }) => {
  const {
    actions: { deleteCard, onSubmitEditCard },
    props: { renderCard, onScrollBottom, loadingOnScrollBottom },
  } = useContext(BoardContext);
  const cards = get(lane, "cards", []);
  const total = get(lane, "total");

  return (
    <Droppable droppableId={get(lane, "id")} type="card">
      {(provided, snapshot) => (
        <StyledLaneBody
          isdraggingover={snapshot.isDraggingOver ? true : ""}
          ref={provided.innerRef}
          {...provided.droppableProps}
          id={`scrollableDiv${laneIndex}`}
        >
          <InfiniteScroll
            key={lane.id}
            id={lane.id}
            dataLength={cards.length}
            next={() => onScrollBottom(lane)}
            hasMore={cards.length ? cards.length !== total : false}
            scrollableTarget={`scrollableDiv${laneIndex}`}
            loader={
              loadingOnScrollBottom ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                ""
              )
            }
          >
            {cards.map((card, index) => (
              <Draggable
                key={get(card, "id")}
                draggableId={get(card, "id")}
                index={index}
              >
                {(cardProvided, carSnapshot) =>
                  renderCard
                    ? renderCard(
                        card,
                        index,
                        {
                          provided: cardProvided,
                          snapshot: carSnapshot,
                        },
                        lane,
                        deleteCard,
                        onSubmitEditCard
                      )
                    : get(card, "title", "")
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </InfiniteScroll>
        </StyledLaneBody>
      )}
    </Droppable>
  );
};

export default LaneBody;
