import CurrencyService from "../currency";

export default function Converter() {
  const [main_currency, findCurrency] = CurrencyService();

  function converter(value, sourceCurrency = 0, destCurrency = 0) {
    if (sourceCurrency.toString() === destCurrency.toString()) {
      return value;
    } else {
      let source = findCurrency(sourceCurrency);
      let dest = source?.values.find((f) => f.to_currency_id === destCurrency);
      // let res =
      //   (((value * Math.pow(10, 12)) / source?.value) * source.values) /
      //   Math.pow(10, 12);
      return Math.round(value * (dest ? dest.value : 1) * 1000) / 1000;
    }
  }

  function convertToMain(value, sourceCurrency) {
    const destCurrency = main_currency._id;
    if (sourceCurrency.toString() === destCurrency.toString()) {
      return value;
    } else {
      let source = findCurrency(sourceCurrency);
      let dest = source?.values.find((f) => f.to_currency_id === destCurrency);
      // let res =
      //   (((value * Math.pow(10, 12)) / source?.value) * source.values) /
      //   Math.pow(10, 12);
      return Math.round(value * (dest ? dest.value : 1) * 1000) / 1000;
    }
  }

  return [converter, convertToMain];
}
