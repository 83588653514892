import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import moment from "moment";
import get from "lodash.get";

import { GLOBAL_DATE_FORMAT } from "constants/dateFormat";
import numberWithSpaces from "services/number_format/number_format";

import { StyledState } from "./DataInfo.style";

const DataInfo = ({ data = {}, columns = [] }) => {
  const { t } = useTranslation();

  const renderData = (
    infoData,
    type,
    dateFormat = GLOBAL_DATE_FORMAT.momentFormatDateAndTime
  ) => {
    switch (type) {
      case "state": {
        return <StyledState>{infoData(data, t)}</StyledState>;
      }
      case "date": {
        return <span>{moment(infoData).format(dateFormat)}</span>;
      }
      case "price": {
        return <span>{numberWithSpaces(infoData)}</span>;
      }
      case "component": {
        return <span>{infoData(data, t)}</span>;
      }
      default: {
        return <span>{infoData}</span>;
      }
    }
  };

  return (
    <div>
      {columns.map(
        (column) =>
          (get(column, "renderComponent") ||
            get(data, get(column, "dataKey"))) && (
            <Box marginBottom="15px">
              {(get(column, "translationKey") || get(column, "text")) && (
                <b>
                  {get(column, "translationKey")
                    ? t(get(column, "translationKey"))
                    : get(column, "text")}
                  :&nbsp;
                </b>
              )}
              {renderData(
                get(column, "renderComponent") ||
                  get(data, get(column, "dataKey")),
                get(column, "type")
                  ? get(column, "type")
                  : column.renderComponent
                  ? "component"
                  : undefined,
                get(column, "dateFormat")
              )}
            </Box>
          )
      )}
    </div>
  );
};

export default DataInfo;
