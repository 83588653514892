import { memo } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { StyledSearchInputBlock, StyledSelect } from "./LabeledSelect.style";

const LabeledSelect = ({
  options = [],
  labelTranslationKey,
  itemLabelKey = "label",
  itemValueKey = "value",
  label,
  getAll = true,
  searchInputChanges,
  ...props
}) => {
  const { t } = useTranslation();

  const renderGetAll = () => <MenuItem value="all">{t("COMMON.ALL")}</MenuItem>;

  const renderLabel = () => (
    <Typography
      sx={{ opacity: props.disabled ? 0.5 : 1 }}
      id="demo-simple-select-label"
    >
      {labelTranslationKey ? t(labelTranslationKey) : label}
    </Typography>
  );

  const renderSearchInput = () => (
    <StyledSearchInputBlock onKeyDown={(e) => e.stopPropagation()}>
      <TextField
        fullWidth={true}
        type="text"
        onClick={(e) => e.stopPropagation()}
        placeholder="Search..."
        onKeyDown={(e) => e.stopPropagation()}
        onChange={searchInputChanges}
      />
    </StyledSearchInputBlock>
  );
  const getItemLabel = (option) =>
    option.translationKey ? t(option.translationKey) : option[itemLabelKey];

  return (
    <StyledSelect>
      <Grid container>
        <Grid item xs={12} md="auto" className="align-items-center d-flex">
          {renderLabel()}
        </Grid>
        <Grid item xs={12} md>
          <FormControl fullWidth>
            <Select {...props}>
              {searchInputChanges && renderSearchInput()}
              {getAll && renderGetAll()}
              {options.map((option) => (
                <MenuItem
                  disabled={option.disabled}
                  key={option[itemValueKey]}
                  value={option[itemValueKey]}
                >
                  {getItemLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </StyledSelect>
  );
};

export default memo(LabeledSelect);
