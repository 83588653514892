import { useContext, useEffect, useMemo, memo } from "react";
import { Grid, IconButton } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

import Table from "../../Table/Table";
import ListTableContext from "../context/ListTableContext";

const ListTable = ({
  payload,
  renderItemProps,
  data = [],
  deleteIcon = true,
  ...props
}) => {
  const {
    state: { list },
    actions: { deleteRowItem, tableCellChangeHandler, setList },
  } = useContext(ListTableContext);
  const columns = useMemo(
    () =>
      deleteIcon
        ? [
            ...props.columns,
            {
              renderComponent: (data) => (
                <IconButton>
                  <DeleteOutline onClick={() => deleteRowItem(data)} />
                </IconButton>
              ),
              style: { width: "10px" },
            },
          ]
        : props.columns,
    [deleteRowItem, props.columns]
  );

  useEffect(() => {
    setList(data);
  }, []);

  const renderTable = useMemo(
    () => (
      <Table
        loading={props.tableloading}
        columns={columns}
        data={list}
        renderItemProps={{
          ...renderItemProps,
          onChange: tableCellChangeHandler,
        }}
      />
    ),
    [list, tableCellChangeHandler, renderItemProps, columns, props.tableloading]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderTable}
      </Grid>
    </Grid>
  );
};

export default memo(ListTable);
