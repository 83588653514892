import { StaticDatePicker } from "@mui/x-date-pickers";
import { ListItem, Popper } from "@mui/material";
import { styled } from "@mui/material";

export const RangePickerContainer = styled("div")({});

export const StyledRangePickerHeader = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "12px",
  display: "flex",
  height: "43px",
  alignItems: "center",
  color: "#000",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  "&:hover": {
    borderColor: "#313649",
  },
  "@media(min-width: 900px)": {
    border: "0",
    boxShadow: "0 1px 2px 0 #00000008",
  },
});

export const StyledRangePickerLabel = styled("div")({
  cursor: "pointer",
  color: "#5E5873",
  fontSize: " 1rem",
  padding: "10px 0 10px 15px",
  display: "flex",
  alignItems: "center",
});

export const StyledRangePickerHeaderChild = styled("div")({
  width: "calc(100% - 40px)",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "10px",
  color: "#5E5873",
  cursor: "pointer",
  "&.full": {
    width: "100%",
    paddingRight: "10px",
    "& p": {
      color: "#9E9E9E !important",
    },
  },
});

export const StyledTextBlock = styled("p")({
  height: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  margin: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  color: "#313649",
  justifyContent: "space-around",
  "@media(min-width: 900px)": {
    display: "initial",
  },
});

export const StyledTo = styled("span")({
  margin: "0px 5px",
});

export const PopperStyle = styled(Popper)({
  backgroundColor: "#fff",
  borderRadius: "12px",
  marginTop: "50px",
  zoom: "0.95",
  "& .css-1hbyad5-MuiTypography-root": {
    display: "none",
  },
  zIndex: 9999999,
});

export const StyledStaticDateRangePicker = styled(StaticDatePicker)({
  paddingLeft: "15px",
  "& .MuiPickersToolbar-root.MuiDatePickerToolbar-root, .MuiDialogActions-root.MuiDialogActions-spacing":
    {
      display: "none",
    },
  "& > div:nth-child(2)": {
    borderTop: "1px solid #eaeaea",
    borderBottom: "1px solid #eaeaea",
    background: "#F2F2F2",
    paddingTop: "22px",
    paddingBottom: "22px",
    marginBottom: 0,
  },
  "& .PrivatePickersSlideTransition-root": {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #eaeaea",
    borderBottom: "1px solid #eaeaea",
    minHeight: "256px",
    "& > div": {
      top: "auto",
    },
  },
});

export const SelectRangeElements = styled("div")({
  width: "200px",
  padding: "15px",
  display: "flex",
});

export const ElementBlock = styled("ul")({
  width: "100%",
  height: "100%",
  alignItems: "baseline",
  borderLeft: "1px solid #eaeaea",
  paddingLeft: "012px",
});
export const Element = styled(ListItem)(({ theme }) => ({
  height: "40px",
  padding: "0 16px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  background: "#F2F2F2",
  borderRadius: "12px",
  marginTop: "12px",
  "&:hover": {
    backgroundColor: "#eaeaea",
  },
  "&.active_interval": {
    background: theme.palette.primary.main,
    color: "#fff",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "rgba(2, 106, 255, 0.7)",
    },
  },
  "&:first-child": {
    marginTop: 0,
  },
  "&:last-child": {
    marginBottom: 0,
  },
}));

export const StyledDateInput = styled("input")({
  height: "43px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: "12px",
  padding: "10px",
  "&:hover": {
    borderColor: "",
  },
  "&:focus-visible": {
    border: "2px solid #026aff",
    outline: "none",
  },
  "&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator": {
    display: "none",
    webkitAppearance: "none",
  },
});
