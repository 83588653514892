import { useState } from "react";
import { universal } from "services/api";
import { REQUEST_STATUSES } from "./useRequest.constants";

const useRequest = () => {
  const [data, setData] = useState();
  const [status, setStatus] = useState(REQUEST_STATUSES.initial);
  const [error, setError] = useState();

  const get = async (url) => {
    await sendRequest("GET", url);
  };

  const post = async (url, data, toastMessage, isShowLoader) => {
    return await sendRequest("POST", url, data, toastMessage, isShowLoader);
  };

  const put = async (url, data, toastMessage, isShowLoader) => {
    return await sendRequest("PUT", url, data, toastMessage, isShowLoader);
  };

  const sendRequest = async (method, url, data, toastMessage, isShowLoader) => {
    setStatus(REQUEST_STATUSES.loading);
    try {
      const res = await universal.client(
        url,
        data,
        method,
        toastMessage,
        isShowLoader
      );
      setData(res);
      setStatus(REQUEST_STATUSES.success);
      return res;
    } catch (err) {
      setError(err);
      setStatus(REQUEST_STATUSES.failed);
      throw new Error(err);
    }
  };

  return [
    {
      get,
      post,
      put,
    },
    data,
    status,
    error,
  ];
};

export default useRequest;
