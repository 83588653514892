import { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Fade, Grid, IconButton, ListItemText } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

import useOutsideHandler from "hooks/useOutsideHandler";
import { GLOBAL_DATE_FORMAT } from "constants/dateFormat";

import { PopperStyle, RangePickerBody, RangePickerContainer } from "./styled";
import {
  Element,
  ElementBlock,
  SelectRangeElements,
  StyledDateInput,
  StyledRangePickerHeader,
  StyledRangePickerHeaderChild,
  StyledStaticDateRangePicker,
  StyledTextBlock,
  StyledTo,
} from "./DateRangePicker.style";
import { CUSTOM_DATE_RANGE } from "./DateRangePicker.constants";

const inputDateFormatAlways = "YYYY-MM-DD";

const DateRangePicker = ({
  onClose,
  fromDateName = "from",
  toDateName = "to",
  defaultValues = [null, null],
  clearable = true,
  inputFormat = GLOBAL_DATE_FORMAT.inputFormatDate,
  ...props
}) => {
  const { t } = useTranslation();

  const outsideOfSelectRef = useRef();

  const [dates, setDates] = useState(defaultValues);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDateInput, setActiveDateInput] = useState(
    defaultValues?.[0] && !defaultValues?.[1] ? "to" : "from"
  );
  const [date, setDate] = useState({
    from: defaultValues?.[0]
      ? moment(defaultValues[0]).format(inputDateFormatAlways)
      : "",
    to: defaultValues?.[1]
      ? moment(defaultValues[1]).format(inputDateFormatAlways)
      : "",
  });

  useOutsideHandler(outsideOfSelectRef, setOpen);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);

  const id = canBeOpen ? "spring-popper" : undefined;

  const handleDate = (e) => {
    onClose([
      {
        name: fromDateName,
        date: e[0],
      },
      {
        name: toDateName,
        date: e[1],
      },
    ]);
    setDate({
      from: moment(e?.[0]).format(inputDateFormatAlways),
      to: moment(e?.[0]).format(inputDateFormatAlways),
    });
    setDates(e);
  };

  const handleChangeStaticDatePicker = async (dateNow) => {
    if (activeDateInput === "from") {
      await setDate((prevState) => ({
        ...prevState,
        from: moment(dateNow).format(inputDateFormatAlways),
      }));
      await setDates((prevState) => [dateNow, prevState?.[1] || null]);
      await setActiveDateInput("to");
      if (date?.to) {
        onClose([
          {
            name: fromDateName,
            date: dateNow,
          },
          {
            name: toDateName,
            date: date?.to,
          },
        ]);
      }
    } else {
      await setDate((prevState) => ({
        ...prevState,
        to: moment(dateNow).format(inputDateFormatAlways),
      }));
      await setDates((prevState) => [prevState?.[0] || null, dateNow]);
      await setActiveDateInput("from");
      if (date?.from) {
        onClose([
          {
            name: fromDateName,
            date: date?.from,
          },
          {
            name: toDateName,
            date: dateNow,
          },
        ]);
      }
    }
  };

  const onChangeDate = (type) => (event) => {
    const newDate = moment(new Date(event.target.value)).format(
      inputDateFormatAlways
    );
    setDate((prevState) => ({
      from: type === "from" ? newDate : prevState?.from,
      to: type === "to" ? newDate : prevState?.to,
    }));
  };

  const isFocusedLabel = (startDate, endDate) => {
    return (
      new Date(moment(dates?.[0]).startOf("day")).getTime() ===
        new Date(moment(startDate).startOf("day")).getTime() &&
      new Date(moment(dates?.[1]).endOf("day")).getTime() ===
        new Date(moment(endDate).endOf("day")).getTime()
    );
  };

  const renderCloseIconButton = () =>
    (dates[0] || dates[1]) && (
      <IconButton onClick={() => handleDate([null, null])}>
        <Close />
      </IconButton>
    );

  return (
    <RangePickerContainer>
      <StyledRangePickerHeader
        style={{ justifyContent: clearable ? "normal" : "center" }}
      >
        <StyledRangePickerHeaderChild
          className={dates[0] || dates[1] ? "" : "full"}
          style={{ padding: clearable ? "0 10px 0 10px" : "0" }}
          onClick={handleClick}
        >
          <StyledTextBlock
            style={{ display: "flex", padding: clearable ? "0" : "0 20px" }}
          >
            {`${
              dates[0]
                ? moment(dates[0]).format(GLOBAL_DATE_FORMAT.momentFormatDate)
                : inputFormat.toLocaleLowerCase()
            }`}
            <StyledTo>-</StyledTo>
            {`${
              dates[1]
                ? moment(dates[1]).format(GLOBAL_DATE_FORMAT.momentFormatDate)
                : inputFormat.toLocaleLowerCase()
            }`}
          </StyledTextBlock>
        </StyledRangePickerHeaderChild>
        {clearable && renderCloseIconButton()}
      </StyledRangePickerHeader>
      <PopperStyle
        id={id}
        anchorEl={anchorEl}
        ref={outsideOfSelectRef}
        open={open}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <RangePickerBody>
              <div className={"calendar"}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box>
                    <StyledStaticDateRangePicker
                      calendars={1}
                      value={dates}
                      onChange={handleChangeStaticDatePicker}
                      {...props}
                    />
                    <Grid
                      container
                      columns={13}
                      marginTop="10px"
                      marginLeft="15px"
                      sx={{ width: "305px" }}
                    >
                      <Grid item xs={6}>
                        <StyledDateInput
                          type="date"
                          format={GLOBAL_DATE_FORMAT.dataDateFormat}
                          value={date?.from}
                          className={activeDateInput === "from" ? "active" : ""}
                          onClick={() => setActiveDateInput("from")}
                          onChange={onChangeDate("from")}
                        />
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        xs={1}
                      >
                        -
                      </Grid>
                      <Grid item xs={6}>
                        <StyledDateInput
                          type="date"
                          format={GLOBAL_DATE_FORMAT.dataDateFormat}
                          className={activeDateInput === "to" ? "active" : ""}
                          onClick={() => setActiveDateInput("to")}
                          value={date?.to}
                          onChange={onChangeDate("to")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </LocalizationProvider>
                <SelectRangeElements>
                  <ElementBlock>
                    {CUSTOM_DATE_RANGE.map((custom) => (
                      <Element
                        className={
                          isFocusedLabel(
                            custom.isFocusedLabel.from,
                            custom.isFocusedLabel.to
                          )
                            ? "active_interval"
                            : ""
                        }
                        onClick={() =>
                          handleDate([
                            custom.isFocusedLabel.from,
                            custom.isFocusedLabel.to,
                          ])
                        }
                      >
                        <ListItemText>{t(custom?.translationKey)}</ListItemText>
                      </Element>
                    ))}
                  </ElementBlock>
                </SelectRangeElements>
              </div>
            </RangePickerBody>
          </Fade>
        )}
      </PopperStyle>
    </RangePickerContainer>
  );
};

export default memo(DateRangePicker);
