import { styled } from "@mui/material";

export const StyledBoardContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "100%",
  width: "auto",
});

export const StyleBoardCard = styled("div")(({ isdragging }) => ({
  padding: "10px",
  border: "1px solid",
  backgroundColor: isdragging ? "lightgreen" : "#fff",
  margin: "5px 0",
  rotate: "1deg",
  transform: isdragging ? "rotate(1deg)" : "rotate(0deg)",
}));

export const StyledLaneContainer = styled("div")(({ styleCameOut }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "300px",
  margin: "0 10px",
  height: "100%",
  ...styleCameOut,
}));

export const StyledLeanHeaderContainer = styled("div")(() => ({
  maxHeight: "10%",
}));
export const StyledLeanBodyContainer = styled("div")(() => ({
  marginTop: "15px",
}));

export const StyledLaneBody = styled("div")(() => ({
  transition: ".1s",
  flexGrow: 1,
  minHeight: "10px",
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "12px",
  marginTop: "10px",
  paddingBottom: "10px",
  height: "100%",
}));

export const StyledBoardWrapper = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: "#fff",
  borderRadius: "15px",
  display: "flex",
  padding: "15px",
  overflow: "hidden",
  overflowX: "scroll",
});
