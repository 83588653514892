import ListMultiTableContext from "./ListMultiTableContext";
import { useListMultiTable } from "./useListMultiTable";

export default function ListMultiTableProvider({ children }) {
  const store = useListMultiTable();

  return (
    <ListMultiTableContext.Provider value={store}>
      {children}
    </ListMultiTableContext.Provider>
  );
}
