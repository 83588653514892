import { Box, styled, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const ButtonWrapper = styled(Box)(({ isopen }) => ({
  borderRadius: "12px",
  cursor: "pointer",
  height: "100%",
  backgroundColor: isopen ? "#fff" : "transparent",
}));

export const MenuTypography = styled(Typography)({
  fontWeight: 600,
  color: "#313649",
});

export const StyledIconButton = styled("div")(({ isopen, theme }) => ({
  transition: "0.4s",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: "rgba(209,209,240,0.2)",
  },
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  width: "100%",
  height: "100%",
  padding: "10px 8px",
  "& .MuiSvgIcon-root, .MuiTypography-root": {
    color: isopen ? theme.palette.primary.main : "#fff",
  },
}));

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ isopen }) => ({
    transition: "0.2s",
    transform: `rotate(${isopen ? "180deg" : "0deg"})`,
  })
);
