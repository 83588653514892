import { memo } from "react";

import { StyledError, StyledInfoIcon } from "./Error.style";

const Error = ({ children }) => {
  return (
    <StyledError>
      <StyledInfoIcon fontSize={"small"} />
      {children}
    </StyledError>
  );
};

export default memo(Error);
