import { styled } from "@mui/material";
import { StyledFormField } from "../style/Form.style";

export const StyledMultiSelect = styled(StyledFormField)({
  "& .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon": {
    width: "100%",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    padding: 0,
  },
  "& .MuiInputBase-formControl": {
    "& .MuiChip-root": { maxWidth: "50%" },
  },
  "& .Mui-focused": {
    "& .MuiChip-root": { maxWidth: "calc(100% - 6px)" },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    borderRadius: "12px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #313649 !important",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #026aff !important",
  },
  "@media (min-width: 900px)": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      borderRadius: "12px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
});
