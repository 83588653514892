import { HREF } from "constants/href.constants";
import get from "lodash.get";
import moment from "moment";
import { SUPPLIER_TYPE_OPTIONS } from "pages/statistics/supplier/constants/Customer.constants";

import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";
import { base64 } from "utils";

const util = (a) => base64.encode(JSON.stringify(a));

export const STATISTICS_DEFAULT_DATE = {
  fromDate: moment(new Date()).startOf("month").toISOString(),
  toDate: moment(new Date()).endOf("day").toISOString(),
};

export const STATISTICS_SALE_DEFAULT_VALUE = {
  divider: "day",
  field: "gross_sales",
  chartType: "bar",
};

export const STATISTICS_PRODUCT_DEFAULT_VALUE = {
  chartType: "bar",
  custom: false,
};

export const STATISTICS_SALE_BY_PAYMENT_DEFAULT_VALUE = {
  saleType: "sale_contract",
};

export const STATISTICS_WAREHOUSE_DEFAULT_VALUE = {};

export const LANGUAGES = [
  { value: "uz", label: "uz" },
  { value: "ru", label: "ru" },
  { value: "en", label: "en" },
];

export const CREATE_SECTIONS = [
  {
    href: `${HREF.sale}/contract/create`,
    translationKey: "ADD_SECTIONS.CONTRACT",
    hasAccess: ACCESS_NAMES.saleContractCreate,
  },
  {
    href: `${HREF.customer}/customers/create`,
    translationKey: "ADD_SECTIONS.CUSTOMER",
    hasAccess: ACCESS_NAMES.customerCreate,
  },
  {
    href: `${HREF.customer}/orders/create`,
    translationKey: "ADD_SECTIONS.CUSTOMER_ORDER",
    hasAccess: ACCESS_NAMES.saleOrderCreate,
  },
  {
    href: `${HREF.products}/products/create`,
    translationKey: "ADD_SECTIONS.PRODUCT",
    hasAccess: ACCESS_NAMES.productCreate,
  },
  {
    href: `${HREF.products}/categories/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_CATEGORY",
    hasAccess: ACCESS_NAMES.productCategoryCreate,
  },
  {
    href: `${HREF.products}/label/print`,
    translationKey: "ADD_SECTIONS.LABEL",
    hasAccess: ACCESS_NAMES.label,
  },
  {
    href: `${HREF.products}/income/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_INCOME",
    hasAccess: ACCESS_NAMES.productIncomeCreate,
  },
  {
    href: `${HREF.products}/transfer/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_TRANSFER",
    hasAccess: ACCESS_NAMES.productTransferCreate,
  },
  {
    href: `${HREF.products}/return/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_RETURN",
    hasAccess: ACCESS_NAMES.productReturnCreate,
  },
  {
    href: `${HREF.supply}/product-orders/create`,
    translationKey: "ADD_SECTIONS.PRODUCT_ORDER",
    hasAccess: ACCESS_NAMES.productOrderCreate,
  },
  {
    href: `${HREF.supply}/material-orders/create`,
    translationKey: "ADD_SECTIONS.MATERIAL_ORDER",
    hasAccess: ACCESS_NAMES.materialOrderCreate,
  },
  {
    href: `${HREF.supply}/supplier/create`,
    translationKey: "ADD_SECTIONS.SUPPLIER",
    hasAccess: ACCESS_NAMES.supplierCreate,
  },
  {
    href: `${HREF.warehouse}/tmb/create`,
    translationKey: "ADD_SECTIONS.TMB",
    hasAccess: ACCESS_NAMES.materialCreate,
  },
  {
    href: `${HREF.warehouse}/ytm/create`,
    translationKey: "ADD_SECTIONS.YTM",
    hasAccess: ACCESS_NAMES.semiProductCreate,
  },
  {
    href: `${HREF.warehouse}/category/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_CATEGORY",
    hasAccess: ACCESS_NAMES.materialCategoryCreate,
  },
  {
    href: `${HREF.warehouse}/active/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_ACTIVE",
    hasAccess: ACCESS_NAMES.activeCreate,
  },
  {
    href: `${HREF.warehouse}/income/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_INCOME",
    hasAccess: ACCESS_NAMES.materialIncomeCreate,
  },
  {
    href: `${HREF.warehouse}/transfer/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_TRANSFER",
    hasAccess: ACCESS_NAMES.materialTransferCreate,
  },
  {
    href: `${HREF.warehouse}/return/create`,
    translationKey: "ADD_SECTIONS.WAREHOUSE_RETURN",
    hasAccess: ACCESS_NAMES.materialReturnCreate,
  },
  {
    href: `${HREF.warehouse}/revision/create`,
    translationKey: "ADD_SECTIONS.REVISION",
    hasAccess: ACCESS_NAMES.inventorizationCreate,
  },
  {
    href: `${HREF.finance}/tax/create`,
    translationKey: "ADD_SECTIONS.TAX",
    hasAccess: ACCESS_NAMES.taxCreate,
  },
  {
    href: `${HREF.hr}/employees/create`,
    translationKey: "ADD_SECTIONS.EMPLOYEE",
    hasAccess: ACCESS_NAMES.employeeCreate,
  },
  {
    href: `${HREF.hr}/section/create`,
    translationKey: "ADD_SECTIONS.SECTION",
    hasAccess: ACCESS_NAMES.sectionCreate,
  },
  {
    href: `${HREF.hr}/position/create`,
    translationKey: "ADD_SECTIONS.POSITION",
    hasAccess: ACCESS_NAMES.positionCreate,
  },
  {
    href: `${HREF.hr}/role/create`,
    translationKey: "ADD_SECTIONS.ROLE",
    hasAccess: ACCESS_NAMES.roleCreate,
  },
  {
    href: `${HREF.hr}/attendance/create`,
    translationKey: "ADD_SECTIONS.ATTENDANCE",
    hasAccess: ACCESS_NAMES.attendanceCreate,
  },
  {
    href: `${HREF.task}/board/create`,
    translationKey: "ADD_SECTIONS.TASK",
    hasAccess: ACCESS_NAMES.taskCreate,
  },
  {
    href: `${HREF.production}/planing/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_PLANNING",
    hasAccess: ACCESS_NAMES.productionPlanCreate,
  },
  {
    href: `${HREF.production}/orders/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_ORDER",
    hasAccess: ACCESS_NAMES.productionOrderCreate,
  },
  {
    href: `${HREF.production}/template/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_TEMPLATE",
    hasAccess: ACCESS_NAMES.productionProcessTemplateCreate,
  },
  {
    href: `${HREF.production}/produced-products/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_PRODUCED_PRODUCTS",
    hasAccess: ACCESS_NAMES.productionProcessProducedCreate,
  },
  {
    href: `${HREF.production}/transfers/create`,
    translationKey: "ADD_SECTIONS.PRODUCTION_TRANSFER",
    hasAccess: ACCESS_NAMES.productionProcessTransferCreate,
  },
];

export const SECTIONS = [
  {
    hasAccess: ACCESS_NAMES.statistics,
    activeInUrl: HREF.statistics,
    isShow: true,
    title: "SIDEBAR.STATISTICS.TITLE",
    icon: "/images/icons/statistics.svg",
    iconAlt: "statistics",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.statisticsSale,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sale`,
        activeInUrl: `${HREF.statistics}/sale`,
        title: "SIDEBAR.STATISTICS.ON_TRADE",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sale?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&from_date=${util(STATISTICS_DEFAULT_DATE.fromDate)}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}&divider=${util(
            STATISTICS_SALE_DEFAULT_VALUE.divider
          )}&field=${util(
            STATISTICS_SALE_DEFAULT_VALUE.field
          )}&chartType=${util(STATISTICS_SALE_DEFAULT_VALUE.chartType)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsCashbox,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/cashbox`,
        activeInUrl: `${HREF.statistics}/cashbox`,
        title: "SIDEBAR.STATISTICS.CASHBOX",
        to: () =>
          `/statistics/cashbox?from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsProduct,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/products`,
        activeInUrl: `${HREF.statistics}/products`,
        title: "SIDEBAR.STATISTICS.ON_GOODS",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/products?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}
                    &from_date=${util(
                      STATISTICS_DEFAULT_DATE.fromDate
                    )}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}&chartType=${util(
            STATISTICS_PRODUCT_DEFAULT_VALUE.chartType
          )}&custom=${util(STATISTICS_PRODUCT_DEFAULT_VALUE.custom)}
                    `,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsCategory,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sales-categories`,
        activeInUrl: `${HREF.statistics}/sales-categories`,
        title: "SIDEBAR.STATISTICS.ON_CATEGORIES",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sales-categories?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&from_date=${util(STATISTICS_DEFAULT_DATE.fromDate)}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsEmployee,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sales-by-employee`,
        activeInUrl: `${HREF.statistics}/sales-by-employee`,
        title: "SIDEBAR.STATISTICS.ON_EMPLOYEE",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sales-by-employee?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsCustomer,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sales-by-customer`,
        activeInUrl: `${HREF.statistics}/sales-by-customer`,
        title: "SIDEBAR.STATISTICS.ON_CUSTOMER",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sales-by-customer?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsPaymentMethod,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sale-by-payment-type`,
        activeInUrl: `${HREF.statistics}/sale-by-payment-type`,
        title: "SIDEBAR.STATISTICS.BY_TYPE_OF_PAYMENT",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sale-by-payment-type?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsReceipt,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/all-checks`,
        activeInUrl: `${HREF.statistics}/all-checks`,
        title: "SIDEBAR.STATISTICS.ALL_BOUNDARIES",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/all-checks?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsWarehouse,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/warehouse`,
        activeInUrl: `${HREF.statistics}/warehouse`,
        title: "SIDEBAR.STATISTICS.WAREHOUSE",
        to: ({ globalPagingLimit, allOrganization }) =>
          `${HREF.statistics}/warehouse?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&organization_id=${util(get(allOrganization, "[0]._id"))}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsSupplier,
        isShow: true,
        reClickDisabler: `${HREF.statistics}/sales-by-supplier`,
        activeInUrl: `${HREF.statistics}/sales-by-supplier`,
        title: "SIDEBAR.STATISTICS.ON_SUPPLIER",
        to: ({ globalPagingLimit }) =>
          `${HREF.statistics}/sales-by-supplier?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}&income_type=${util(
            SUPPLIER_TYPE_OPTIONS[0]?.value
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.pos,
    activeInUrl: HREF.pos,
    reClickDisabler: HREF.pos,
    isShow: true,
    title: "SIDEBAR.POS",
    icon: "/images/icons/pos.svg",
    iconAlt: "pos",
    to: () => "/pos/choose-organization",
  },
  {
    hasAccess: ACCESS_NAMES.sales,
    activeInUrl: HREF.sale,
    isShow: true,
    title: "SIDEBAR.SALE.TITLE",
    icon: "/images/icons/sale_new.svg",
    iconAlt: "sale",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.saleContract,
        isShow: true,
        reClickDisabler: `${HREF.sale}/contract/list`,
        title: "COMMON.CONTRACT",
        activeInUrl: `${HREF.sale}/contract`,
        to: ({ globalPagingLimit }) =>
          `${HREF.sale}/contract/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      // {
      //   hasAccess: ACCESS_NAMES.discount,
      //   isShow: true,
      //   reClickDisabler: `${HREF.sale}/discount/list`,
      //   activeInUrl: `${HREF.sale}/discount`,
      //   title: "SIDEBAR.SALE.DISCOUNTS",
      //   to: ({ globalPagingLimit }) =>
      //     `/sale/discount/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.customers,
    activeInUrl: HREF.customer,
    isShow: true,
    title: "SIDEBAR.CUSTOMERS.TITLE",
    icon: "/images/icons/customer.svg",
    iconAlt: "customer",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.saleContract,
        isShow: true,
        reClickDisabler: `${HREF.customer}/customers/list`,
        activeInUrl: `${HREF.customer}/customers`,
        title: "SIDEBAR.CUSTOMERS.TITLE",
        to: ({ globalPagingLimit }) =>
          `${HREF.customer}/customers/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      // {
      //   hasAccess: ACCESS_NAMES.customersBalance,
      //   isShow: true,
      //   reClickDisabler: `${HREF.customer}/customer-balance/list`,
      //   activeInUrl: `${HREF.customer}/customer-balance`,
      //   title: "SIDEBAR.CUSTOMERS.CUSTOMERS_BALANCE",
      //   to: ({ globalPagingLimit }) =>
      //     `${HREF.customer}/customer-balance/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
      {
        hasAccess: ACCESS_NAMES.saleOrder,
        isShow: true,
        reClickDisabler: `${HREF.customer}/orders/list`,
        activeInUrl: `${HREF.customer}/orders`,
        title: "SIDEBAR.CUSTOMERS.SALE_ORDERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.customer}/orders/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.callRecord,
        isShow: true,
        reClickDisabler: `${HREF.customer}/record-calls/list`,
        activeInUrl: `${HREF.customer}/record-calls`,
        title: "SIDEBAR.CUSTOMERS.RECORD_CALLS",
        to: ({ globalPagingLimit }) =>
          `${HREF.customer}/record-calls/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.products,
    activeInUrl: HREF.products,
    isShow: true,
    title: "SIDEBAR.PRODUCTS.TITLE",
    icon: "/images/icons/product.svg",
    iconAlt: "product",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.product,
        isShow: true,
        reClickDisabler: `${HREF.products}/products/list`,
        activeInUrl: `${HREF.products}/products`,
        title: "SIDEBAR.PRODUCTS.PRODUCTS",
        to: ({ globalPagingLimit }) =>
          `${HREF.products}/products/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productCategory,
        isShow: true,
        reClickDisabler: `${HREF.products}/categories/list`,
        activeInUrl: `${HREF.products}/categories`,
        title: "SIDEBAR.PRODUCTS.CATEGORIES",
        to: ({ globalPagingLimit }) =>
          `${HREF.products}/categories/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.label,
        isShow: true,
        reClickDisabler: `${HREF.products}/label/print`,
        activeInUrl: `${HREF.products}/label/print`,
        title: "SIDEBAR.PRODUCTS.ETIKET",
        to: () => `${HREF.products}/label/print`,
      },
      {
        hasAccess: ACCESS_NAMES.productIncome,
        isShow: true,
        reClickDisabler: `${HREF.products}/income/list`,
        activeInUrl: `${HREF.products}/income`,
        title: "SIDEBAR.PRODUCTS.INCOME",
        to: ({ globalPagingLimit }) =>
          `${HREF.products}/income/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productTransfer,
        isShow: true,
        reClickDisabler: `${HREF.products}/transfer/list`,
        activeInUrl: `${HREF.products}/transfer`,
        title: "SIDEBAR.PRODUCTS.TRANSFER",
        to: ({ globalPagingLimit }) =>
          `${HREF.products}/transfer/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productReturn,
        isShow: true,
        reClickDisabler: `${HREF.products}/return/list`,
        activeInUrl: `${HREF.products}/return`,
        title: "SIDEBAR.PRODUCTS.RETURN",
        to: ({ globalPagingLimit }) =>
          `${HREF.products}/return/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.supply,
    activeInUrl: HREF.supply,
    isShow: true,
    title: "SIDEBAR.SUPPLY.TITLE",
    icon: "/images/icons/supply.svg",
    iconAlt: "supply",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.productOrder,
        isShow: true,
        reClickDisabler: `${HREF.supply}/product-orders/list`,
        activeInUrl: `${HREF.supply}/product-orders`,
        title: "SIDEBAR.SUPPLY.PRODUCT_ORDERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.supply}/product-orders/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.materialOrder,
        isShow: true,
        reClickDisabler: `${HREF.supply}/material-orders/list`,
        activeInUrl: `${HREF.supply}/material-orders`,
        title: "SIDEBAR.SUPPLY.MATERIAL_ORDERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.supply}/material-orders/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.supplier,
        isShow: true,
        reClickDisabler: `${HREF.supply}/supplier/list`,
        activeInUrl: `${HREF.supply}/supplier`,
        title: "SIDEBAR.SUPPLY.SUPPLIERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.supply}/supplier/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      // {
      //   hasAccess: ACCESS_NAMES.suppliersBalance,
      //   isShow: true,
      //   reClickDisabler: `${HREF.supply}/supplier-balance/list`,
      //   activeInUrl: `${HREF.supply}/supplier-balance`,
      //   title: "SIDEBAR.SUPPLY.SUPPLIERS_BALANCE",
      //   to: ({ globalPagingLimit }) =>
      //     `${HREF.supply}/supplier-balance/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.warehouse,
    activeInUrl: HREF.warehouse,
    isShow: true,
    title: "SIDEBAR.WAREHOUSE.TITLE",
    icon: "/images/icons/warehouse.svg",
    iconAlt: "warehouse",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.material,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/tmb/list`,
        activeInUrl: `${HREF.warehouse}/tmb`,
        title: "SIDEBAR.WAREHOUSE.TMB",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/tmb/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.semiProduct,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/ytm/list`,
        activeInUrl: `${HREF.warehouse}/ytm`,
        title: "SIDEBAR.WAREHOUSE.YTM",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/ytm/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.materialCategory,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/category/list`,
        activeInUrl: `${HREF.warehouse}/category`,
        title: "SIDEBAR.WAREHOUSE.CATEGORIES",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/category/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.active,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/active/list`,
        activeInUrl: `${HREF.warehouse}/active`,
        title: "SIDEBAR.WAREHOUSE.ACTIVES",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/active/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.materialIncome,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/income/list`,
        activeInUrl: `${HREF.warehouse}/income`,
        title: "SIDEBAR.WAREHOUSE.INCOME",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/income/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.materialTransfer,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/transfer/list`,
        activeInUrl: `${HREF.warehouse}/transfer`,
        title: "SIDEBAR.WAREHOUSE.TRANSFER",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/transfer/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.materialReturn,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/return/list`,
        activeInUrl: `${HREF.warehouse}/return`,
        title: "SIDEBAR.WAREHOUSE.RETURN",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/return/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.inventorization,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/revision/list`,
        activeInUrl: `${HREF.warehouse}/revision`,
        title: "SIDEBAR.FINANCE.INVENTORY",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/revision/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.writeOff,
        isShow: true,
        reClickDisabler: `${HREF.warehouse}/write-off/list`,
        activeInUrl: `${HREF.warehouse}/write-off`,
        title: "sidebar.warehouse.actions.write_of",
        to: ({ globalPagingLimit }) =>
          `${HREF.warehouse}/write-off/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.finance,
    activeInUrl: HREF.finance,
    isShow: true,
    title: "SIDEBAR.FINANCE.TITLE",
    icon: "/images/icons/finance.svg",
    iconAlt: "finance",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.cashbox,
        isShow: true,
        reClickDisabler: `${HREF.finance}/cash`,
        activeInUrl: `${HREF.finance}/cash`,
        title: "SIDEBAR.FINANCE.CASH",
        to: ({ globalPagingLimit }) =>
          `${HREF.finance}/cash?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.currency,
        isShow: true,
        reClickDisabler: `${HREF.finance}/currency/list`,
        activeInUrl: `${HREF.finance}/currency`,
        title: "SIDEBAR.FINANCE.CURRENCY",
        to: ({ globalPagingLimit }) =>
          `${HREF.finance}/currency/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      // {
      //   hasAccess: ACCESS_NAMES.tax,
      //   isShow: true,
      //   reClickDisabler: `${HREF.finance}/tax/list`,
      //   activeInUrl: `${HREF.finance}/tax`,
      //   title: "SIDEBAR.FINANCE.TAX",
      //   to: ({ globalPagingLimit }) =>
      //     `${HREF.finance}/tax/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
      {
        hasAccess: ACCESS_NAMES.paymentMethods,
        isShow: true,
        reClickDisabler: `${HREF.finance}/payment-type/list`,
        activeInUrl: `${HREF.finance}/payment-type`,
        title: "SIDEBAR.FINANCE.PAYMENT_TYPE",
        to: () =>
          `${HREF.finance}/payment-type/list?transaction-type=${util(
            "income"
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.hr,
    activeInUrl: HREF.hr,
    isShow: true,
    title: "SIDEBAR.HR.TITLE",
    icon: "/images/icons/hr.svg",
    iconAlt: "hr",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.employee,
        isShow: true,
        reClickDisabler: `${HREF.hr}/employees/list`,
        activeInUrl: `${HREF.hr}/employees`,
        title: "SIDEBAR.HR.EMPLOYEES",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/employees/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      // {
      //   hasAccess: ACCESS_NAMES.employeesBalance,
      //   isShow: true,
      //   reClickDisabler: `${HREF.hr}/employee-balance/list`,
      //   activeInUrl: `${HREF.hr}/employee-balance`,
      //   title: "SIDEBAR.HR.EMPLOYEES_BALANCE",
      //   to: ({ globalPagingLimit }) =>
      //     `${HREF.hr}/employee-balance/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
      {
        hasAccess: ACCESS_NAMES.section,
        isShow: true,
        reClickDisabler: `${HREF.hr}/section/list`,
        activeInUrl: `${HREF.hr}/section`,
        title: "SIDEBAR.HR.SECTION",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/section/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.position,
        isShow: true,
        reClickDisabler: `${HREF.hr}/position/list`,
        activeInUrl: `${HREF.hr}/position`,
        title: "SIDEBAR.HR.POSITION",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/position/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.role,
        isShow: true,
        reClickDisabler: `${HREF.hr}/role/list`,
        activeInUrl: `${HREF.hr}/role`,
        title: "SIDEBAR.HR.ROLES",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/role/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.salary,
        isShow: true,
        reClickDisabler: `${HREF.hr}/salary/list`,
        activeInUrl: `${HREF.hr}/salary`,
        title: "SIDEBAR.HR.SALARY",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/salary/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.attendance,
        isShow: true,
        reClickDisabler: `${HREF.hr}/attendance/list`,
        activeInUrl: `${HREF.hr}/attendance`,
        title: "SIDEBAR.HR.ATTENDANCE",
        to: ({ globalPagingLimit }) =>
          `${HREF.hr}/attendance/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
    ],
  },
  // {
  //   hasAccess: ACCESS_NAMES.tasks,
  //   activeInUrl: HREF.task,
  //   reClickDisabler: HREF.task,
  //   isShow: true,
  //   title: "SIDEBAR.TASKS.TITLE",
  //   icon: "/images/icons/task.svg",
  //   iconAlt: "task",
  //   to: () => `${HREF.task}/board/list`,
  // },
  {
    hasAccess: ACCESS_NAMES.integration,
    activeInUrl: HREF.integration,
    reClickDisabler: HREF.integration,
    isShow: true,
    title: "SIDEBAR.INTEGRATION.TITLE",
    icon: "/images/icons/integration.svg",
    iconAlt: "integration",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.integration,
        isShow: true,
        reClickDisabler: `${HREF.integration}/integration`,
        title: "SIDEBAR.INTEGRATION.TITLE",
        activeInUrl: `${HREF.integration}/integration`,
        to: ({ globalPagingLimit }) => `${HREF.integration}/integration`,
      },
      {
        hasAccess: ACCESS_NAMES.integration,
        isShow: true,
        reClickDisabler: `${HREF.integration}/sms-template`,
        title: "COMMON.SMS_TEMPLATE",
        activeInUrl: `${HREF.integration}/sms-template`,
        to: ({ globalPagingLimit }) => `${HREF.integration}/sms-template/list`,
      },

      // {
      //   hasAccess: ACCESS_NAMES.discount,
      //   isShow: true,
      //   reClickDisabler: `${HREF.sale}/discount/list`,
      //   activeInUrl: `${HREF.sale}/discount`,
      //   title: "SIDEBAR.SALE.DISCOUNTS",
      //   to: ({ globalPagingLimit }) =>
      //     `/sale/discount/list?page=${util(0)}&limit=${util(
      //       globalPagingLimit
      //     )}`,
      // },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.production,
    activeInUrl: HREF.production,
    isShow: true,
    title: "SIDEBAR.PRODUCTION.TITLE",
    icon: "/images/icons/production.svg",
    iconAlt: "production",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.productionPlan,
        isShow: true,
        reClickDisabler: `${HREF.production}/planing/list`,
        activeInUrl: `${HREF.production}/planing`,
        title: "SIDEBAR.PRODUCTION.PLANNING",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/planing/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&year=${util(new Date().getFullYear())}`,
      },
      {
        isShow: false,
        reClickDisabler: `${HREF.production}/board`,
        activeInUrl: `${HREF.production}/board`,
        title: "SIDEBAR.PRODUCTION.BOARD",
        to: () => `${HREF.production}/board`,
      },
      {
        hasAccess: ACCESS_NAMES.productionOrder,
        isShow: true,
        reClickDisabler: `${HREF.production}/orders/list`,
        activeInUrl: `${HREF.production}/orders`,
        title: "SIDEBAR.PRODUCTION.ORDERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/orders/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productionProcess,
        isShow: true,
        reClickDisabler: `${HREF.production}/process/list`,
        activeInUrl: `${HREF.production}/process`,
        title: "SIDEBAR.PRODUCTION.PROCESS",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/process/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productionProcessTemplate,
        isShow: true,
        reClickDisabler: `${HREF.production}/template/list`,
        activeInUrl: `${HREF.production}/template`,
        title: "SIDEBAR.PRODUCTION.TEMPLATE",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/template/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.productionProcessProduced,
        isShow: true,
        reClickDisabler: `${HREF.production}/produced-products/list`,
        activeInUrl: `${HREF.production}/produced-products`,
        title: "SIDEBAR.PRODUCTION.PRODUCED_PRODUCTS",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/produced-products/list?page=${util(
            0
          )}&limit=${util(globalPagingLimit)}`,
      },
      {
        hasAccess: ACCESS_NAMES.productionProcessTransfer,
        isShow: true,
        reClickDisabler: `${HREF.production}/transfers/list`,
        activeInUrl: `${HREF.production}/transfers`,
        title: "SIDEBAR.PRODUCTION.TRANSFERS",
        to: ({ globalPagingLimit }) =>
          `${HREF.production}/transfers/list?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}`,
      },
    ],
  },
  {
    hasAccess: ACCESS_NAMES.settings,
    activeInUrl: HREF.settings,
    isShow: true,
    reClickDisabler: `${HREF.settings}/tabs`,
    title: "SIDEBAR.SETTINGS",
    icon: "/images/icons/settings.svg",
    iconAlt: "settings",
    to: ({ hasAccess }) =>
      `${HREF.settings}/tabs?tabIndex=${util(
        hasAccess(ACCESS_NAMES.paymentMethods)
          ? 1
          : hasAccess(ACCESS_NAMES.receiptDetailGet)
          ? 2
          : 3
      )}`,
  },
];
