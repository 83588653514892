import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { getToken } from "../../Token";
// import makeQueryString from "services/make_query";

export const baseUrl = process.env.REACT_APP_BASE_TEST_URL;
// export const baseUrl = 'http://localhost:5000';
export const headers = { "Content-Type": "application/json" };

export let head_token = {
  "Content-Type": "application/json",
  Authorization: getToken(),
};

export function postAxios(url, data, urlParams = false) {
  head_token.Authorization = getToken();

  if (head_token.Authorization) {
    const response = axios.post(`${baseUrl + url}`, data, {
      headers: head_token,
    });
    // if (url.endsWith("paging") && urlParams) {
    //     const params = makeQueryString(data);
    //     window.history.pushState(
    //         null,
    //         "Optimal Erp",
    //         window.location.href.split("?")[0] + params
    //     );
    // }
    return response;
  } else return "";
}

export function Success(url, text, history) {
  toast.success(text);
  history.goBack();
  return <Toaster />;
}
