import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createTheme } from "@mui/material";

import { useRequest } from "hooks";

const useGlobal = () => {
  const { t } = useTranslation();
  const [getMeasuresClient, getMeasuresData] = useRequest();

  const muiTheme = createTheme({
    palette: {
      white: {
        main: "#fff",
        contrastText: "#000",
      },
      primary: {
        main: "#026aff",
        contrastText: "#fff",
      },
      error: {
        main: "#fc0707",
      },
      success: {
        main: "#47BE85",
        contrastText: "#fff",
      },
      info: {
        main: "rgba(0, 0, 0, 0.23)",
      },
    },
    shape: { borderRadius: "12px" },
    components: {
      MuiTablePagination: {
        defaultProps: { labelRowsPerPage: t("table.labelRowsSelect") },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: "32px !important",
          },
        },
      },
    },
  });
  const getMeasures = () => {
    getMeasuresClient.post(
      "/back-api/admin/settings/warehouse/units-of-measure/get-all",
      {}
    );
  };

  useEffect(() => {
    getMeasures();
  }, []);

  return {
    state: { muiTheme, getMeasuresState: { getMeasuresData } },
    actions: {},
  };
};

export default useGlobal;
