import React from "react";

import SendSms from "./SendSms";
import { SendSmsProvider } from "../context";

const index = ({ type, checkedTableRows }) => {
  return (
    <SendSmsProvider>
      <SendSms type={type} checkedTableRows={checkedTableRows} />
    </SendSmsProvider>
  );
};

export default index;
